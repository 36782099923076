import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';

export const NormalizedButton = styled.button`
  cursor: pointer;
  overflow: visible;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.2;
  padding: 1rem 2rem;
  margin: 0;
  border: none;
  text-transform: none;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }

  -webkit-appearance: button;
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  & > svg {
    height: calc(100% - 2rem);
  }
`;

const BasicStyledButton = styled(NormalizedButton)`
  background: #e2e2e2;
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 0.125rem;
  text-align: center;
  line-height: 1.6;
  border-radius: 999rem;

  & > svg {
    margin-right: 1.5rem;
  }
`;

const TextSmallStyledButton = styled(NormalizedButton)`
  background: transparent;
  border: none;
  font-family: Raleway;
  font-weight: 300;
  font-size: 1.3rem;
  letter-spacing: 0.04rem;
  line-height: 2.8rem;
  padding: 0.5rem 1rem;
`;

const PrimaryStyledButton = styled(BasicStyledButton)`
  background: #009fac;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px - 1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #ffffff;
  border: 2px solid transparent;

  &:hover {
    border-color: #009fac;
    color: #009fac;
    background: transparent;
    background: #009fac20;
  }

  &:hover svg * {
    fill: #009fac;
  }
`;

const SecondaryStyledButton = styled(BasicStyledButton)`
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px - 1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
  color: #666666;
  fill: #666666;

  &:hover {
    border-color: #243382;
    color: #243382;
    background: transparent;
    background: #24338220;
  }

  &:hover svg * {
    fill: #243382;
  }
`;

export interface ButtonProps {
  variant: 'basic' | 'text-small' | 'primary' | 'secondary';
  disabled?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
}

export const Button: VFC<ButtonProps> = ({ variant = 'secondary', icon, children, disabled, onClick }) => {
  // Select button style by variant
  let Component = BasicStyledButton;
  switch (variant) {
    case 'primary':
      Component = PrimaryStyledButton;
      break;
    case 'secondary':
      Component = SecondaryStyledButton;
      break;
    case 'text-small':
      Component = TextSmallStyledButton;
      break;
  }

  // Render button style
  return (
    <Component onClick={onClick} disabled={disabled}>
      {icon}
      {children}
    </Component>
  );
};

export const ButtonMemo = memo(Button);
