import { createAction } from '@reduxjs/toolkit';
import { connectRoutes, NOT_FOUND } from 'redux-first-router';
import querySerializer from 'query-string';
import { features } from '../config';

export const actions = {
  notFound: createAction(NOT_FOUND),
};

export const featuresByRoute = {};

const routes = {
  [actions.notFound.type]: '/not-found',
};

Object.keys(features).forEach((k) => {
  actions[k] = features[k].routeAction;
  featuresByRoute[features[k].routeAction.type] = features[k];
  routes[actions[k].type] = features[k].routepath;
});

// The called route must have the same parameters and parameter order as the canonical route
export const canonicalRoutes = {
  ...routes,
  // [actions.routeAlternative.type]: '/canonical-path/:with-same-parameters/but-other-names/:parameter-order-the-same',
};

// connectRouting
// Call initialize after saga start
export function connectRouting() {
  const connectRoutesOptions = {
    initialDispatch: false,
    scrollTop: true,
    initialEntries: [],
    querySerializer,
  };

  const { reducer, middleware, enhancer, initialDispatch } = connectRoutes(routes, connectRoutesOptions);
  return {
    reducer,
    middleware,
    enhancer,
    initialize: initialDispatch,
  };
}
