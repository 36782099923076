import { take, all, takeLatest, takeLeading, call, delay, fork, select, put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { feature, jobs, actions, selectors } from './config';
import {
  IStatements,
  ITestLineResult,
  convertToRoman,
  supportColorToText,
  genderToText,
  diagnosisToText,
  classLevelToText,
} from '@app/state';
import { PayloadAction } from '@reduxjs/toolkit';
import { tryJob } from '@app/lib/saga';
import { compileEvent } from '@app/lib/type';
import { createAxios } from './reports/repository';

export const sagas = [forking];

function* forking() {
  yield takeLatest(feature.initPattern, init);
}

function* init() {
  yield takeLeading(actions.send, sendToFairchance);
  yield takeLeading(actions.createPdf, createPdf);
}

function* sendToFairchance() {
  // Identifier for batch to save
  const batch = yield select(selectors.pupilForm.getBatchId);

  // Helper functions
  const testLineMapper = (lr: ITestLineResult) => ({
    ...lr,
    testLine: lr.id,
    batch,
    id: uuidv4(),
  });

  // Gather stuff
  const sentBy = yield select(selectors.auth.getUserId);

  const { childCode, ...pupilinfo } = yield select(selectors.pupilForm.getFormValues);

  const scratchPad = { id: batch };
  const childBatch = { id: uuidv4(), batch, childCode, sentBy };
  const childInformation = { batch, ...pupilinfo, id: uuidv4() };
  const childStatements = (yield select(selectors.enterStatements.getStatements))
    .filter((s: IStatements) => !!s.entered || s.entered === false)
    .map((s: IStatements) => ({ id: uuidv4(), batch, item: s.id, entered: s.entered ? s.entered : null }));

  const lineResultsSVO = (yield select(selectors.testSVO.getLineResults)).map(testLineMapper);
  const lineResultsNGP = (yield select(selectors.testNGP.getLineResults)).map(testLineMapper);
  const lineResultsNominativ = (yield select(selectors.testNominativ.getLineResults)).map(testLineMapper);
  const lineResultsNarration = (yield select(selectors.testNarration.getLineResults)).map(testLineMapper);

  const eventsToSend = [
    compileEvent('scratchPad', 'removed', scratchPad),
    compileEvent('childBatch', 'created', childBatch),
    compileEvent('childInformation', 'created', childInformation),
    ...childStatements.map((r: any) => compileEvent('childStatement', 'created', r)),
    ...lineResultsSVO.map((r: any) => compileEvent('childLineResult', 'created', r)),
    ...lineResultsNGP.map((r: any) => compileEvent('childLineResult', 'created', r)),
    ...lineResultsNominativ.map((r: any) => compileEvent('childLineResult', 'created', r)),
    ...lineResultsNarration.map((r: any) => compileEvent('childLineResult', 'created', r)),
  ];

  const testMode = yield select(selectors.resultsSummary.getTestMode);

  try {
    yield call(tryJob, jobs.saveEvaluation, !testMode ? eventsToSend : []);
  } catch (err) {
    console.error(err);
  }
}

function* createPdf() {
  // Gather stuff
  const { supportLevel, gender, diagnosis, classLevel, ...pupilinfo } = yield select(selectors.pupilForm.getFormValues);
  const childStatements = (yield select(selectors.enterStatements.getStatements))
    .filter((s: IStatements) => !!s.entered || s.entered === false)
    .map((s: IStatements) => ({ item: s.label, entered: s.entered ? s.entered : null }));

  const grades = {
    svoGrade: yield select(selectors.testSVO.getGradeTotal),
    svoGradeColor: supportColorToText(
      yield select(selectors.testSVO.getSupportColor, yield select(selectors.pupilForm.getInDaycare))
    ),
    ngpAcquired: yield select(selectors.testNGP.getNGPAquired),
    ngpCorrectAnswers: yield select(selectors.testNGP.getNumberOfCorrectAnswers),
    ngpTotalAnswers: (yield select(selectors.testNGP.getStatements, selectors.enterStatements.getEnteredByItem)).length,
    nominativLevel: convertToRoman(yield select(selectors.testNominativ.getNominativLevel)),

    narrationGrade: yield select(selectors.testNarration.getNarrationTotal),
  };

  const postdata = {
    pupil: {
      ...pupilinfo,
      supportLevel: supportColorToText(supportLevel),
      gender: genderToText(gender),
      diagnosis: diagnosisToText(diagnosis),
      classLevel: classLevelToText(classLevel),
    },
    statements: childStatements,
    grades,
  };

  try {
    const accessToken = yield select(selectors.auth.accessToken);
    const axios = createAxios(accessToken);
    const response = yield call(axios.post, '/pdf/result-summary', postdata, { responseType: 'blob' });
    const blobUrl = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${pupilinfo.childCode}.pdf`);
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      link.remove();
      URL.revokeObjectURL(blobUrl);
    }, 1000);
  } catch (err) {
    console.error(err);
  } finally {
    yield put(actions.createPdfDone());
  }
}
