import { createAction } from '@reduxjs/toolkit';
import {
  features,
  controlActions,
  // createJob
} from '../config';
import { IChildFormData } from '@app/state/types/model';
export { controlActions } from '../config';

export const feature = features.pupilForm;

export const jobs = {};

export const actions = {
  gotoEnterStatements: () => features.enterStatements.routeAction({}),
  gotoTestSVO: () => features.testSVO.routeAction({}),
  gotoTestNGP: () => features.testNGP.routeAction({}),
  gotoTestNominativ: () => features.testNominativ.routeAction({}),
  gotoTestNarration: () => features.testNarration.routeAction({}),
  gotoResultSummary: () => features.resultsSummary.routeAction({}),
  pupilFormSubmitted: createAction('SUBMITTED@PUPIL-FORM', (data: Partial<IChildFormData>) => ({ payload: data })),
  newChild: createAction('NEW-CHILD@PUPIL-FORM'),
  reset: () => controlActions.routing.resetApplication(),
};
