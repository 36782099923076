import { createAction } from '@reduxjs/toolkit';
import { features, createJob } from '../config';
import { TId } from '@app/lib/type';

export const feature = features.enterStatements;

export const jobs = {
  loadEnterStatements: createJob.loadEnterStatements(),
};

export const actions = {
  gotoPupilForm: () => features.pupilForm.routeAction({}),
  gotoEnterStatements: () => features.enterStatements.routeAction({}),
  gotoTestSVO: () => features.testSVO.routeAction({}),
  gotoTestNGP: () => features.testNGP.routeAction({}),
  gotoTestNominativ: () => features.testNominativ.routeAction({}),
  gotoTestNarration: () => features.testNarration.routeAction({}),
  gotoResultSummary: () => features.resultsSummary.routeAction({}),
  onEntered: createAction('ON_ENTERED@ENTER-STATEMENTS', (id: TId, value: string | false | null) => ({
    payload: {
      id,
      value,
    },
  })),
  onHashed: createAction('ON_HASHED@ENTER-STATEMENTS', (id: TId, value: string | null) => ({
    payload: {
      id,
      value,
    },
  })),
};
