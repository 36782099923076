import { VFC, memo } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as EvaluationLegendIcon1 } from './evaluation-legend-1.svg';
import { ReactComponent as EvaluationLegendIcon2 } from './evaluation-legend-2.svg';
import { ReactComponent as EvaluationLegendIcon3 } from './evaluation-legend-3.svg';

const StyledEvaluationLegend = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  font-family: Raleway;
  font-weight: 300;
  font-size: 1.3rem;
  color: #9b9b9b;
  max-width: 45rem;
  align-items: center;
  justify-items: start;
`;

export const EvaluationLegend: VFC = () => {
  return (
    <StyledEvaluationLegend>
      <EvaluationLegendIcon1 />
      <div>Die KI (Künstliche Intelligenz) hat diese Vorauswahl bei der Bewertung getroffen,</div>
      <EvaluationLegendIcon2 />
      <div>
        Diese Bewertung wurde bei gleicher Aussage des Kindes von einer anderen Förderkraft schon einmal gemacht.
      </div>
      <EvaluationLegendIcon3 />
      <div>Diese Bewertung wurde von unseren Fachkräften geprüft und ist richtig.</div>
    </StyledEvaluationLegend>
  );
};

export const EvaluationLegendMemo = memo(EvaluationLegend);
