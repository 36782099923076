import { createReducer } from '@reduxjs/toolkit';
import { EmptyINormalized, INormalized, IGrouped, EmptyIGrouped, buildGroup, TId } from '@app/lib/type';
import { feature, jobs, actions } from './config';
import { createDataSuccessToNormalizedReducer } from '@app/lib/reducer';
import {
  ITestItem,
  ITestSection,
  ITestLine,
  TTestSectionSpecifier,
  ITestLineResult,
  IGradingProposal,
  upsertTestLineResult,
  evaluateTestLineSvo,
} from '@app/state';
import { TTestLineStatus } from './types';
import { isEmpty } from 'lodash';

interface IState {
  featureInitialized?: boolean;
  testSection: INormalized<ITestSection>;
  testSectionMap: Partial<Record<TTestSectionSpecifier, ITestSection>>;
  testItem: INormalized<ITestItem>;
  testLine: IGrouped<ITestLine>;
  gradingProposal: INormalized<IGradingProposal>;
  lineResult: Record<TId, ITestLineResult>;
  lineStatus: Record<TId, TTestLineStatus>;
}

export type TState = IState;

const initialState: TState = {
  testSection: EmptyINormalized(),
  testSectionMap: {},
  testItem: EmptyINormalized(),
  testLine: EmptyIGrouped(),
  gradingProposal: EmptyINormalized(),
  lineResult: {},
  lineStatus: {},
};

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(feature.initAction, (state) => {
    state.featureInitialized = true;
  });
  builder.addCase(jobs.loadTestSettings.successAction, (state, data) => {
    createDataSuccessToNormalizedReducer('testSection', 'testSection')(state, data);
    createDataSuccessToNormalizedReducer('testItem', 'testItem')(state, data);
    createDataSuccessToNormalizedReducer('testLine', 'testLine')(state, data);
    buildGroup<ITestSection, ITestLine>('testSection', state.testSection, state.testLine, (tl) => tl.lineNumber);
    for (const section of Object.values(state.testSection.map)) state.testSectionMap[section.section] = section;
    createDataSuccessToNormalizedReducer('gradingProposal', 'gradingProposal')(state, data);

    if (isEmpty(state.lineResult)) {
      for (const testLineId of state.testLine.ids) {
        const proposal: IGradingProposal | undefined = state.gradingProposal.map[testLineId];
        if (!!proposal && proposal.proposedBy === 'expert') {
          state.lineResult[testLineId] = evaluateTestLineSvo(
            upsertTestLineResult(testLineId, state.lineResult[testLineId], { correct: true, points: proposal.points })
          );
          state.lineStatus[testLineId] = 'closed';
        }
      }
    }
  });
  builder.addCase(actions.updateGrading, (state, { payload: { testLineId, points } }) => {
    state.lineResult[testLineId] = evaluateTestLineSvo(
      upsertTestLineResult(testLineId, state.lineResult[testLineId], { correct: true, points })
    );
    state.lineStatus[testLineId] = 'closed';
  });
  builder.addCase(actions.openGrading, (state, { payload: { testLineId } }) => {
    state.lineStatus[testLineId] = 'open';
  });
});
