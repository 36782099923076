import { VFC, ReactNode } from 'react';
//import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { Table, HeaderRow, BodyRow, HeaderCell, BodyCell } from '../../ui/table';
import { ActionIconEdit } from '../../ui/icons';
import { Button } from '../../ui/buttons/button';
import { IScratchPad } from './config';
import moment from 'moment';
import 'moment/locale/de';
import { TId } from '@app/lib/type';

export interface ViewProps {
  evaluations: IScratchPad[];
  onLoadData: (id: TId) => void;
}

export const View: VFC<ViewProps> = ({ evaluations, onLoadData }) => {
  return (
    <Page header={<Header title="Zwischengespeicherte Sprachstandserfassungen" />}>
      <Section>
        <Table>
          <HeaderRow gridTemplateColumns="1fr 1fr 1fr 1fr">
            <HeaderCell>Angelegt am</HeaderCell>
            <HeaderCell>Kodierung des Kindes</HeaderCell>
            <HeaderCell>Verfällt am</HeaderCell>
            <HeaderCell></HeaderCell>
          </HeaderRow>
          {evaluations
            .filter((x) => typeof x.payload === 'string')
            .map((lineData) => (
              <BodyRow key={lineData.id} gridTemplateColumns="1fr 1fr 1fr 1fr">
                <BodyCell>{moment(lineData.sentAt).locale('de-DE').fromNow()}</BodyCell>
                <BodyCell>{lineData.childCode}</BodyCell>
                <BodyCell>{moment(lineData.sentAt).add(1, 'months').locale('de-DE').fromNow()}</BodyCell>
                <BodyCell>
                  <Button variant="text-small" onClick={() => onLoadData(lineData.id)}>
                    <ActionIconEdit />
                  </Button>
                </BodyCell>
              </BodyRow>
            ))}
        </Table>
      </Section>
    </Page>
  );
};
