import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';
import { SectionTitle } from '../typography';

const variantcss = ({
  variant,
  horizontal,
  spread,
}: {
  variant: 'default' | 'done' | 'hint';
  horizontal: boolean;
  spread: boolean;
}) => {
  switch (variant) {
    case 'default':
      return ``;
    case 'hint':
    case 'done':
      return `
        display: flex;
        flex-direction: ${horizontal ? 'row' : 'column'};
        gap: 3rem;
        justify-content: ${spread ? 'space-between' : 'flex-start'};
        align-items: center;
      `;
  }
};

const StyledSection = styled.section<{
  variant: 'default' | 'done' | 'hint';
  narrow: boolean;
  horizontal: boolean;
  spread: boolean;
}>`
  margin-bottom: ${(props) => (props.narrow ? '6rem' : '10rem')};
  ${variantcss}
`;

export interface SectionProps {
  variant?: 'default' | 'done' | 'hint';
  title?: string;
  className?: string;
  children?: ReactNode;
  narrow?: boolean;
  horizontal?: boolean;
  spread?: boolean;
}

export const Section: VFC<SectionProps> = ({
  variant = 'default',
  className,
  title,
  children,
  narrow = false,
  horizontal = false,
  spread = false,
}) => {
  return (
    <StyledSection className={className} variant={variant} narrow={narrow} horizontal={horizontal} spread={spread}>
      {title && <SectionTitle>{title}</SectionTitle>}
      {children}
    </StyledSection>
  );
};

export const SectionMemo = memo(Section);
