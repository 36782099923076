import { take, all, takeLatest, call, delay, fork, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { feature, jobs, actions } from './config';
import {} from '@app/state';
import { PayloadAction } from '@reduxjs/toolkit';
import { tryJob } from '@app/lib/saga';
import {} from './selectors';

export const sagas = [forking];

function* forking() {
  yield takeLatest(feature.initPattern, init);
}

function* init(action) {}
