//import { createAction } from '@reduxjs/toolkit';
import {
  features,
  // createJob
} from '../config';

export const feature = features.resultsPdf;

export const jobs = {};

export const actions = {
  gotoEnterStatements: () => features.enterStatements.routeAction({}),
  gotoTestSVO: () => features.testSVO.routeAction({}),
  gotoTestNGP: () => features.testNGP.routeAction({}),
  gotoTestNominativ: () => features.testNominativ.routeAction({}),
  gotoTestNarration: () => features.testNarration.routeAction({}),
  gotoResultSummary: () => features.resultsSummary.routeAction({}),
};
