import { TId } from '@app/lib/type';
import { createAction } from '@reduxjs/toolkit';
import { features, createJob } from '../config';
import { ITestNarrationStatementGrading } from './types';

export const feature = features.testNarration;

export const jobs = {
  loadTestSettings: createJob.loadTestSettings(),
};

export const testName = 'Erzählen';

export const actions = {
  gotoEnterStatements: () => features.enterStatements.routeAction({}),
  gotoTestSVO: () => features.testSVO.routeAction({}),
  gotoTestNGP: () => features.testNGP.routeAction({}),
  gotoTestNominativ: () => features.testNominativ.routeAction({}),
  gotoTestNarration: () => features.testNarration.routeAction({}),
  gotoResultSummary: () => features.resultsSummary.routeAction({}),
  updateStatementGrading: createAction(
    'UPDATE-STATEMENT-GRADING@TEST-NARRATION',
    (testLineId: TId, update: Partial<ITestNarrationStatementGrading>) => ({
      payload: { testLineId, update },
    })
  ),
  updateOrderGrading: createAction(
    'UPDATE-ORDER-GRADING@TEST-NARRATION',
    (testLineId: TId, update: Partial<ITestNarrationStatementGrading>) => ({
      payload: { testLineId, update },
    })
  ),
  updateConjunctionGrading: createAction(
    'UPDATE-CONJUNCTION-GRADING@TEST-NARRATION',
    (testLineId: TId, update: Partial<ITestNarrationStatementGrading>) => ({
      payload: { testLineId, update },
    })
  ),
  updateCustomObjective: createAction(
    'UPDATE-CUSTOM_OBJECTIVE@TEST-NARRATION',
    (testLineId: TId, customObjective: null | string) => ({
      payload: { testLineId, customObjective },
    })
  ),
};
