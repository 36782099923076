import type { OptionsList } from './languages';

export const countryCodes = [
  ['Abchasien', 'Republik Abchasien', 'ABC'],
  ['Afghanistan', 'Islamische Republik Afghanistan', 'AFG'],
  ['Ägypten', 'Arabische Republik Ägypten', 'EGY'],
  ['Albanien', 'Republik Albanien', 'ALB'],
  ['Algerien', 'Demokratische Volksrepublik Algerien', 'DZA'],
  ['Andorra', 'Fürstentum Andorra', 'AND'],
  ['Angola', 'Republik Angola', 'AGO'],
  ['Antigua und Barbuda', 'Antigua und Barbuda', 'ATG'],
  ['Äquatorialguinea', 'Republik Äquatorialguinea', 'GNQ'],
  ['Argentinien', 'Argentinische Republik', 'ARG'],
  ['Armenien', 'Republik Armenien', 'ARM'],
  ['Arzach', 'Republik Arzach', ''],
  ['Aserbaidschan', 'Republik Aserbaidschan', 'AZE'],
  ['Äthiopien', 'Demokratische Bundesrepublik Äthiopien', 'ETH'],
  ['Australien', 'Australien', 'AUS'],
  ['Bahamas', 'Commonwealth der Bahamas', 'BHS'],
  ['Bahrain', 'Königreich Bahrain', 'BHR'],
  ['Bangladesch', 'Volksrepublik Bangladesch', 'BGD'],
  ['Barbados', 'Barbados', 'BRB'],
  ['Belgien', 'Königreich Belgien', 'BEL'],
  ['Belize', 'Belize', 'BLZ'],
  ['Benin', 'Republik Benin', 'BEN'],
  ['Bhutan', 'Königreich Bhutan', 'BTN'],
  ['Bolivien', 'Plurinationaler Staat Bolivien', 'BOL'],
  ['Bosnien und Herzegowina', 'Bosnien und Herzegowina', 'BIH'],
  ['Botswana', 'Republik Botsuana', 'BWA'],
  ['Brasilien', 'Föderative Republik Brasilien', 'BRA'],
  ['Brunei', 'Brunei Darussalam', 'BRN'],
  ['Bulgarien', 'Republik Bulgarien', 'BGR'],
  ['Burkina Faso', 'Burkina Faso', 'BFA'],
  ['Burundi', 'Republik Burundi', 'BDI'],
  ['Chile', 'Republik Chile', 'CHL'],
  ['Republik China', 'Republik China (Taiwan)', 'TWN'],
  ['Volksrepublik China', 'Volksrepublik China mit Hongkong (HK) und Macau (MO)', 'CHN'],
  ['Cookinseln', 'Cookinseln', 'COK'],
  ['Costa Rica', 'Republik Costa Rica', 'CRI'],
  ['Dänemark', 'Königreich Dänemark mit Färöer (FO) und Grönland (GL)', 'DNK'],
  ['Deutschland', 'Bundesrepublik Deutschland', 'DEU'],
  ['Dominica', 'Commonwealth Dominica', 'DMA'],
  ['Dominikanische Republik', 'Dominikanische Republik', 'DOM'],
  ['Dschibuti', 'Republik Dschibuti', 'DJI'],
  ['Ecuador', 'Republik Ecuador', 'ECU'],
  ['El Salvador', 'Republik El Salvador', 'SLV'],
  ['Elfenbeinküste', 'Republik Côte dIvoire', 'CIV'],
  ['Eritrea', 'Staat Eritrea', 'ERI'],
  ['Estland', 'Republik Estland', 'EST'],
  ['Eswatini', 'Königreich Eswatini', 'SWZ'],
  ['Fidschi', 'Republik Fidschi', 'FJI'],
  ['Finnland', 'Republik Finnland', 'FIN'],
  ['Frankreich', 'Französische Republik ohne Überseegebiete', 'FRA'],
  ['Gabun', 'Gabunische Republik', 'GAB'],
  ['Gambia', 'Republik Gambia', 'GMB'],
  ['Georgien', 'Georgien mit Abchasien und Südossetien', 'GEO'],
  ['Ghana', 'Republik Ghana', 'GHA'],
  ['Grenada', 'Grenada', 'GRD'],
  ['Griechenland', 'Hellenische Republik', 'GRC'],
  ['Guatemala', 'Republik Guatemala', 'GTM'],
  ['Guinea', 'Republik Guinea', 'GIN'],
  ['Guinea-Bissau', 'Republik Guinea-Bissau', 'GNB'],
  ['Guyana', 'Kooperative Republik Guyana', 'GUY'],
  ['Haiti', 'Republik Haiti', 'HTI'],
  ['Honduras', 'Republik Honduras', 'HND'],
  ['Indien', 'Republik Indien mit Kaschmir', 'IND'],
  ['Indonesien', 'Republik Indonesien', 'IDN'],
  ['Irak', 'Republik Irak', 'IRQ'],
  ['Iran', 'Islamische Republik Iran', 'IRN'],
  ['Irland', 'Irland', 'IRL'],
  ['Island', 'Republik Island', 'ISL'],
  ['Israel', 'Staat Israel mit Golanhöhen und Ostjerusalem, ohne Gazastreifen und Westjordanland', 'ISR'],
  ['Italien', 'Italienische Republik', 'ITA'],
  ['Jamaika', 'Jamaika', 'JAM'],
  ['Japan', 'Japan', 'JPN'],
  ['Jemen', 'Republik Jemen', 'YEM'],
  ['Jordanien', 'Haschemitisches Königreich Jordanien', 'JOR'],
  ['Kambodscha', 'Königreich Kambodscha', 'KHM'],
  ['Kamerun', 'Republik Kamerun', 'CMR'],
  ['Kanada', 'Kanada', 'CAN'],
  ['Kap Verde', 'Republik Cabo Verde', 'CPV'],
  ['Kasachstan', 'Republik Kasachstan', 'KAZ'],
  ['Katar', 'Staat Katar', 'QAT'],
  ['Kenia', 'Republik Kenia', 'KEN'],
  ['Kirgisistan', 'Kirgisische Republik', 'KGZ'],
  ['Kiribati', 'Republik Kiribati', 'KIR'],
  ['Kolumbien', 'Republik Kolumbien', 'COL'],
  ['Komoren', 'Union der Komoren', 'COM'],
  ['Kongo, Demokratische Republik', 'Demokratische Republik Kongo', 'COD'],
  ['Kongo, Republik', 'Republik Kongo', 'COG'],
  ['Korea, Nord', 'Demokratische Volksrepublik Korea', 'PRK'],
  ['Korea, Süd', 'Republik Korea', 'KOR'],
  ['Kosovo', 'Republik Kosovo', 'XXK'],
  ['Kroatien', 'Republik Kroatien', 'HRV'],
  ['Kuba', 'Republik Kuba', 'CUB'],
  ['Kuwait', 'Staat Kuwait', 'KWT'],
  ['Laos', 'Demokratische Volksrepublik Laos', 'LAO'],
  ['Lesotho', 'Königreich Lesotho', 'LSO'],
  ['Lettland', 'Republik Lettland', 'LVA'],
  ['Libanon', 'Libanesische Republik', 'LBN'],
  ['Liberia', 'Republik Liberia', 'LBR'],
  ['Libyen', 'Staat Libyen', 'LBY'],
  ['Liechtenstein', 'Fürstentum Liechtenstein', 'LIE'],
  ['Litauen', 'Republik Litauen', 'LTU'],
  ['Luxemburg', 'Großherzogtum Luxemburg', 'LUX'],
  ['Madagaskar', 'Republik Madagaskar', 'MDG'],
  ['Malawi', 'Republik Malawi', 'MWI'],
  ['Malaysia', 'Malaysia', 'MYS'],
  ['Malediven', 'Republik Malediven', 'MDV'],
  ['Mali', 'Republik Mali', 'MLI'],
  ['Malta', 'Republik Malta', 'MLT'],
  ['Marokko', 'Königreich Marokko ohne Westsahara (EH)', 'MAR'],
  ['Marshallinseln', 'Republik Marshallinseln', 'MHL'],
  ['Mauretanien', 'Islamische Republik Mauretanien', 'MRT'],
  ['Mauritius', 'Republik Mauritius', 'MUS'],
  ['Mexiko', 'Vereinigte Mexikanische Staaten', 'MEX'],
  ['Mikronesien', 'Föderierte Staaten von Mikronesien', 'FSM'],
  ['Moldau', 'Republik Moldau mit Transnistrien', 'MDA'],
  ['Monaco', 'Fürstentum Monaco', 'MCO'],
  ['Mongolei', 'Mongolei', 'MNG'],
  ['Montenegro', 'Montenegro', 'MNE'],
  ['Mosambik', 'Republik Mosambik', 'MOZ'],
  ['Myanmar', 'Republik der Union Myanmar', 'MMR'],
  ['Namibia', 'Republik Namibia', 'NAM'],
  ['Nauru', 'Republik Nauru', 'NRU'],
  ['Nepal', 'Demokratische Bundesrepublik Nepal', 'NPL'],
  ['Neuseeland', 'Neuseeland', 'NZL'],
  ['Nicaragua', 'Republik Nicaragua', 'NIC'],
  ['Niederlande', 'Königreich der Niederlande ohne Aruba (AW) Curaçao (CW) und Sint Maarten (SX)', 'NLD'],
  ['Niger', 'Republik Niger', 'NER'],
  ['Nigeria', 'Bundesrepublik Nigeria', 'NGA'],
  ['Niue', 'Niue', 'NIU'],
  ['Nordmazedonien', 'Republik Nordmazedonien', 'MKD'],
  ['Norwegen', 'Königreich Norwegen ohne Svalbard (SJ)', 'NOR'],
  ['Oman', 'Sultanat Oman', 'OMN'],
  ['Österreich', 'Republik Österreich', 'AUT'],
  ['Osttimor / Timor-Leste', 'Demokratische Republik Timor-Leste', 'TLS'],
  ['Pakistan', 'Islamische Republik Pakistan ohne Kaschmir', 'PAK'],
  ['Palästina', 'Staat Palästina', 'PSE'],
  ['Palau', 'Republik Palau', 'PLW'],
  ['Panama', 'Republik Panama', 'PAN'],
  ['Papua-Neuguinea', 'Unabhängiger Staat Papua-Neuguinea', 'PNG'],
  ['Paraguay', 'Republik Paraguay', 'PRY'],
  ['Peru', 'Republik Peru', 'PER'],
  ['Philippinen', 'Republik der Philippinen', 'PHL'],
  ['Polen', 'Republik Polen', 'POL'],
  ['Portugal', 'Portugiesische Republik', 'PRT'],
  ['Ruanda', 'Republik Ruanda', 'RWA'],
  ['Rumänien', 'Rumänien', 'ROU'],
  ['Russland', 'Russische Föderation ohne Krim', 'RUS'],
  ['Salomonen', 'Salomonen', 'SLB'],
  ['Sambia', 'Republik Sambia', 'ZMB'],
  ['Samoa', 'Unabhängiger Staat Samoa', 'WSM'],
  ['San Marino', 'Republik San Marino', 'SMR'],
  ['São Tomé und Príncipe', 'Demokratische Republik São Tomé und Príncipe', 'STP'],
  ['Saudi-Arabien', 'Königreich Saudi-Arabien', 'SAU'],
  ['Schweden', 'Königreich Schweden', 'SWE'],
  ['Schweiz', 'Schweizerische Eidgenossenschaft', 'CHE'],
  ['Senegal', 'Republik Senegal', 'SEN'],
  ['Serbien', 'Republik Serbien ohne Kosovo', 'SRB'],
  ['Seychellen', 'Republik Seychellen', 'SYC'],
  ['Sierra Leone', 'Republik Sierra Leone', 'SLE'],
  ['Simbabwe', 'Republik Simbabwe', 'ZWE'],
  ['Singapur', 'Republik Singapur', 'SGP'],
  ['Slowakei', 'Slowakische Republik', 'SVK'],
  ['Slowenien', 'Republik Slowenien', 'SVN'],
  ['Somalia', 'Bundesrepublik Somalia mit Somaliland', 'SOM'],
  ['Spanien', 'Königreich Spanien', 'ESP'],
  ['Sri Lanka', 'Demokratische Sozialistische Republik Sri Lanka', 'LKA'],
  ['St. Kitts und Nevis', 'Föderation St. Kitts und Nevis', 'KNA'],
  ['St. Lucia', 'St. Lucia', 'LCA'],
  ['St. Vincent und die Grenadinen', 'St. Vincent und die Grenadinen', 'VCT'],
  ['Südafrika', 'Republik Südafrika', 'ZAF'],
  ['Sudan', 'Republik Sudan', 'SDN'],
  ['Südossetien', 'Südossetien', 'SOS'],
  ['Südsudan', 'Republik Südsudan', 'SSD'],
  ['Suriname', 'Republik Suriname', 'SUR'],
  ['Syrien', 'Arabische Republik Syrienmit Golanhöhen', 'SYR'],
  ['Tadschikistan', 'Republik Tadschikistan', 'TJK'],
  ['Tansania', 'Vereinigte Republik Tansania', 'TZA'],
  ['Thailand', 'Königreich Thailand', 'THA'],
  ['Togo', 'Republik Togo', 'TGO'],
  ['Tonga', 'Königreich Tonga', 'TON'],
  ['Trinidad und Tobago', 'Republik Trinidad und Tobago', 'TTO'],
  ['Tschad', 'Republik Tschad', 'TCD'],
  ['Tschechien', 'Tschechische Republik', 'CZE'],
  ['Tunesien', 'Tunesische Republik', 'TUN'],
  ['Türkei', 'Republik Türkei', 'TUR'],
  ['Turkmenistan', 'Turkmenistan', 'TKM'],
  ['Tuvalu', 'Tuvalu', 'TUV'],
  ['Uganda', 'Republik Uganda', 'UGA'],
  ['Ukraine', 'Ukraine mit Krim', 'UKR'],
  ['Ungarn', 'Ungarn', 'HUN'],
  ['Uruguay', 'Republik Östlich des Uruguay', 'URY'],
  ['Usbekistan', 'Republik Usbekistan', 'UZB'],
  ['Vanuatu', 'Republik Vanuatu', 'VUT'],
  ['Vatikanstadt', 'Staat Vatikanstadt', 'VAT'],
  ['Venezuela', 'Bolivarische Republik Venezuela', 'VEN'],
  ['Vereinigte Arabische Emirate', 'Vereinigte Arabische Emirate', 'ARE'],
  ['Vereinigte Staaten', 'Vereinigte Staaten von Amerika ohne Außengebiete', 'USA'],
  [
    'Vereinigtes Königreich',
    'Vereinigtes Königreich Großbritannien und Nordirland ohne Überseegebiete und Kronbesitzungen',
    'GBR',
  ],
  ['Vietnam', 'Sozialistische Republik Vietnam', 'VNM'],
  ['Weißrussland', 'Republik Belarus', 'BLR'],
  ['Westsahara', 'Demokratische Arabische Republik Sahara', 'ESH'],
  ['Zentralafrikanische Republik', 'Zentralafrikanische Republik', 'CAF'],
  ['Zypern', 'Republik Zypern mit Nordzypern', 'CYP'],
];

export const getSortedCountryOptions = (): OptionsList[] => {
  return countryCodes.map((c) => ({ value: c[2], label: c[0] })).sort((a, b) => a.label.localeCompare(b.label));
};

export const isCountryCodeValid = (code: string) => {
  return countryCodes.find((cc) => cc[2] === code) !== undefined;
};
