import { VFC, memo } from 'react';
import styled from 'styled-components/macro';
import { Card, CardBodySection, CardExpander, CardHeader, CardHeaderContent } from '../../../ui/layout/card';
import { NumericGradingButton } from '../../../ui/buttons/grading';
import { ITestSVOStatement } from '../types';

const StyledGradingColor = ({ grading }: { grading: null | 0 | 1 | 2 }) => {
  switch (grading) {
    case 0:
      return `#C01616`;
    case 1:
      return `#2BA834`;
    case 2:
      return `#2BA834`;
    default:
      return `#c9c9c9`;
  }
};

const StyledGrading = styled.span<{ grading: null | 0 | 1 | 2 }>`
  font-family: Raleway;
  font-weight: 600;
  font-size: 6rem;
  letter-spacing: 0.74px;
  color: ${StyledGradingColor};
`;

const StyledCardBodyInner = styled.section`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-bottom: 1rem;
`;

export interface ResultCardProps extends ITestSVOStatement {
  className?: string;
  onGrade: (points: 0 | 1 | 2) => void;
  onRegrade: () => void;
}

export const ResultCard: VFC<ResultCardProps> = ({
  className,
  label,
  text,
  statement,
  proposal,
  status,
  grading,
  onGrade,
  onRegrade,
}) => {
  const success = grading !== null;
  return (
    <Card className={className} variant={success ? 'success' : 'default'}>
      <CardHeader>
        <CardHeaderContent
          label={label}
          hint={statement || undefined}
          aside={<StyledGrading grading={grading}>{grading !== null ? grading : '?'}</StyledGrading>}
        >
          {text}
        </CardHeaderContent>
      </CardHeader>
      {status === 'closed' && <CardExpander onExpand={onRegrade} />}
      {status !== 'closed' && (
        <CardBodySection title="Bewertung">
          <StyledCardBodyInner>
            <NumericGradingButton
              caption={0}
              checked={grading === 0}
              basedOn={proposal?.points === 0 ? proposal?.basedOn : 'none'}
              onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade(0)}
            />
            <NumericGradingButton
              caption={1}
              checked={grading === 1}
              basedOn={proposal?.points === 1 ? proposal?.basedOn : 'none'}
              onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade(1)}
            />
            <NumericGradingButton
              caption={2}
              checked={grading === 2}
              basedOn={proposal?.points === 2 ? proposal?.basedOn : 'none'}
              onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade(2)}
            />
          </StyledCardBodyInner>
        </CardBodySection>
      )}
    </Card>
  );
};

export const ResultCardMemo = memo(ResultCard);
