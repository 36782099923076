import { ActionCreatorWithPreparedPayload, AnyAction, createAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';

export interface IFeatureConfig {
  key: any;
  pattern: (action: any) => action is AnyAction;
  routeAction: ActionCreatorWithPreparedPayload<
    [payload: any],
    any,
    string,
    never,
    {
      featureKey: string;
    }
  >;
  initPattern: (action: any) => action is AnyAction;
  initAction: ActionCreatorWithPreparedPayload<
    [routeaction?: any],
    {
      query: any;
      data: Pick<any, string | number | symbol>;
    },
    string,
    never,
    {
      featureKey: string;
    }
  >;
  destroyPattern: (action: any) => action is AnyAction;
  destroyAction: ActionCreatorWithPreparedPayload<
    [],
    void,
    string,
    never,
    {
      featureKey: string;
    }
  >;
  routepath: string;
}

const featurePattern = (featureKey) => (action): action is AnyAction => action.meta?.featureKey === featureKey;
const featureInitPattern = (featureKey) => (action): action is AnyAction =>
  action.type === `FEATURE_INIT_${featureKey}`;
const featureDestroyPattern = (featureKey) => (action): action is AnyAction =>
  action.type === `FEATURE_DESTROY_${featureKey}`;

const createRouteActionCreator = (featureKey) =>
  createAction(`ROUTE_${featureKey}`, (payload) => ({ payload, meta: { featureKey } }));

const createInitActionCreator = (featureKey) =>
  createAction(`FEATURE_INIT_${featureKey}`, (routeaction = undefined) => ({
    payload: {
      query: routeaction?.meta?.location?.current?.query ?? {},
      data: omit(routeaction?.payload ?? {}, ['query']),
    },
    meta: {
      featureKey,
    },
  }));

const createDestroyActionCreator = (featureKey) =>
  createAction(`FEATURE_DESTROY_${featureKey}`, () => ({
    payload: undefined,
    meta: {
      featureKey,
    },
  }));

export const featureConfig = (featureKey: string, routepath: string) => ({
  key: featureKey,
  pattern: featurePattern(featureKey),
  routeAction: createRouteActionCreator(featureKey),
  initPattern: featureInitPattern(featureKey),
  initAction: createInitActionCreator(featureKey),
  destroyPattern: featureDestroyPattern(featureKey),
  destroyAction: createDestroyActionCreator(featureKey),
  routepath: routepath,
});
