import { takeLatest, takeLeading, call, select, put } from 'redux-saga/effects';
import { feature, jobs, actions, selectors, controlActions, IScratchPad } from './config';
import { IRootState } from '@app/state';
import { tryJob } from '@app/lib/saga';
import { compileEvent, TId } from '@app/lib/type';
import { PayloadAction } from '@reduxjs/toolkit';
import { MainmenuLayer } from '@app/ui/mainmenu';
import { dateReviver } from 'src/lib/type';

export const sagas = [forking];

function* forking() {
  yield takeLatest(feature.initPattern, init);
  yield takeLeading(controlActions.routing.saveApplicationData.type, saveScratchToServer);
  yield takeLeading(actions.loadData.type, loadIncompleteEvaluationData);
  yield takeLeading(controlActions.routing.loadApplicationData.type, loadedApplicationData);
}

function* init() {
  try {
    yield call(tryJob, jobs.loadIncompleteEvaluations);
  } catch (err) {
    console.error(err);
  }
}

function* loadIncompleteEvaluationData(action: PayloadAction<TId>) {
  const batch = action.payload;
  const evaluationToLoad: IScratchPad = yield select(selectors.incompleteEvaluations.getEvaluationById, batch);
  const parsedData: Partial<IRootState> =
    typeof evaluationToLoad.payload === 'string'
      ? JSON.parse(evaluationToLoad.payload, dateReviver)
      : evaluationToLoad.payload;
  yield put(controlActions.routing.loadApplicationData(parsedData));
}

function* loadedApplicationData() {
  yield put(actions.gotoPupilForm());
}

function* saveScratchToServer(action: PayloadAction<{ fromLayer: MainmenuLayer }>) {
  switch (action.payload.fromLayer) {
    case 'evaluation': {
      if ((yield select(selectors.resultsSummary.getSent)) === 'ok')
        // Check if the data has already been submitted successfully to fairchance
        return;

      // Gather stuff
      // Identifier for batch to save
      const batch = yield select(selectors.pupilForm.getBatchId);
      const stateToSave: Partial<IRootState> = yield select(selectors.incompleteEvaluations.getStateToSave);
      const sentBy: string | null = yield select(selectors.auth.getUserId);
      const childCode: string | null = yield select(selectors.pupilForm.getChildCode);

      // login and non-empty childcode is necessary
      if (!sentBy || !childCode || childCode === '') return;

      const scratchPad: IScratchPad = { id: batch, childCode, sentBy, payload: JSON.stringify(stateToSave) };

      const eventsToSend = [compileEvent('scratchPad', 'upserted', scratchPad)];

      try {
        yield call(tryJob, jobs.saveEvaluation, eventsToSend);
      } catch (err) {
        console.error(err);
      }
    }
  }
}
