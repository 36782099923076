import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { HelpButton } from '../../ui/help/help-button';
import { EvaluationLegend } from '../../ui/help/evaluation-legend';
import { ITestSVOStatement } from './types';
import { ResultCard } from './partials/result-card';
import { CardsGrid } from '../../ui/layout/grid';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon } from '../../ui/icons';
import { Motivation, Warning } from '../../ui/typography';
import { ResultCircle } from '../../ui/layout/result-circle';
import { supportColorToText, TSupportColor } from '@app/state';
import { TId } from '@app/lib/type';

const StyledTestPage = styled(Page)`
  .svo {
    font-weight: bold;
  }
`;

export interface ViewProps {
  done: boolean;
  gradeTotal: number;
  gradeColor: TSupportColor;
  onNext: () => void;
  onGrade: (statementId: TId, points: 0 | 1 | 2) => void;
  onRegrade: (statementId: TId) => void;
  statementsSVO: ITestSVOStatement[];
  statementsSVOP: ITestSVOStatement[];
  statementsSHOV: ITestSVOStatement[];
}

export const View: VFC<ViewProps> = ({
  statementsSVO,
  statementsSVOP,
  statementsSHOV,
  done,
  gradeTotal,
  gradeColor,
  onNext,
  onGrade,
  onRegrade,
}) => {
  return (
    <StyledTestPage
      header={
        <Header
          title="Satzbau & Wortschatz"
          left={<EvaluationLegend />}
          right={<HelpButton title="Kriterien">Für den Auswertungsbogen 1 - Satzbau & Wortschatz</HelpButton>}
        />
      }
    >
      <Section title="Subjekt – Verb – Objekt">
        <CardsGrid>
          {statementsSVO.map((s) => {
            return (
              <ResultCard
                key={s.id}
                onGrade={(points) => onGrade(s.id, points)}
                onRegrade={() => onRegrade(s.id)}
                {...s}
              />
            );
          })}
        </CardsGrid>
      </Section>
      <Section title="Subjekt – Verb – Objekt - Partikel">
        <CardsGrid>
          {statementsSVOP.map((s) => {
            return (
              <ResultCard
                key={s.id}
                onGrade={(points) => onGrade(s.id, points)}
                onRegrade={() => onRegrade(s.id)}
                {...s}
              />
            );
          })}
        </CardsGrid>
      </Section>
      <Section title="Subjekt – Hilfsverb – Objekt - Vollverb">
        <CardsGrid>
          {statementsSHOV.map((s) => {
            return (
              <ResultCard
                key={s.id}
                onGrade={(points) => onGrade(s.id, points)}
                onRegrade={() => onRegrade(s.id)}
                {...s}
              />
            );
          })}
        </CardsGrid>
      </Section>
      <Section variant="done">
        {done && (
          <ResultCircle
            title="Punkte gesamt"
            grade={gradeTotal}
            info={
              <span>
                Förderfarbe:
                <br />
                {supportColorToText(gradeColor)}
              </span>
            }
          />
        )}
        {done ? (
          <Motivation>Der erste Test ist schon erledigt!</Motivation>
        ) : (
          <Warning>Bitte füllen Sie alles aus!</Warning>
        )}
        <Button variant="primary" onClick={onNext} disabled={!done}>
          <ButtonNextIcon />
          WEITER
        </Button>
      </Section>
    </StyledTestPage>
  );
};
