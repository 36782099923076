import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { SectionTitle, Success } from '../../ui/typography';
import { Section } from '../../ui/layout/section';
import { Fieldset } from '../../ui/form/fieldset';
import { Input } from '../../ui/form/input';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon } from '../../ui/icons';
import { Motivation, Warning } from '../../ui/typography';
import { ResultCircle } from 'src/ui/layout/result-circle';
import { supportColorToText, convertToRoman, TSupportColor } from 'src/state';
import { IChildInformation } from '@app/state/types';
import { LabeledCheckbox } from './partials/labeled-checkbox';

const StyledChildForm = styled(Section)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-template-rows: 1fr;
  column-gap: 4rem;
`;

const StyledResultGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-template-rows: 1fr;
  column-gap: 2rem;
  justify-items: center;
`;

const StyledResultColumn = styled.div``;

export interface IGrades {
  svoGrade: number;
  svoGradeColor: TSupportColor;
  ngpAcquired: boolean;
  ngpCorrectAnswers: number;
  ngpTotalAnswers: number;
  nominativLevel: number;
  narrationGrade: number;
}

export interface ViewProps {
  formValues: Partial<IChildInformation>;
  grades: IGrades;
  done: {
    pupilForm: boolean;
    enterStatements: boolean;
    svo: boolean;
    ngp: boolean;
    nominativ: boolean;
    narration: boolean;
  };
  allDone: boolean;
  onSend: () => void;
  sent: false | 'ok' | 'error';
  onNextChild: () => void;
  testMode: boolean;
  onSwitchTestMode: (on: boolean) => void;
  onCreatePdf: () => void;
}

export const View: VFC<ViewProps> = ({
  formValues = {},
  grades: { svoGrade, svoGradeColor, ngpAcquired, ngpCorrectAnswers, ngpTotalAnswers, nominativLevel, narrationGrade },
  onSend,
  done,
  allDone,
  sent,
  onNextChild,
  testMode,
  onSwitchTestMode,
  onCreatePdf,
}) => {
  return (
    <Page header={<Header title="Übersicht Sprachstandserfassung" />}>
      <StyledChildForm>
        <Fieldset label="Codierung des Kindes">
          <Input required name="childCode" value={formValues.childCode ?? ''} disabled />
        </Fieldset>
        <Fieldset label="Geburtsdatum">
          <Input
            name="dateOfBirth"
            type="date"
            value={formValues.dateOfBirth?.toISOString().substring(0, 10) ?? ''}
            disabled
          />
        </Fieldset>
        <Fieldset label="Testung durchgeführt am">
          <Input
            name="examDate"
            type="date"
            value={formValues.examDate?.toISOString().substring(0, 10) ?? ''}
            disabled
          />
        </Fieldset>
      </StyledChildForm>
      {allDone && (
        <Section>
          <StyledResultGrid>
            <StyledResultColumn>
              <SectionTitle>Satzbau & Wortschatz</SectionTitle>
              <ResultCircle
                title="Punkte gesamt"
                grade={svoGrade}
                info={
                  <span>
                    Förderfarbe:
                    <br />
                    {supportColorToText(svoGradeColor)}
                  </span>
                }
              />
            </StyledResultColumn>
            <StyledResultColumn>
              <SectionTitle>Genus: NGP I</SectionTitle>
              <ResultCircle
                title="NGP erworben?"
                grade={ngpAcquired ? 'JA' : 'NEIN'}
                info={
                  <span>
                    Richtige Antworten:
                    <br />
                    {ngpCorrectAnswers} von {ngpTotalAnswers}
                  </span>
                }
              />
            </StyledResultColumn>
            <StyledResultColumn>
              <SectionTitle>Genus: Nominativ I</SectionTitle>
              <ResultCircle title="Entwicklungsstufe" grade={convertToRoman(nominativLevel)} />
            </StyledResultColumn>
            <StyledResultColumn>
              <SectionTitle>Erzählen</SectionTitle>
              <ResultCircle title="Punkte gesamt" grade={narrationGrade} />
            </StyledResultColumn>
          </StyledResultGrid>
        </Section>
      )}
      <Section variant="done">
        {allDone && <Motivation>Alles erfasst!</Motivation>}
        {!allDone && (
          <Warning>
            <span>Bitte ergänzen Sie die Eingaben vor dem Absenden:</span>
            <br />
            <ul>
              {!done.pupilForm && <li>Angaben zum Kind</li>}
              {!done.enterStatements && <li>Sätze des Kindes / keine Äußerung.</li>}
              {!done.svo && <li>Angaben im 1. Test - SVO</li>}
              {!done.ngp && <li>Angaben im 2. Test - NGP I</li>}
              {!done.nominativ && <li>Angaben im 3. Test - Nominativ</li>}
              {!done.narration && <li>Angaben im 4. Test - Erzählen</li>}
            </ul>
          </Warning>
        )}
        {sent !== 'ok' && (
          <Button variant="primary" onClick={onSend} disabled={!allDone}>
            <ButtonNextIcon />
            Angaben an Fairchance übertragen
          </Button>
        )}
        {sent === 'ok' && <Success>Angaben erfolgreich übertragen!</Success>}
        {sent === 'ok' && (
          <Button variant="primary" onClick={onCreatePdf} disabled={!allDone}>
            <ButtonNextIcon />
            Als PDF herunterladen
          </Button>
        )}
        {sent === 'error' && (
          <Warning>
            Fehler beim Übertragen der Angaben! Bitte versuchen Sie es in ein paar Minuten nocheinmal. Sollte das
            Problem weiterhin bestehen, wenden Sie sich bitte an Fairchance.
          </Warning>
        )}
        <LabeledCheckbox
          label="Ich will das nur mal testen. Übertragen nur simulieren."
          checked={testMode}
          onChange={onSwitchTestMode}
        />
      </Section>
    </Page>
  );
};
