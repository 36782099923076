//import { createAction } from '@reduxjs/toolkit';
import { IRootState } from '@app/state';
import { IWithId, TId } from '@app/lib/type';
import { features, createJob } from '../config';
import { createAction } from '@reduxjs/toolkit';
export { selectors, controlActions } from '../config';

export const feature = features.incompleteEvaluations;

export interface IScratchPad extends IWithId {
  // the id is the batch id
  childCode: string | null;
  sentBy: TId;
  sentAt?: string;
  payload: Partial<IRootState> | string;
}

export const jobs = {
  saveEvaluation: createJob.saveEvaluation(),
  loadIncompleteEvaluations: createJob.loadIncompleteEvaluations(),
};

export const actions = {
  gotoPupilForm: () => features.pupilForm.routeAction({}),
  loadData: createAction('LOAD_INCOMPLETE_EVALUATION', (id: TId) => ({ payload: id })),
};
