import { createAction } from '@reduxjs/toolkit';
import { features, createJob } from '../config';
export { selectors } from '../config';

export const feature = features.resultsSummary;

export const jobs = {
  savePseudonym: createJob.savePseudonym(),
  saveEvaluation: createJob.saveEvaluation(),
};

export const actions = {
  gotoEnterStatements: () => features.enterStatements.routeAction({}),
  gotoTestSVO: () => features.testSVO.routeAction({}),
  gotoTestNGP: () => features.testNGP.routeAction({}),
  gotoTestNominativ: () => features.testNominativ.routeAction({}),
  gotoTestNarration: () => features.testNarration.routeAction({}),
  gotoResultSummary: () => features.resultsSummary.routeAction({}),
  send: createAction('SEND@RESULTS-SUMMARY'),
  switchTestMode: createAction('SWITCH_TEST_MODE@RESULTS-SUMMARY', (on: boolean) => ({ payload: { testMode: on } })),
  createPdf: createAction('CREATE-PDF@RESULTS-SUMMARY'),
  createPdfDone: createAction('CREATE-PDF-DONE@RESULTS-SUMMARY'),
};
