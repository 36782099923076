import { put, take, takeLatest, call, delay, fork, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { feature, jobs, actions } from './config';
import {} from '@app/state';
import { PayloadAction } from '@reduxjs/toolkit';
import { tryJob } from '@app/lib/saga';
//import {} from './selectors';
import { selectors } from '../config';
import { IChildFormData } from '@app/state/types/model';

export const sagas = [forking, waitForNewChild];

function* forking() {
  yield takeLatest(feature.initPattern, init);
}

function* init(action) {
  const previous = yield select(selectors.pupilForm.getFormValues);
  const initializer: Partial<IChildFormData> = {};

  if (!previous.nameOfTeacher) {
    initializer.nameOfTeacher = yield select(selectors.auth.getUserFullname);
  }

  if (!previous.nameOfInstitute) {
    const userGroups = yield select(selectors.auth.groups);
    initializer.nameOfInstitute = (userGroups.length && userGroups[0]) ?? null;
  }

  yield put(actions.pupilFormSubmitted(initializer));

  //yield fork(waitUntilSubmitted);
}
/*
function* waitUntilSubmitted() {
  while (true) {
    yield take(actions.pupilFormSubmitted);
    yield put(actions.gotoEnterStatements());
  }
}
*/

function* waitForNewChild() {
  yield takeLatest(actions.newChild, onResetGetConfirmation);
}

function* onResetGetConfirmation(action) {
  if (window.confirm('Möchten Sie wirklich alle Daten löschen und neu starten?')) {
    yield put(actions.reset());
  }
}
