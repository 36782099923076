import { TId } from '@app/lib/type';
import { createAction } from '@reduxjs/toolkit';
import { features, createJob } from '../config';
import { TTestLineStatus, ITestNominativStatementGrading } from './types';
export { selectors } from '../config';

export const feature = features.testNominativ;

export const jobs = {
  loadTestSettings: createJob.loadTestSettings(),
};

export const testName = 'Genus: Nominativ I';

interface IStatusActionPayload {
  payload: {
    testLineId: TId;
    status: TTestLineStatus;
  };
}

export const actions = {
  gotoEnterStatements: () => features.enterStatements.routeAction({}),
  gotoTestSVO: () => features.testSVO.routeAction({}),
  gotoTestNGP: () => features.testNGP.routeAction({}),
  gotoTestNominativ: () => features.testNominativ.routeAction({}),
  gotoTestNarration: () => features.testNarration.routeAction({}),
  gotoResultSummary: () => features.resultsSummary.routeAction({}),
  gotoNextTest: createAction('GO-TO-NEXT-TEST@TEST-NOMINATIV'),
  updateGrading: createAction(
    'UPDATE-GRADING@TEST-NOMINATIV',
    (testLineId: TId, update: Partial<ITestNominativStatementGrading>) => ({
      payload: { testLineId, update },
    })
  ),
  openGrading: createAction(
    'OPEN-GRADING@TEST-NOMINATIV',
    (testLineId: TId): IStatusActionPayload => ({
      payload: { testLineId, status: 'open' },
    })
  ),
};
