import { featureConfig } from '@app/lib/config';
import { apiJobConfig } from '@app/lib/config';
import { IFeatureConfig } from '@app/lib/config';
import { IEvent } from '@app/lib/type';
import {} from '@app/state/types';
export { selectors } from './config.selectors';
export { controlActions } from './config.actions';

export const features: Record<string, IFeatureConfig> = {
  //start: featureConfig('START-PAGE', '/'),
  auth: featureConfig('AUTH', '/logout'),
  home: featureConfig('HOME', '/'),
  pupilForm: featureConfig('PUPIL-FORM', '/pupil-form'),
  enterStatements: featureConfig('ENTER-STATEMENTS', '/enter-statements'),
  testSVO: featureConfig('TEST-SVO', '/test-svo'),
  testNGP: featureConfig('TEST-NGP', '/test-ngp'),
  testNominativ: featureConfig('TEST-NOMINATIV', '/test-nominativ'),
  testNarration: featureConfig('TEST-NARRATION', '/test-narration'),
  resultsSummary: featureConfig('RESULTS-SUMMARY', '/results-summary'),
  resultsPdf: featureConfig('RESULTS-PDF', '/results-pdf'),
  incompleteEvaluations: featureConfig('INCOMPLETE-EVALUATIONS', '/incomplete-evaluations'),
  completeEvaluations: featureConfig('COMPLETE-EVALUATIONS', '/complete-eveluations'),
  statementsReview: featureConfig('STATEMENTS-REVIEW', '/statements-review'),
};

export const registerFeature = (featureName: string, cfg: IFeatureConfig) => {
  features[featureName] = cfg;
};

const saveEvents = (events: IEvent[]) => ({
  events,
});

export const createJob = {
  loadEnterStatements: apiJobConfig('loadEnterStatements', 'get', 'enter-statements'),
  loadTestSettings: apiJobConfig('loadTestSettings', 'get', 'test-settings'),
  savePseudonym: apiJobConfig('savePseudonym', 'put', 'save-pseudonym', saveEvents),
  saveEvaluation: apiJobConfig('saveEvaluation', 'put', 'save-evaluation', saveEvents),
  loadIncompleteEvaluations: apiJobConfig('loadIncompleteEvaluations', 'get', 'scratch-pad'),
  loadReviewStatements: apiJobConfig('loadReviewStatements', 'get', 'review-statements'),
  loadReviewLines: apiJobConfig('loadReviewLines', 'get', 'review-lines'),
  saveReviewLines: apiJobConfig('saveReviewLines', 'put', 'save-review-lines', saveEvents),
};
