import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { storeCreator, TPreloadedState } from './state/store';
import { Provider } from 'react-redux';
// import reportWebVitals from './reportWebVitals';

export type { TPreloadedState };

export interface IWrapAppProps {
  preloadedState?: TPreloadedState;
  children: React.ReactNode;
}

let store: ReturnType<typeof storeCreator> | null = null;

export const WrapApp: React.FC<IWrapAppProps> = ({ children, preloadedState }) => {
  if (!store) {
    store = storeCreator(preloadedState);
  }
  return <Provider store={store}>{children}</Provider>;
};

ReactDOM.render(
  <WrapApp>
    <App />
  </WrapApp>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
