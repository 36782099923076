import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { IChildFormData } from '@app/state/types/model';
import { selectors } from '../config';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  formValues: selectors.pupilForm.getFormValues(state),
  done: selectors.pupilForm.getIsDone(state),
  facilityLocked: !selectors.auth.isAuthorizedForRole(state, 'SSE-Alle-Einrichtungen'),
  facilities: selectors.auth.groups(state),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onSubmit: (data: Partial<IChildFormData>) => dispatch(actions.pupilFormSubmitted(data)),
  onNext: () => dispatch(actions.gotoEnterStatements()),
  onNewChild: () => dispatch(actions.newChild()),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
