import { createReducer } from '@reduxjs/toolkit';
import { EmptyINormalized, INormalized } from '@app/lib/type';
import { jobs, actions, IScratchPad } from './config';
import { createDataSuccessToNormalizedReducer } from '@app/lib/reducer';

interface IState {
  evaluations: INormalized<IScratchPad>;
}

export type TState = IState;

const initialState: TState = {
  evaluations: EmptyINormalized(),
};

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(jobs.loadIncompleteEvaluations.successAction, (state, action) => {
    createDataSuccessToNormalizedReducer('evaluations', 'scratchPad')(state, action);
  });
});
