import { createReducer } from '@reduxjs/toolkit';
import { jobs, actions } from './config';

interface IState {
  sent: false | 'ok' | 'error';
  errorMessage?: any;
  testMode: boolean;
}

export type TState = IState;

const initialState: TState = {
  sent: false,
  errorMessage: '',
  testMode: false,
};

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(jobs.saveEvaluation.successAction, (state) => {
    state.sent = 'ok';
  });
  builder.addCase(jobs.saveEvaluation.failedAction, (state, action) => {
    state.sent = 'error';
    state.errorMessage = action.error.message;
  });
  builder.addCase(actions.switchTestMode, (state, action) => {
    state.testMode = action.payload.testMode;
  });
});
