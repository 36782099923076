import React from 'react';
import { connect } from 'react-redux';

export interface IViewProps {
  role: string;
}

const AuthorizedView: React.FC<IViewProps> = ({ role }) => {
  return <React.Fragment></React.Fragment>;
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AuthorizedView);
