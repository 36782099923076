import { SelectHTMLAttributes } from 'react';
import styled from 'styled-components/macro';

export const Select = styled.select`
  /* appearance: none; */
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 0.5rem;
  /*max-width: 33rem;*/
  height: 4.5rem;
  font-family: Raleway;
  font-weight: 600;
  font-size: 1.616rem;
  color: inherit;
  letter-spacing: 0.015rem;
  line-height: 2.4;
  padding: 0 1.5rem;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  & option {
    appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.32);
    border-radius: 0.5rem;
    font-family: Raleway;
    font-weight: 600;
    font-size: 1.616rem;
    color: inherit;
    letter-spacing: 0.015rem;
    line-height: 2.4;
    padding: 1rem 1.5rem;
    margin: 0;
  }
  &:disabled {
    background: #ffffff;
    cursor: not-allowed;
  }
  &:required:invalid {
    border-color: #c01616;
    background-color: #fff3f3;
  }
  &:required:valid {
    border-color: #2ba834;
    background-color: #f5f8f6;
  }
`;

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {}
