import { delay, put, all, call, spawn, take } from 'redux-saga/effects';
import { redirector } from './redirector';
import { sagas as featureSagas } from '@app/features/config.redux';

const catchAndRestart = (saga) => {
  return function* () {
    yield spawn(function* () {
      let keepRunning = true;
      while (keepRunning) {
        try {
          yield call(saga);
        } catch (e) {
          if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
            console.error('Saga error! Restarting in 1s.', e);
            yield delay(1000); // Workaround to avoid infinite error loops
          } else {
            // Fail in test env
            keepRunning = false;
          }
        }
      }
    });
  };
};

const rootSagas = [
  // put sagas here
  redirector,
  ...featureSagas,
].map(catchAndRestart);

export function* clientRootSaga(initialize, waitForPattern) {
  // start all sagas first so they can listen during app start
  yield all([...rootSagas.map((saga) => call(saga))]);

  // app start
  yield put({ type: 'APP_STARTING' });
  // TODO: yield call(authInitialize);

  if (waitForPattern) {
    yield take(waitForPattern);
  }

  yield put({ type: 'APP_STARTED' });

  // dispatch initial routing to load requested route
  if (initialize) yield call(initialize);
}
