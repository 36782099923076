import { InputHTMLAttributes } from 'react';
import styled from 'styled-components/macro';

export const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 0.5rem;
  ${({ type }) => (type !== 'checkbox' ? `height: 4.5rem` : ``)};
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.8rem;
  color: inherit;
  letter-spacing: 0.015rem;
  line-height: 1;
  padding: 0 1.5rem;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  &:disabled {
    background: #ffffff;
    cursor: not-allowed;
  }
  &:required:invalid {
    border-color: #c01616;
    background-color: #fff3f3;
  }
  &:required:valid {
    border-color: #2ba834;
    background-color: #f5f8f6;
  }
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}
