import { take, all, takeLatest, call, delay, fork, select, put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { feature, jobs, actions } from './config';
import { TId } from '@app/lib/type';
import { PayloadAction } from '@reduxjs/toolkit';
import { tryJob } from '@app/lib/saga';
import {} from './selectors';
import { normalizeStatement, hashStatement } from './lib/hash-statement';

export const sagas = [forking];

function* forking() {
  yield takeLatest(feature.initPattern, init);
  yield takeLatest(actions.onEntered, calculateHash);
}

function* init() {
  try {
    yield call(tryJob, jobs.loadEnterStatements);
  } catch (err) {
    console.error(err);
  }
}

function* calculateHash({ payload: { id, value } }: { payload: { id: TId, value: string | false | null } }) { 
  try {
    if (value) {
      const hashedId = yield call(hashStatement, normalizeStatement(value), id);
      yield put(actions.onHashed(id, hashedId));
    } else { 
      yield put(actions.onHashed(id, null));
    }
  } catch (err) {
    console.error(err);
  }
}