//import { createAction } from '@reduxjs/toolkit';
import {
  features,
  // createJob
} from '../config';

export const feature = features.home;

export const jobs = {};

export const actions = {
  gotoPupilForm: () => features.pupilForm.routeAction({}),
};
