import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { selectors } from '../config';
import { TId } from '@app/lib/type';
import { TNarrationOrderGrades } from './types';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  entered: selectors.testNarration.getEntered(state, selectors.enterStatements.getEnteredByItem),
  statements: selectors.testNarration.getStatements(state),
  conjunctions: selectors.testNarration.getConjunctions(state),
  order: selectors.testNarration.getOrderGrade(state),
  narrationGrade: selectors.testNarration.getNarrationTotal(state),
  done: selectors.testNarration.getIsDone(state),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onToggleStatement: (testLineId: TId, checked: boolean) =>
    dispatch(
      actions.updateStatementGrading(testLineId, {
        other: checked,
      })
    ),
  onEditedStatement: (testLineId: TId, value: string | null) =>
    dispatch(actions.updateCustomObjective(testLineId, value)),
  onGradeOrder: (testLineId: TId, grading: TNarrationOrderGrades) =>
    dispatch(
      actions.updateOrderGrading(testLineId, {
        points: grading,
      })
    ),
  onEditedConjunction: (testLineId: TId, value: string | null) =>
    dispatch(actions.updateCustomObjective(testLineId, value)),
  onChangeConjunction: (testLineId: TId, frequency: number, checked: boolean) =>
    dispatch(
      actions.updateConjunctionGrading(testLineId, {
        frequency,
        other: checked,
      })
    ),

  onNext: () => dispatch(actions.gotoResultSummary()),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
