import { IRootState } from '@app/state';
import { get } from 'lodash';
import { TState } from './state';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  return {
    getBatchId: (state: IRootState) => getSlice(state).batchId,
    getFormValues: (state: IRootState) => getSlice(state).childInfo ?? {},
    getChildCode: (state: IRootState) => getSlice(state).childInfo?.childCode,
    getInDaycare: (state: IRootState) => getSlice(state).childInfo?.classLevel === 'daycare',
    getIsDone: (state: IRootState): boolean => {
      const ci = getSlice(state).childInfo;
      return !!(
        ci.childCode &&
        ci.examDate &&
        ci.diagnosis &&
        ci.nameOfInstitute &&
        ci.nameOfTeacher &&
        ci.dateOfBirth &&
        ci.gender &&
        ci.nativeLanguage &&
        ci.classLevel &&
        ((ci.diagnosis === 'start' && ci.monthsOfContact) ||
          (ci.diagnosis === 'end' && ci.classDesignation && ci.supportLevel))
      );
    },
  };
};
