import { take, all, takeLatest, call, delay, fork, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { feature, jobs, actions, selectors, testName } from './config';
import { PayloadAction } from '@reduxjs/toolkit';
import { tryJob } from '@app/lib/saga';

export const sagas = [forking];

function* forking() {
  yield takeLatest(feature.initPattern, init);
}

function* init() {
  try {
    const statementIdList = yield select(selectors.enterStatements.getStatementHashedIds);
    yield call(tryJob, jobs.loadTestSettings, { test: testName, statementIdList });
  } catch (err) {
    console.error(err);
  }
}
