import { groupBy, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

type TId = string;

type IItemInitializer<T extends IWithId, TStateSlice, TItem extends T = T> = (state: TStateSlice) => Partial<TItem>;

interface IWithId {
  id: TId;
}

type TIdList = TId[];

interface INumbered extends IWithId {
  number: number;
}

interface IDraft<VALUE_TYPE extends IWithId> {
  draft: VALUE_TYPE | null;
  isEditing: boolean;
  isNew: boolean;
}

export const EmptyIDraft: <VALUE_TYPE extends IWithId>() => IDraft<VALUE_TYPE> = () => ({
  draft: null,
  isEditing: false,
  isNew: false,
});

interface INormalized<VALUE_TYPE extends IWithId> {
  map: Record<TId, VALUE_TYPE>;
  ids: TIdList;
}

interface IGrouped<VALUE_TYPE extends IWithId> extends INormalized<VALUE_TYPE> {
  groups: Record<keyof VALUE_TYPE, Record<TId, TId[]>>;
}

type IWithMultiselection<CONTEXTS extends string, BASE_TYPE extends {}> = BASE_TYPE & {
  //selected: TId[];
  selected: Record<CONTEXTS, TId[]>;
};

export const EmptyIWithMultiselection: <CONTEXTS extends string, BASE_TYPE extends {}>(
  base: BASE_TYPE,
  contexts: CONTEXTS[]
) => IWithMultiselection<CONTEXTS, BASE_TYPE> = (base, contexts) => {
  type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
  type CT = ArrayElement<typeof contexts>;
  return {
    ...base,
    selected: contexts.reduce<Record<CT, TId[]>>((obj, c) => {
      obj[c] = [];
      return obj;
    }, {} as any),
  };
};

interface ITracked<VALUE_TYPE extends IWithId> extends INormalized<VALUE_TYPE> {
  map: Record<TId, VALUE_TYPE>;
  ids: TIdList;
  created: TIdList;
  changed: TIdList;
  removed: TIdList;
}

interface IEvent<VALUE_TYPE extends IWithId = IWithId> {
  id: string;
  topic: string;
  event: 'created' | 'changed' | 'removed' | 'upserted';
  data: VALUE_TYPE;
}

interface IEventTracker<VALUE_TYPE extends IWithId> {
  events: IEvent<VALUE_TYPE>[];
}

interface IEventTracked<VALUE_TYPE extends IWithId> extends INormalized<VALUE_TYPE>, IEventTracker<VALUE_TYPE> {}

export const EmptyINormalized: <T extends IWithId>() => INormalized<T> = () => ({
  map: {},
  ids: [],
});

export const EmptyIGrouped: <T extends IWithId>() => IGrouped<T> = () => ({
  ...EmptyINormalized(),
  groups: Object(),
});

export const naturalSorted = <ITEM_TYPE>(
  arr: ITEM_TYPE[] = [],
  getSortByValue: (item: ITEM_TYPE) => any = (item) => item
) => {
  return arr
    .slice()
    .sort((a, b) =>
      `${getSortByValue(a)}`.localeCompare(getSortByValue(b), navigator.languages[0] || navigator.language, {
        numeric: true,
      })
    );
};

export const buildSparseGroup = <VALUE_TYPE extends IWithId>(
  byKey: keyof VALUE_TYPE,
  grouped: IGrouped<VALUE_TYPE>
) => {
  grouped.groups[byKey] = groupBy(grouped.ids, (id) => grouped.map[id][byKey]);
};

export const buildGroup = <GROUPS_TYPE extends IWithId, VALUE_TYPE extends IWithId>(
  byKey: keyof VALUE_TYPE,
  groups: INormalized<GROUPS_TYPE>,
  grouped: IGrouped<VALUE_TYPE>,
  getSortByValue?: (item: VALUE_TYPE) => any
) => {
  const emptyGroups = groups.ids.reduce<Record<string, string[]>>((obj, id) => {
    obj[id] = [];
    return obj;
  }, {});
  let newGroups: Record<string, string[]> = { ...emptyGroups, ...groupBy(grouped.ids, (id) => grouped.map[id][byKey]) };

  if (getSortByValue) {
    Object.keys(newGroups).forEach((groupKey) => {
      //newGroups[groupKey] = sortBy(newGroups[groupKey], (id) => getSortByValue(grouped.map[id]));
      newGroups[groupKey].sort((a, b) =>
        `${getSortByValue(grouped.map[a])}`.localeCompare(
          getSortByValue(grouped.map[b]),
          navigator.languages[0] || navigator.language,
          { numeric: true }
        )
      );
    });
  }
  grouped.groups[byKey] = newGroups;
};

export const EmptyITracked: <T extends IWithId>() => ITracked<T> = () => ({
  map: {},
  ids: [],
  created: [],
  changed: [],
  removed: [],
});

export const pristineITracked: <T extends IWithId>(key: ITracked<T>) => ITracked<T> = (key) => ({
  ...EmptyITracked(),
  map: key.map,
  ids: key.ids,
});

export const EmptyEvents: <T extends IWithId>() => IEventTracker<T> = () => ({
  events: [],
});

export const EmptyEventTracked: <T extends IWithId>() => IEventTracked<T> = () => ({
  ...EmptyEvents(),
  map: {},
  ids: [],
});

/**
 *  Compiles an event based on topic, event: 'changed'|'created'|'removed' and a deep clone of data.
 */
export const compileEvent = <T extends IWithId>(
  topic: string,
  event: 'changed' | 'created' | 'removed' | 'upserted',
  data: T,
  updater?: (old: T) => Partial<T>
): IEvent<T> => {
  const cloned = cloneDeep(data);
  return {
    id: uuidv4(),
    topic,
    event,
    data: { ...cloned, ...(updater ? updater(cloned) : {}) },
  };
};

/**
 *  Compiles and pushes an event based on topic, event: 'changed'|'created'|'removed' and a deep clone of data.
 *  Returns a new deep clone of the data.
 */
export const pushEvent = <T extends IWithId>(
  queue: IEvent<IWithId>[],
  topic: string,
  event: 'changed' | 'created' | 'removed',
  data: T,
  updater?: (old: T) => Partial<T>
): T => {
  const compiled = compileEvent(topic, event, data, updater);
  queue.push(compiled);
  return cloneDeep(compiled.data);
};

export const compileTrackedForSaving = <T extends IWithId>(topic: string, tracked: ITracked<T>) => {
  const data = {
    changed: tracked.changed.filter(Boolean).map((id) => compileEvent(topic, 'changed', tracked.map[id])),
    created: tracked.created.filter(Boolean).map((id) => compileEvent(topic, 'created', tracked.map[id])),
    removed: tracked.removed.filter(Boolean).map((id) => compileEvent(topic, 'removed', { id })),
  };
  return data;
};

interface INormalizedWithSelection<VALUE_TYPE extends IWithId> {
  map: Record<TId, VALUE_TYPE>;
  ids: TIdList;
  selected: TId | null;
}

export const EmptyINormalizedWithSelection: <T extends IWithId>() => INormalizedWithSelection<T> = () => ({
  map: {},
  ids: [],
  selected: null,
});

export const removeFromNormalized = <T extends IWithId>(item: T, normalized: INormalized<T>) => {
  delete normalized.map[item.id];
  normalized.ids = normalized.ids.filter((id) => id !== item.id);
};

export const removeFromTracked = <T extends IWithId>(item: T, tracked: ITracked<T>) => {
  removeFromNormalized(item, tracked);
  tracked.removed.push(item.id);
};

export type {
  TId,
  TIdList,
  IWithId,
  INumbered,
  IDraft,
  INormalized,
  INormalizedWithSelection,
  ITracked,
  IGrouped,
  IItemInitializer,
  IEventTracker,
  IEvent,
  IEventTracked,
  IWithMultiselection,
};
