import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { Mainmenu as View, MainmenuLayer, MainmenuSwitches } from '@app/ui/mainmenu';
import { actions, selectors } from './config';

const routeToMainmenuSwitch = (route: string): MainmenuSwitches => {
  switch (route) {
    case 'ROUTE_PUPIL-FORM':
      return 'childinfo';
    case 'ROUTE_ENTER-STATEMENTS':
      return 'info';
    case 'ROUTE_TEST-SVO':
      return 'test1';
    case 'ROUTE_TEST-NGP':
      return 'test2';
    case 'ROUTE_TEST-NOMINATIV':
      return 'test3';
    case 'ROUTE_TEST-NARRATION':
      return 'test4';
    case 'ROUTE_RESULTS-SUMMARY':
    case 'ROUTE_RESULTS-PDF':
      return 'summary';
    case 'ROUTE_COMPLETE-EVALUATIONS':
      return 'complete-evaluations';
    case 'ROUTE_INCOMPLETE-EVALUATIONS':
      return 'incomplete-evaluations';
    case 'ROUTE_STATEMENTS-REVIEW':
      return 'expert-review-todo';
  }
  return 'home';
};

export interface ControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, { route }: ControllerProps) => ({
  active: routeToMainmenuSwitch(route),
  disabled: {
    test4: selectors.enterStatements.skipNarration(state),
  },
  hasReviewPermission: selectors.auth.isAuthorizedForRole(state, 'Reviewer'),
});
const mapDispatchToProps = (dispatch: Dispatch, props: ControllerProps) => ({
  onSwitch: (to: MainmenuSwitches, fromLayer: MainmenuLayer, saveLayer: boolean) => {
    switch (to) {
      case 'logout':
        dispatch(actions.logout(fromLayer, saveLayer));
        return;
      case 'home':
        dispatch(actions.gotoHome(fromLayer, saveLayer));
        return;
      case 'enter-evaluation':
      case 'childinfo':
        dispatch(actions.gotoPupilForm(fromLayer, saveLayer));
        return;
      case 'info':
        dispatch(actions.gotoEnterStatements(fromLayer, saveLayer));
        return;
      case 'test1':
        dispatch(actions.gotoTestSVO(fromLayer, saveLayer));
        return;
      case 'test2':
        dispatch(actions.gotoTestNGP(fromLayer, saveLayer));
        return;
      case 'test3':
        dispatch(actions.gotoTestNominativ(fromLayer, saveLayer));
        return;
      case 'test4':
        dispatch(actions.gotoTestNarration(fromLayer, saveLayer));
        return;
      case 'summary':
        dispatch(actions.gotoResultSummary(fromLayer, saveLayer));
        return;
      case 'complete-evaluations':
        dispatch(actions.gotoCompleteEvaluations(fromLayer, saveLayer));
        return;
      case 'incomplete-evaluations':
        dispatch(actions.gotoIncompleteEvaluations(fromLayer, saveLayer));
        return;
      case 'expert-review-todo':
        dispatch(actions.gotoStatementsReview(fromLayer, saveLayer));
        return;
    }
  },
});
export type ControllerComProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ControllerProps;

export const Controller = connect(mapStateToProps, mapDispatchToProps)(View);
