import { createAction } from '@reduxjs/toolkit';
import { features } from '../config';

export type IProfile = {
  email?: string;
  emailVerified?: boolean;
  username?: string;
  firstName?: string;
  lastName?: string;
};

export const feature = features.auth;

export const jobs = {};

export const actions = {
  authSuccess: createAction(
    'AUTH_SUCCESS@KEYCLOAK',
    (accessToken: string, userId: string | null, fullName: string | null, roles: string[], groups: string[]) => ({
      payload: { accessToken, userId, fullName, roles, groups },
    })
  ),
  refreshSuccess: createAction('REFRESH_SUCCESS@KEYCLOAK', (accessToken: string, userId: string | null) => ({
    payload: { accessToken, userId },
  })),
  updateProfile: createAction('UPDATE_PROFILE@KEYCLOAK', (profile: IProfile) => ({ payload: profile })),
  failure: createAction('FAILURE@KEYCLOAK'),
  logout: createAction('LOGOUT@KEYCLOAK'),
};
