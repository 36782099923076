import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { selectors } from '../config';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  pupilcode: selectors.pupilForm.getChildCode(state) as string,
  statements: selectors.enterStatements.getStatements(state),
  done: selectors.enterStatements.getIsDone(state),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onInfos: () => dispatch(actions.gotoPupilForm()),
  onEntered: (id: string, value: string | false | null) => dispatch(actions.onEntered(id, value)),
  onNext: () => dispatch(actions.gotoTestSVO()),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
