import { VFC, ReactNode } from 'react';
//import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { Table, HeaderRow, BodyRow, HeaderCell, BodyCell } from '../../ui/table';
import { ActionIconDownload } from '../../ui/icons';
import { Button } from '../../ui/buttons/button';

export interface ViewProps {
  children?: ReactNode;
}

export const View: VFC<ViewProps> = ({ children }) => {
  return (
    <Page header={<Header title="Abgesendete Sprachstandserfassungen" />}>
      <Section>
        <Table>
          <HeaderRow gridTemplateColumns="1fr 1fr 1fr 1fr">
            <HeaderCell>Angelegt am</HeaderCell>
            <HeaderCell>Kodierung des Kindes</HeaderCell>
            <HeaderCell>Geburtsdatum</HeaderCell>
            <HeaderCell></HeaderCell>
          </HeaderRow>
          <BodyRow gridTemplateColumns="1fr 1fr 1fr 1fr">
            <BodyCell>Inhalt 1</BodyCell>
            <BodyCell>Inhalt 1</BodyCell>
            <BodyCell>Inhalt 1</BodyCell>
            <BodyCell>
              <Button variant="text-small">
                <ActionIconDownload />
              </Button>
            </BodyCell>
          </BodyRow>
        </Table>
      </Section>
    </Page>
  );
};
