import React, { VFC, Fragment, ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { Fieldset } from '../../../ui/form/fieldset';

export const StyledForm = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
  grid-template-rows: 1fr 1fr;
  row-gap: 2rem;
  column-gap: 1.5rem;
`;

export const StyledFormTool = styled(StyledForm)`
  position: relative;
  border: 1px solid #979797;
  border-radius: 15px;
  padding: 2rem 1.5rem;
  margin-bottom: 8rem;
`;

export const TwoColFieldset = styled(Fieldset)`
  grid-column: span 2;
`;
