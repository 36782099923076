//import { Controller as StartPageController } from './start';
import { Controller as PupilFormController } from './pupil-form';
import { Controller as EnterStatementsController } from './enter-statements';
import { Controller as TestSVOController } from './test-svo';
import { Controller as TestNGPController } from './test-ngp';
import { Controller as TestNominativController } from './test-nominativ';
import { Controller as TestNarrationController } from './test-narration';
import { Controller as ResultsSummaryController } from './results-summary';
import { Controller as ResultsPdfController } from './results-pdf';
import { Controller as CompleteEvaluationsController } from './complete-evaluations';
import { Controller as IncompleteEvaluationsController } from './incomplete-evaluations';
import { Controller as HomeController } from './home';
import { Controller as StatementsReviewController } from './statements-review';

const screenMap = {
  //'START-PAGE': StartPageController,
  'PUPIL-FORM': PupilFormController,
  'ENTER-STATEMENTS': EnterStatementsController,
  'TEST-SVO': TestSVOController,
  'TEST-NGP': TestNGPController,
  'TEST-NOMINATIV': TestNominativController,
  'TEST-NARRATION': TestNarrationController,
  'RESULTS-SUMMARY': ResultsSummaryController,
  'RESULTS-PDF': ResultsPdfController,
  HOME: HomeController,
  'COMPLETE-EVALUATIONS': CompleteEvaluationsController,
  'INCOMPLETE-EVALUATIONS': IncompleteEvaluationsController,
  'STATEMENTS-REVIEW': StatementsReviewController,
};

export const screenByFeatureKey = (featureKey: string): React.VFC => screenMap[featureKey];
