import { ReactNode } from 'react';
import item1 from './item-01.jpg';
import item2 from './item-02.jpg';
import item3 from './item-03.jpg';
import item4 from './item-04.jpg';
import item5 from './item-05.jpg';
import item6 from './item-06.jpg';
import item7 from './item-07.jpg';
import item8 from './item-08.jpg';
import item9 from './item-09.jpg';
import item10 from './item-10.jpg';
import item11 from './item-11.jpg';
import item12 from './item-12.jpg';
import item13 from './item-13.jpg';
import item14 from './item-14.jpg';
import item15 from './item-15.jpg';
import narration00 from './narration-00.jpg';

const ItemImages = [
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  item7,
  item8,
  item9,
  item10,
  item11,
  item12,
  item13,
  item14,
  item15,
  narration00,
];

export const getItemImage = (no: number): ReactNode => {
  return <img src={ItemImages[no - 1]} alt="" />;
};
