//import { createAction } from '@reduxjs/toolkit';
import { MainmenuLayer } from '@app/ui/mainmenu';
import {
  features,
  // createJob
} from '../config';
export { selectors } from '../config';

export const feature = null;

export const jobs = {};

export const actions = {
  gotoHome: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.home.routeAction({ fromLayer, saveLayer }),
  logout: (fromLayer: MainmenuLayer, saveLayer: boolean = false) => features.auth.routeAction({ fromLayer, saveLayer }),
  gotoPupilForm: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.pupilForm.routeAction({ fromLayer, saveLayer }),
  gotoEnterStatements: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.enterStatements.routeAction({ fromLayer, saveLayer }),
  gotoTestSVO: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.testSVO.routeAction({ fromLayer, saveLayer }),
  gotoTestNGP: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.testNGP.routeAction({ fromLayer, saveLayer }),
  gotoTestNominativ: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.testNominativ.routeAction({ fromLayer, saveLayer }),
  gotoTestNarration: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.testNarration.routeAction({ fromLayer, saveLayer }),
  gotoResultSummary: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.resultsSummary.routeAction({ fromLayer, saveLayer }),
  gotoCompleteEvaluations: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.completeEvaluations.routeAction({ fromLayer, saveLayer }),
  gotoIncompleteEvaluations: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.incompleteEvaluations.routeAction({ fromLayer, saveLayer }),
  gotoStatementsReview: (fromLayer: MainmenuLayer, saveLayer: boolean = false) =>
    features.statementsReview.routeAction({ fromLayer, saveLayer }),
};
