import { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components/macro';

export const Textarea = styled.textarea`
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 0.5rem;
  max-width: 33rem;
  min-height: 7rem;
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.5rem;
  color: inherit;
  letter-spacing: 0.015rem;
  line-height: 2.2rem;
  padding: 1rem;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  &:disabled {
    background: #ffffff;
    cursor: not-allowed;
  }
  &:focus-visible {
  }
`;

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}
