import React, { VFC, Fragment, ChangeEvent, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { StyledSubtitle } from '../../../ui/layout/header';
import { Fieldset } from '../../../ui/form/fieldset';
import { Input } from '../../../ui/form/input';
import { Select } from '../../../ui/form/select';
import { Button } from '../../../ui/buttons/button';
import { Motivation, Warning } from '../../../ui/typography';
import { getSortedLanguageOptions, getSortedCountryOptions, OptionsList } from '@app/state/types';
import { StyledFormTool, TwoColFieldset } from './styled-form';

const CenteredTwoColFieldset = styled(TwoColFieldset)`
  text-align: center;
`;

const ThreeColStyledSubtitle = styled(StyledSubtitle)`
  grid-column: span 3;
`;

export interface IChildcodeToolProps {
  updateChildCode: (childCode: string, childDateOfBirth: Date) => void;
}

interface IChildcodeToolState {
  childFirstName: string | null;
  motherFirstname: string | null;
  fatherFirstname: string | null;
  childDateOfBirth: Date | null;
  childCountryOfBirth: string | null;
  motherNativeLanguage: string | null;
  childcodeVariant: 1 | 2;
}

const checkValidity = (state: IChildcodeToolState): boolean => {
  const regex = /^[\p{L}]{2,}$/u;
  return (
    regex.test(state.childFirstName ?? '') &&
    !!state.childDateOfBirth &&
    !!state.childCountryOfBirth &&
    ((state.childcodeVariant === 1 && regex.test(state.motherFirstname ?? '') && !!state.motherNativeLanguage) ||
      (state.childcodeVariant === 2 && regex.test(state.fatherFirstname ?? '')))
  );
};

export const ChildcodeTool: VFC<IChildcodeToolProps> = ({ updateChildCode }) => {
  const [langOptions] = useState<OptionsList[]>(getSortedLanguageOptions());
  const [countryOptions] = useState<OptionsList[]>(getSortedCountryOptions());
  const [state, setState] = useState<IChildcodeToolState>(
    (): IChildcodeToolState => ({
      childFirstName: null,
      motherFirstname: null,
      fatherFirstname: null,
      childDateOfBirth: null,
      childCountryOfBirth: null,
      motherNativeLanguage: null,
      childcodeVariant: 1,
    })
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  useEffect(() => {
    setIsValid(checkValidity(state));
  }, [state]);
  const [childCode, setChildCode] = useState<string | null>(null);
  useEffect(() => {
    if (checkValidity(state)) {
      let builtCode = state.childFirstName?.substring(0, 2) ?? '';

      if (state.childcodeVariant === 1) {
        builtCode += state.motherFirstname?.substring(0, 2);
        builtCode += state.childDateOfBirth?.toISOString().substring(2, 4);
        builtCode += state.childCountryOfBirth;
        builtCode += state.motherNativeLanguage;
      } else {
        builtCode += state.fatherFirstname?.substring(0, 2);
        builtCode += state.childDateOfBirth?.toISOString().substring(5, 7).replace('0', '');
        builtCode += state.childDateOfBirth?.toISOString().substring(2, 4);
        builtCode += state.childCountryOfBirth;
      }

      setChildCode(builtCode);
    } else {
      setChildCode(null);
    }
  }, [state]);
  const [maxBirth] = useState<string>(
    new Date(new Date().getFullYear() - 3, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0]
  );
  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const property = evt.currentTarget.name;
      let value: any = null;
      if (!!evt.currentTarget.value) {
        const type = evt.currentTarget.getAttribute('data-type') || evt.currentTarget.type;
        switch (type) {
          case 'number':
            value = Number(evt.currentTarget.value);
            if (isNaN(value)) return;
            break;
          case 'date':
            value = new Date(evt.currentTarget.value);
            break;
          default:
            value = `${evt.currentTarget.value}`;
        }
      }

      setState((s) => ({
        ...s,
        [property]: value,
      }));
    },
    [setState]
  );
  const onSubmitCode = useCallback(() => {
    !!childCode && !!state.childDateOfBirth && isValid && updateChildCode(childCode, state.childDateOfBirth);
  }, [childCode, state]);

  return (
    <StyledFormTool>
      <ThreeColStyledSubtitle>Optional: Hilfe bei der Codierung des Kindes</ThreeColStyledSubtitle>
      <Fieldset label="Vorname des Kindes">
        <Input
          required
          pattern="\p{Letter}{2}.*"
          name="childFirstName"
          value={state.childFirstName ?? ''}
          onChange={onChange}
        />
      </Fieldset>
      <Fieldset label="Geburtsdatum des Kindes">
        <Input
          name="childDateOfBirth"
          required
          type="date"
          max={maxBirth}
          value={state.childDateOfBirth?.toISOString().substring(0, 10) ?? ''}
          onChange={onChange}
        />
      </Fieldset>
      <Fieldset label="Geburtsland des Kindes">
        <Select name="childCountryOfBirth" required value={state.childCountryOfBirth ?? ''} onChange={onChange}>
          <option value={undefined}></option>
          {countryOptions.map((lo: OptionsList) => (
            <option key={lo.value} value={lo.value}>
              {lo.label}
            </option>
          ))}
        </Select>
      </Fieldset>
      <Fieldset label="Vorname/Herkunftssprache der Mutter">
        <Select
          required
          name="childcodeVariant"
          value={state.childcodeVariant ?? ''}
          onChange={onChange}
          data-type="number"
        >
          <option value={1}>Bekannt</option>
          <option value={2}>Unbekannt</option>
        </Select>
      </Fieldset>
      {state.childcodeVariant === 1 && (
        <React.Fragment>
          <Fieldset label="Vorname der Mutter">
            <Input
              required={state.childcodeVariant === 1}
              pattern="\p{Letter}{2}.*"
              name="motherFirstname"
              value={state.motherFirstname ?? ''}
              onChange={onChange}
            />
          </Fieldset>
          <Fieldset label="Herkunftssprache der Mutter">
            <Select
              required={state.childcodeVariant === 1}
              name="motherNativeLanguage"
              value={state.motherNativeLanguage ?? ''}
              onChange={onChange}
            >
              <option value={undefined}></option>
              {langOptions.map((lo: OptionsList) => (
                <option key={lo.value} value={lo.value}>
                  {lo.label}
                </option>
              ))}
            </Select>
          </Fieldset>
        </React.Fragment>
      )}
      {state.childcodeVariant === 2 && (
        <TwoColFieldset label="Vorname des Vaters">
          <Input
            required
            pattern="\p{Letter}{2}.*"
            name="fatherFirstname"
            value={state.fatherFirstname ?? ''}
            onChange={onChange}
          />
        </TwoColFieldset>
      )}

      <Fieldset>
        <Button disabled={!isValid} variant="secondary" onClick={onSubmitCode}>
          Codierung übernehmen
        </Button>
      </Fieldset>

      <CenteredTwoColFieldset hint="Die Codierung wird lokal auf Ihrem Computer erzeugt und in das Formularfeld 'Codierung des Kindes' eingetragen sobald Sie den 'Codierung übernehmen' Button drücken. Die Codierungshilfe blendet sich automatisch aus sobald das Formularfeld 'Codierung des Kindes' einen Inhalt aufweist. Sie können natürlich auch direkt das Formularfeld oben links nutzen.">
        {!isValid ? <Warning>Bitte füllen Sie alles aus!</Warning> : <Motivation>{childCode}</Motivation>}
      </CenteredTwoColFieldset>
    </StyledFormTool>
  );
};
