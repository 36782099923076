import { compileEvent, TId } from '@app/lib/type';
import { take, all, takeLatest, takeLeading, call, put, fork, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { feature, jobs, actions, selectors, testName } from './config';
import { IReviewLine, ITestItem } from '@app/state';
import { PayloadAction } from '@reduxjs/toolkit';
import { tryJob } from '@app/lib/saga';

export const sagas = [forking];

function* forking() {
  yield takeLatest(feature.initPattern, init);
  yield takeLatest(actions.selectItem, loadStatements);
  yield takeLatest(actions.selectStatement, loadReviewLines);
  yield takeLeading(actions.confirmReview, sendToFairchance);
}

function* init(action) {
  try {
    yield call(tryJob, jobs.loadReviewStatements);
  } catch (err) {
    console.error(err);
  }
}

function* loadStatements({ payload: { testItemId } }: { payload: { testItemId: TId } }) {
  try {
    yield call(tryJob, jobs.loadReviewStatements, { reviewStatementsByTestItemId: testItemId });
  } catch (err) {
    console.error(err);
  }
}

function* loadReviewLines({ payload: { statementId } }: { payload: { statementId: TId } }) {
  try {
    yield call(tryJob, jobs.loadReviewLines, { statementId });
  } catch (err) {
    console.error(err);
  }
}

function* sendToFairchance() {
  // Gather stuff
  const sentBy = yield select(selectors.auth.getUserId);

  const reviewLines: IReviewLine[] = yield select(selectors.statementsReview.getTouchedReviewLinesForSending);

  const eventsToSend = [
    ...reviewLines.map((r: IReviewLine) => compileEvent('reviewLine', 'upserted', { ...r, reviewer: sentBy })),
  ];

  try {
    yield call(tryJob, jobs.saveReviewLines, eventsToSend);

    const testItem: ITestItem = yield select(selectors.statementsReview.getSelectedTestItem);
    if (testItem) {
      yield call(tryJob, jobs.loadReviewStatements, { reviewStatementsByTestItemId: testItem.id });
    }
  } catch (err) {
    console.error(err);
  }
}
