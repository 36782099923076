import { VFC, ReactNode, memo } from 'react';
import { Card, CardHeader, CardHeaderContent } from '../../../ui/layout/card';
import { DefaultCardImage } from '../../../ui/images';
import { Fieldset } from '../../../ui/form/fieldset';
import { Textarea } from '../../../ui/form/textarea';
import { NarrationCardProps, NoStatementCheckbox } from './narration-card';
import styled from 'styled-components/macro';

const MemoTextArea = memo(Textarea);

export interface StatementCardProps extends NarrationCardProps {
  label: string;
  text: string;
  image?: ReactNode;
  entered: string | false | null;
  onEntered: (value: string | false | null) => void;
}

export const StatementCard: VFC<StatementCardProps> = ({ label, text, image, entered, onEntered }) => {
  return (
    <Card variant={entered === false || entered ? 'success' : 'default'}>
      <CardHeader img={image ?? <DefaultCardImage />}>
        <CardHeaderContent variant="compact" label={label}>
          <div dangerouslySetInnerHTML={text ? { __html: text } : undefined} />
        </CardHeaderContent>
      </CardHeader>
      <Fieldset
        label="Äußerung des Kindes"
        hint={
          <NoStatementCheckbox label="Das Kind hat keine Äußerung gemacht." entered={entered} onEntered={onEntered} />
        }
      >
        <MemoTextArea
          value={entered || ''}
          onInput={(e) => onEntered(e.currentTarget.value === '' ? null : e.currentTarget.value)}
        />
      </Fieldset>
    </Card>
  );
};

export const StatementCardMemo = memo(StatementCard);
