import { genericTestLineIsDone, IReviewStatement, IReviewLine, IRootState, ITestItem } from '@app/state';
import { get } from 'lodash';
import { TState } from './state';
import { IReviewStatementSelection, IReviewStatementCard, ITestItemSelection, IReviewLineForGrading } from './types';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  return {
    getReviewLinesDone: (state: IRootState): boolean => {
      const slice = getSlice(state);
      return slice.reviewLines.ids
        .map((itemId) => slice.reviewLines.map[itemId])
        .every((rL) =>
          genericTestLineIsDone(
            rL,
            slice.testSection.map[slice.testLine.map[rL.testLine].testSection].test === 'Satzbau & Wortschatz'
          )
        );
    },
    getReviewLinesForGrading: (state: IRootState): IReviewLineForGrading[] => {
      const slice = getSlice(state);
      return slice.reviewLines.ids.map((itemId) => {
        const rl = slice.reviewLines.map[itemId];
        return {
          ...rl,
          test: slice.testSection.map[slice.testLine.map[rl.testLine].testSection].test,
          status: slice.lineStatus[rl.id],
        };
      });
    },
    getTouchedReviewLinesForSending: (state: IRootState): IReviewLine[] => {
      const slice = getSlice(state);
      return slice.reviewLines.ids
        .filter((itemId) => slice.reviewLinesTouched[itemId])
        .map((itemId) => slice.reviewLines.map[itemId]);
    },
    getSelectedTestItem: (state: IRootState): ITestItem | null => {
      const slice = getSlice(state);
      return slice.testItem.selected ? slice.testItem.map[slice.testItem.selected] : null;
    },
    getTestItems: (state: IRootState): ITestItemSelection => {
      const slice = getSlice(state);
      return {
        items: slice.testItem.ids.map((itemId) => {
          return slice.testItem.map[itemId];
        }),
        selected: slice.testItem.selected ? slice.testItem.map[slice.testItem.selected] : null,
      };
    },
    getStatements: (state: IRootState): IReviewStatementSelection => {
      const slice = getSlice(state);
      const makeStatementCard = (reviewStatement: IReviewStatement): IReviewStatementCard => {
        const item = slice.testItem.map[reviewStatement.item];
        return {
          id: reviewStatement.id,
          normalized: reviewStatement.normalized,
          reference: item.statement,
          itemNumber: item.itemNumber,
          done: reviewStatement.reviewLineCount > 0,
          duplicates: reviewStatement.duplicates,
        };
      };
      return {
        statements: slice.reviewStatement.ids.map((reviewStatementId) => {
          const reviewStatement = slice.reviewStatement.map[reviewStatementId];

          return makeStatementCard(reviewStatement);
        }),
        selected: slice.reviewStatement.selected
          ? makeStatementCard(slice.reviewStatement.map[slice.reviewStatement.selected])
          : null,
      };
    },
    /*
    getIsDone: (state: IRootState) => {
      const slice = getSlice(state);
      return (
        !!slice.featureInitialized &&
        Object.entries(slice.lineResult).length === slice.testLine.ids.length &&
        Object.values(slice.lineResult).every((line) => genericTestLineIsDone(line))
      );
    },*/
  };
};
