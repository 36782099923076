import { VFC, memo, useCallback, Fragment } from 'react';
import styled from 'styled-components/macro';
import { NormalizedButton } from '../buttons/button';

import { ReactComponent as IconChildInfo } from './icon_menu_childinfo.svg';
import { ReactComponent as IconInfo } from './icon_menu_info.svg';
import { ReactComponent as IconLogin } from './icon_menu_login.svg';
import { ReactComponent as IconLogout } from './icon_logout.svg';
import { ReactComponent as IconSummary } from './icon_menu_summary.svg';
import { ReactComponent as Icon1 } from './icon_menu_1.svg';
import { ReactComponent as Icon2 } from './icon_menu_2.svg';
import { ReactComponent as Icon3 } from './icon_menu_3.svg';
import { ReactComponent as Icon4 } from './icon_menu_4.svg';
import { ReactComponent as IconEnterEvaluation } from './icon_neue_bewertung.svg';
import { ReactComponent as IconExit } from './icon_speichern_verlassen.svg';
import { ReactComponent as IconIncompleteEvaluations } from './icon_unvollstaendige_bewertungen.svg';
import { ReactComponent as IconCompletedEvaluations } from './icon_abgeschlossene_bewertungen.svg';
import { ReactComponent as IconHome } from './icon_home.svg';
import { ReactComponent as IconReviewDone } from './icon_review-done.svg';
import { ReactComponent as IconReviewPostponed } from './icon_review-postponed.svg';
import { ReactComponent as IconReviewTodo } from './icon_review-todo.svg';


const StyledMenu = styled.nav`
  position: fixed;
  top: 6rem;
  left: 0;
  width: 7.5rem;
`;

const StyledMenuButton = styled(NormalizedButton).attrs<{ active: boolean }, { active: boolean }>(({ active }) => ({
  active: active ?? false,
}))`
  background: ${(props) => (props.active ? `#F8B477` : `#e2e2e2`)};
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 1.25;
  text-align: center;
  line-height: 1.6;
  padding: 0 1rem;
  border-radius: 0 999rem 999rem 0;
  margin-bottom: 2rem;
  height: ${(props) => (props.active ? `7.5rem` : `5rem`)};
  width: ${(props) => (props.active ? `7.5rem` : `5rem`)};
  cursor: ${({ active }) => (active ? `auto` : `pointer`)};
  transform: translateX(-0.25rem);
  & > svg {
    max-width: 100%;
    max-height: 100%;
  }
  :hover {
    transform: translateX(0);
  }
`;

export type MainmenuSwitches =
  | 'logout'
  | 'home'
  | 'childinfo'
  | 'info'
  | 'test1'
  | 'test2'
  | 'test3'
  | 'test4'
  | 'summary'
  | 'enter-evaluation'
  | 'complete-evaluations'
  | 'incomplete-evaluations'
  | 'expert-review-todo'
  | 'expert-review-postponed'
  | 'expert-review-done'
  | 'expert-review-enter';

export type MainmenuLayer = 'desktop' | 'evaluation' | 'expert-review';

const switchToLayerMap: Record<MainmenuSwitches, MainmenuLayer> = {
  logout: 'desktop',
  home: 'desktop',
  childinfo: 'evaluation',
  info: 'evaluation',
  test1: 'evaluation',
  test2: 'evaluation',
  test3: 'evaluation',
  test4: 'evaluation',
  summary: 'evaluation',
  'enter-evaluation': 'desktop',
  'complete-evaluations': 'desktop',
  'incomplete-evaluations': 'desktop',
  'expert-review-todo': 'expert-review',
  'expert-review-postponed': 'expert-review',
  'expert-review-done': 'expert-review',
  'expert-review-enter': 'expert-review'
};

export interface MainmenuProps {
  active: MainmenuSwitches;
  onSwitch: (to: MainmenuSwitches, fromLayer: MainmenuLayer, saveLayer: boolean) => void;
  disabled: Partial<Record<MainmenuSwitches, boolean>>;
  hasReviewPermission: boolean;
}

export const Mainmenu: VFC<MainmenuProps> = ({ active, onSwitch, disabled, hasReviewPermission }) => {
  const memoSwitchHandler = useCallback(
    (to: MainmenuSwitches, fromLayer: MainmenuLayer, saveLayer: boolean = false) => () => {
      onSwitch(to, fromLayer, saveLayer);
    },
    [onSwitch]
  );

  const layer = switchToLayerMap[active] || 'desktop';

  return (
    <StyledMenu>
      {layer === 'desktop' && (
        <Fragment>
          <StyledMenuButton active={active === 'logout'} onClick={memoSwitchHandler('logout', 'desktop')} title="Logout">
            <IconLogout />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'home'}
            disabled={disabled['home']}
            onClick={memoSwitchHandler('home', 'desktop')}
            title="Home"
          >
            <IconHome />
          </StyledMenuButton>
          {/*<StyledMenuButton
            active={active === 'enter-evaluation'}
            disabled={disabled['enter-evaluation']}
            onClick={memoSwitchHandler('enter-evaluation', 'desktop')}
            title="Bewertung erfassen">
            <IconEnterEvaluation />
          </StyledMenuButton>*/}
          <StyledMenuButton
            active={active === 'incomplete-evaluations'}
            disabled={disabled['incomplete-evaluations']}
            onClick={memoSwitchHandler('incomplete-evaluations', 'desktop')}
            title="Zwischengespeicherte Sprachstandserfassungen"
          >
            <IconIncompleteEvaluations />
          </StyledMenuButton>
          {/*<StyledMenuButton
            active={active === 'complete-evaluations'}
            disabled={disabled['complete-evaluations']}
            onClick={memoSwitchHandler('complete-evaluations', 'desktop')}
            title="Abgesendete Sprachstandserfassungen"
          >
            <IconCompletedEvaluations />
          </StyledMenuButton>*/}
          {hasReviewPermission &&
            <StyledMenuButton
              active={active === 'expert-review-todo'}
              disabled={disabled['expert-review-todo']}
              onClick={memoSwitchHandler('expert-review-todo', 'expert-review')}
              title="Unbewertete Aussagen"
            >
              <IconReviewTodo />
            </StyledMenuButton>
          }
        </Fragment>
      )}
      {layer === 'evaluation' && (
        <Fragment>
          <StyledMenuButton active={active === 'home'} onClick={memoSwitchHandler('home', 'evaluation', true)} title="Home">
            <IconExit />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'childinfo'}
            disabled={disabled['childinfo']}
            onClick={memoSwitchHandler('childinfo', 'evaluation')}
            title="Infos zum Kind"
          >
            <IconChildInfo />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'info'}
            disabled={disabled['info']}
            onClick={memoSwitchHandler('info', 'evaluation')}
            title="Sätze eingeben"
          >
            <IconInfo />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'test1'}
            disabled={disabled['test1']}
            onClick={memoSwitchHandler('test1', 'evaluation')}
            title="Satzbau & Wortschatz"
          >
            <Icon1 />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'test2'}
            disabled={disabled['test2']}
            onClick={memoSwitchHandler('test2', 'evaluation')}
            title="Genus: NGP I"
          >
            <Icon2 />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'test3'}
            disabled={disabled['test3']}
            onClick={memoSwitchHandler('test3', 'evaluation')}
            title="Genus: Nominativ I"
          >
            <Icon3 />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'test4'}
            disabled={disabled['test4']}
            onClick={memoSwitchHandler('test4', 'evaluation')}
            title="Erzählen"
          >
            <Icon4 />
          </StyledMenuButton>
          <StyledMenuButton
            disabled={disabled['summary']}
            active={active === 'summary'}
            onClick={memoSwitchHandler('summary', 'evaluation')}
            title="Übersicht & Absenden"
          >
            <IconSummary />
          </StyledMenuButton>
        </Fragment>
      )}
      {layer === 'expert-review' && (
        <Fragment>
          <StyledMenuButton active={active === 'home'} onClick={memoSwitchHandler('home', 'expert-review', true)} title="Home">
            <IconExit />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'expert-review-todo'}
            disabled={disabled['expert-review-todo']}
            onClick={memoSwitchHandler('expert-review-todo', 'expert-review')}
            title="Unbewertete Aussagen"
          >
            <IconReviewTodo />
          </StyledMenuButton>
          {/*
          <StyledMenuButton
            active={active === 'expert-review-postponed'}
            disabled={disabled['expert-review-postponed']}
            onClick={memoSwitchHandler('expert-review-postponed', 'expert-review')}
            title="Sätze eingeben"
          >
            <IconReviewPostponed />
          </StyledMenuButton>
          <StyledMenuButton
            active={active === 'expert-review-done'}
            disabled={disabled['expert-review-done']}
            onClick={memoSwitchHandler('expert-review-done', 'expert-review')}
            title="Satzbau & Wortschatz"
          >
            <IconReviewDone />
          </StyledMenuButton>*/
          }
        </Fragment>
      )}
    </StyledMenu>
  );
};

export const MainmenuMemo = memo(Mainmenu);
