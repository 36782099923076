import { TId, IWithId } from '@app/lib/type';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TClassLevel, TSupportColor, TGender, TDiagnosis } from './constants';

export type TItemTypeSpecifier = 'statement' | 'narration';
export type TTestSpecifier = 'Satzbau & Wortschatz' | 'Genus: NGP I' | 'Genus: Nominativ I' | 'Erzählen';
export type TTestSectionSpecifier =
  | 'Inhalte'
  | 'Konjunktionen'
  | 'Reihenfolge'
  | 'S-HILFSVERB-O-VOLLVERB'
  | 'S-V-O-PARTIKEL'
  | 'S-V-O'
  | 'Artikel';

export interface IChildFormData {
  childCode: null | string;
  nativeLanguage: null | string;
  nativeLanguage2: null | string;
  dateOfBirth: Date | null;
  gender: null | TGender;
  monthsOfContact: null | number;
  diagnosis: null | TDiagnosis;
  examDate: Date | null;
  supportHoursPerWeek: null | number;
  supportHoursMissed: null | number;
  supportLevel: null | TSupportColor;
  supportGame: null | string;
  nameOfInstitute: null | string;
  classLevel: null | TClassLevel;
  classDesignation: null | string;
  nameOfTeacher: null | string;
}

export const initChildFormData = (): IChildFormData => ({
  childCode: null,
  nativeLanguage: null,
  nativeLanguage2: null,
  dateOfBirth: null,
  gender: null,
  monthsOfContact: null,
  diagnosis: null,
  examDate: null,
  supportHoursPerWeek: null,
  supportHoursMissed: null,
  supportLevel: null,
  supportGame: null,
  nameOfInstitute: null,
  classLevel: null,
  classDesignation: null,
  nameOfTeacher: null,
});

export interface IChildInformation extends IChildFormData, IWithId {}

export const initChildInformation = (id = uuidv4()): IChildInformation => ({
  id,
  childCode: null,
  nativeLanguage: null,
  nativeLanguage2: null,
  dateOfBirth: null,
  gender: null,
  monthsOfContact: null,
  diagnosis: null,
  examDate: null,
  supportHoursPerWeek: null,
  supportHoursMissed: null,
  supportLevel: null,
  supportGame: null,
  nameOfInstitute: null,
  classLevel: null,
  classDesignation: null,
  nameOfTeacher: null,
});

export interface IStatements extends IWithId {
  id: string;
  type: TItemTypeSpecifier;
  label: string;
  text?: string;
  image?: ReactNode;
  entered: string | false | null;
}

export interface IReviewStatement extends IWithId {
  item: string;
  normalized: string;
  duplicates: number;
  reviewLineCount: number;
}

export interface ITestItem extends IWithId {
  itemNumber: number;
  statement: string;
  itemType: TItemTypeSpecifier;
}

export interface ITestImage extends IWithId {
  imageNumber: number;
}

export interface ITestSection extends IWithId {
  test: TTestSpecifier;
  section: TTestSectionSpecifier;
  sectionNumber: number;
  inTestResult: boolean;
}

export interface ITestLine extends IWithId {
  testSection: TId;
  lineNumber: number;
  testItem: TId;
  testImage: TId | null;
  testImageAlt: TId | null;
  objective: string | null;
  custom: boolean;
}

export interface ILineResult extends IWithId {
  // Correct is defined here as 'when to signal the user correctness'. This can already
  // happen when only partially correct results are evaluated.
  correct: boolean;

  points: 0 | 1 | 2;

  femaleArticleR: boolean;
  femaleArticleW: boolean;
  maleArticleR: boolean;
  maleArticleW: boolean;
  maleIndefinitArticleR: boolean;
  maleIndefinitArticleW: boolean;
  neuterArticleR: boolean;
  neuterArticleW: boolean;

  other: boolean;
}

export const initLineResult = (id: TId): ILineResult => ({
  id,
  points: 0,
  femaleArticleR: false,
  femaleArticleW: false,
  maleArticleR: false,
  maleArticleW: false,
  maleIndefinitArticleR: false,
  maleIndefinitArticleW: false,
  neuterArticleR: false,
  neuterArticleW: false,
  other: false,
  correct: false,
});

export interface IGradingProposal extends ILineResult { 
  test: string;
  statement: TId;
  proposedBy: 'expert' | 'history' | 'ai';
}

export const initGradingProposal = (
  test: string,
  statement: TId,
  proposedBy: 'expert' | 'history' | 'ai',
  id = uuidv4()
): IGradingProposal => ({
  ...initLineResult(id),
  test,
  statement,
  proposedBy
});

export interface IReviewLine extends ILineResult {
  statement: TId;
  testLine: TId;
  reviewer: TId | null;
}

export const initReviewLineResult = (
  testLine: TId,
  statement: TId,
  reviewer: TId | null,
  id = uuidv4()
): IReviewLine => ({
  ...initLineResult(id),
  statement,
  testLine,
  reviewer,
});

export const upsertReviewLineResult = (
  testLine: TId,
  statement: TId,
  reviewer: TId | null,
  previous: IReviewLine = initReviewLineResult(testLine, statement, reviewer),
  changeData: Partial<IReviewLine> = {}
) => {
  return { ...previous, ...changeData };
};

export interface ITestLineResult extends ILineResult {
  frequency: number;
  customObjective: null | string;
}

export const initTestLineResult = (testLineId: TId): ITestLineResult => ({
  ...initLineResult(testLineId),
  frequency: 1,
  customObjective: null,
});

export const upsertTestLineResult = (
  testLineId: TId,
  previous: ITestLineResult = initTestLineResult(testLineId),
  changeData: Partial<ITestLineResult> = {}
) => {
  return { ...previous, ...changeData };
};

export const genericTestLineIsDone = (line: ILineResult, isSvoTest = false): boolean => {
  return (
    isSvoTest ||
    line.correct ||
    line.femaleArticleR ||
    line.femaleArticleW ||
    line.maleArticleR ||
    line.maleArticleW ||
    line.maleIndefinitArticleR ||
    line.maleIndefinitArticleW ||
    line.neuterArticleR ||
    line.neuterArticleW ||
    line.other
  );
};

export interface ITestLineMask extends IWithId {
  femaleArticleR: false;
  femaleArticleW: false;
  maleArticleR: false;
  maleArticleW: false;
  maleIndefinitArticleR: false;
  maleIndefinitArticleW: false;
  neuterArticleR: false;
  neuterArticleW: false;
  other: false;
}

const svoCorrectness = (line: ILineResult): boolean => {
  return line.points === 1 || line.points === 2;
};

const genericCorrectnessByMask = (line: ILineResult, mask: ITestLineMask): boolean => {
  return (
    (line.femaleArticleR && mask.femaleArticleR) ||
    (line.femaleArticleW && mask.femaleArticleW) ||
    (line.maleArticleR && mask.maleArticleR) ||
    (line.maleArticleW && mask.maleArticleW) ||
    (line.maleIndefinitArticleR && mask.maleIndefinitArticleR) ||
    (line.maleIndefinitArticleW && mask.maleIndefinitArticleW) ||
    (line.neuterArticleR && mask.neuterArticleR) ||
    (line.neuterArticleW && mask.neuterArticleW) ||
    (line.other && mask.other)
  );
};

export const evaluateTestLineSvo = <ITestLineType extends ILineResult>(line: ITestLineType): ITestLineType => {
  return { ...line, correct: svoCorrectness(line) };
};

export const evaluateTestLineNGP = <ITestLineType extends ILineResult>(
  line: ITestLineType,
  mask: ITestLineMask
): ITestLineType => {
  return { ...line, correct: genericCorrectnessByMask(line, mask) };
};

export const evaluateTestLineNominativ = <ITestLineType extends ILineResult>(
  line: ITestLineType,
  mask: ITestLineMask
): ITestLineType => {
  return { ...line, correct: genericCorrectnessByMask(line, mask) };
};
