import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions, selectors } from './config';
import { TId } from '@app/lib/type';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  evaluations: selectors.incompleteEvaluations.getEvaluations(state),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onLoadData: (id: TId) => dispatch(actions.loadData(id)),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
