import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { HelpButton } from '../../ui/help/help-button';
import { EvaluationLegend } from '../../ui/help/evaluation-legend';
import { ResultCard } from './partials/result-card';
import { CardsGrid } from '../../ui/layout/grid';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon } from '../../ui/icons';
import { Motivation, Warning } from '../../ui/typography';
import { ResultCircle } from '../../ui/layout/result-circle';
import { ITestNGPStatement, ITestNGPStatementGrading } from './types';
import { TId } from '@app/lib/type';

const StyledTestPage = styled(Page)`
  .ngp {
    font-weight: bold;
  }
`;

export interface ViewProps {
  done: boolean;
  ngpAcquired: boolean;
  ngpCorrectAnswers: number;
  onNext: () => void;
  onGrade: (statementId: TId, update: Partial<ITestNGPStatementGrading>) => void;
  onRegrade: (statementId: string) => void;
  statements: ITestNGPStatement[];
}

export const View: VFC<ViewProps> = ({
  statements,
  done,
  ngpAcquired,
  ngpCorrectAnswers,
  onNext,
  onGrade,
  onRegrade,
}) => {
  return (
    <StyledTestPage
      header={
        <Header
          title="Genus: NGP I"
          left={<EvaluationLegend />}
          right={<HelpButton title="Kriterien">Für den Auswertungsbogen 2 - Genus: NGP I</HelpButton>}
        />
      }
    >
      <Section>
        <CardsGrid>
          {statements.map((s) => {
            return (
              <ResultCard
                {...s}
                key={s.id}
                onGrade={(update) => onGrade(s.id, update)}
                onRegrade={() => onRegrade(s.id)}
              />
            );
          })}
        </CardsGrid>
      </Section>
      <Section variant="done">
        {done && (
          <ResultCircle
            title="NGP erworben?"
            grade={ngpAcquired ? 'JA' : 'NEIN'}
            info={
              <span>
                Richtige Antworten:
                <br />
                {ngpCorrectAnswers} von {statements.length}
              </span>
            }
          />
        )}
        {done ? (
          <Motivation>Halbzeit – der zweite Test ist geschafft!</Motivation>
        ) : (
          <Warning>Bitte füllen Sie alles aus!</Warning>
        )}
        <Button variant="primary" onClick={onNext} disabled={!done}>
          <ButtonNextIcon />
          WEITER
        </Button>
      </Section>
    </StyledTestPage>
  );
};
