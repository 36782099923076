import { actionChannel, take, put } from 'redux-saga/effects';
import { actions as routingActions } from '@app/features/routing/routes';
//import { isLoggedIn } from '../../selectors/account';

export function* redirector() {
  const queue = yield actionChannel([routingActions.notFound.type]);
  while (true) {
    const { type } = yield take(queue);

    // Close menu before redirecting
    // yield put(ActionCreators.onSwitchMenu(false));

    //const loggedIn = yield select(isLoggedIn);
    switch (type) {
      case routingActions.notFound.type:
        yield put(routingActions.start());
        break;

      default:
    }
  }
}
