import { VFC, ReactNode } from 'react';
import styled from 'styled-components/macro';

export const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 4rem;
  grid-column-gap: 4rem;
  align-items: start;
`;

export interface GridProps {
  className?: string;
  children?: ReactNode;
}

export const Grid: VFC<GridProps> = StyledGrid;

export interface CardsGridProps extends GridProps {
  variant?: 'mini' | 'compact' | 'default';
  stretchItems?: boolean;
  heightenItems?: boolean;
}

export const CardsGrid: VFC<CardsGridProps> = styled(Grid)<CardsGridProps>`
  ${({ variant = 'default' }) =>
    variant === 'default' && `grid-template-columns: repeat(auto-fill, minmax(47.1rem, 3fr));`}
  ${({ variant = 'default' }) =>
    variant === 'compact' && `grid-template-columns: repeat(auto-fill, minmax(30rem, 3fr));`}
  ${({ variant = 'default' }) => variant === 'mini' && `grid-template-columns: repeat(auto-fill, minmax(20rem, 3fr));`}
  ${({ stretchItems = false }) =>
    stretchItems &&
    `
    align-items: stretch;
    & > * {
      width: 100%;
    }
  `}
  ${({ heightenItems = false }) =>
    heightenItems &&
    `
    justify-item: stretch;
    & > * {
      height: 100%;
    }
  `}
`;
