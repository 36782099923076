import { AnyAction, createAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

const apiJobtype = 'api-job';
const apiJobIndicatorRun = 'run';

export const apiJobRunPattern = (action) =>
  action.meta?.jobType === apiJobtype && action.meta?.jobIndicator === apiJobIndicatorRun;

export const apiJobConfig = (jobKey, method, url, transform = (r: any) => r) => (jobId = v4()) => {
  const pattern = (action) => action.meta?.jobKey === jobKey && action.meta?.jobId === jobId;
  const responseTransform = method === 'get' ? transform : (r: any) => r;
  const requestTransform = method !== 'get' ? transform : (r: any) => r;
  return {
    jobKey: jobKey,
    jobId: jobId,
    pattern: pattern,
    runPattern: (action): action is AnyAction => pattern(action) && action.meta?.jobIndicator === apiJobIndicatorRun,
    runAction: createAction(`API_JOB_${jobId}`, (data = {}) => {
      return {
        payload: { data: requestTransform(data) },
        meta: {
          jobType: apiJobtype,
          jobIndicator: apiJobIndicatorRun,
          jobKey,
          jobId,
          jobParams: {
            method,
            url,
          },
        },
      };
    }),
    successPattern: (action): action is AnyAction => pattern(action) && action.meta?.jobIndicator === 'success',
    successAction: createAction(`API_SUCCESS_${jobId}`, (data) => ({
      payload: { data: responseTransform(data) },
      meta: { jobType: apiJobtype, jobIndicator: 'success', jobKey, jobId },
    })),
    successActionType: `API_SUCCESS_${jobId}`,
    failedPattern: (action): action is AnyAction => pattern(action) && action.meta?.jobIndicator === 'failed',
    failedAction: createAction(`API_FAILED_${jobId}`, (message, request, status, data, headers) => ({
      payload: undefined,
      error: { source: 'api', message, request, status, data, headers },
      meta: { jobType: apiJobtype, jobIndicator: 'failed', jobKey, jobId },
    })),
    failedActionType: `API_FAILED_${jobId}`,
    finallyPattern: (action): action is AnyAction => pattern(action) && action.meta?.jobIndicator === 'finally',
    finallyAction: createAction(`API_FINALLY_${jobId}`, () => ({
      payload: undefined,
      meta: { jobType: apiJobtype, jobIndicator: 'finally', jobKey, jobId },
    })),
    finallyActionType: `API_FINALLY_${jobId}`,
  };
};
