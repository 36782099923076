import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import keycloakCreator from 'keycloak-js';
import { feature, actions, IProfile } from './config';
import { selectors } from '../config.selectors';

export const sagas = [authenticate, forking];

const keycloak = keycloakCreator(`/keycloak.${process.env.NODE_ENV}.json`);

function* forking() {
  yield takeLatest(feature.initPattern, init);
}

/*
The authentication module only exposes the logout route. 
When the modules feature route is called the logout has to be done
*/
function* init() {
  yield call(keycloak.logout, { redirectUri: window.location.origin });
}

export function* authenticate() {
  if (process.env.REACT_APP_AUTH === 'disable') return;
  console.log('authenticate');
  while (true) {
    try {
      const isLoggedIn = yield select(selectors.auth.isLoggedIn);
      if (!isLoggedIn) {
        const authenticated = yield call(keycloak.init, {
          onLoad: 'login-required',
        });
        if (!authenticated) {
          window.location.reload();
        } else {
          yield put(
            actions.authSuccess(
              keycloak.token ?? '',
              keycloak.subject ?? null,
              keycloak.tokenParsed?.name ?? null,
              keycloak.realmAccess?.roles ?? [],
              keycloak.tokenParsed?.groups ?? []
            )
          );

          const profile: IProfile = yield call(keycloak.loadUserProfile);
          yield put(actions.updateProfile(profile));

          yield put({ type: 'APP_AUTHENTICATED' });
        }
      }

      const refreshed = yield call(keycloak.updateToken, 15);
      if (refreshed) {
        yield put(actions.refreshSuccess(keycloak.token ?? '', keycloak.subject ?? null));
      }
    } catch (e) {
      yield put(actions.failure());
      console.error('Authentication failed');
    }

    yield delay(1000);
  }
}
