import { createSelectors as createKeycloakSelectors } from './keycloak/selectors';
import { createSelectors as createPupilFormSelectors } from './pupil-form/selectors';
import { createSelectors as createEnterStatementsSelectors } from './enter-statements/selectors';
import { createSelectors as createTestSVOSelectors } from './test-svo/selectors';
import { createSelectors as createTestNGPSelectors } from './test-ngp/selectors';
import { createSelectors as createTestNominativSelectors } from './test-nominativ/selectors';
import { createSelectors as createTestNarrationSelectors } from './test-narration/selectors';
import { createSelectors as createResultsSummarySelectors } from './results-summary/selectors';
import { createSelectors as createResultsPdfSelectors } from './results-pdf/selectors';
import { createSelectors as createIncompleteEvaluationsSelectors } from './incomplete-evaluations/selectors';
import { createSelectors as createCompleteEvaluationSelectors } from './complete-evaluations/selectors';
import { createSelectors as createStatementsReviewSelectors } from './statements-review/selectors';

export const selectors = {
  auth: createKeycloakSelectors('auth'),
  pupilForm: createPupilFormSelectors('pupilForm'),
  enterStatements: createEnterStatementsSelectors('enterStatements'),
  testSVO: createTestSVOSelectors('testSVO'),
  testNGP: createTestNGPSelectors('testNGP'),
  testNominativ: createTestNominativSelectors('testNominativ'),
  testNarration: createTestNarrationSelectors('testNarration'),
  resultsSummary: createResultsSummarySelectors('resultsSummary'),
  resultsPdf: createResultsPdfSelectors('resultsPdf'),
  incompleteEvaluations: createIncompleteEvaluationsSelectors('incompleteEvaluations'),
  completeEvaluations: createCompleteEvaluationSelectors('completeEvaluations'),
  statementsReview: createStatementsReviewSelectors('statementsReview'),
};
