import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { selectors } from '../config';
import { TId } from '@app/lib/type';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  statementsSVO: selectors.testSVO.getStatementsSVO(state, selectors.enterStatements.getEnteredByItem),
  statementsSVOP: selectors.testSVO.getStatementsSVOP(state, selectors.enterStatements.getEnteredByItem),
  statementsSHOV: selectors.testSVO.getStatementsSHOV(state, selectors.enterStatements.getEnteredByItem),
  done: selectors.testSVO.getIsDone(state),
  gradeTotal: selectors.testSVO.getGradeTotal(state),
  gradeColor: selectors.testSVO.getSupportColor(state, selectors.pupilForm.getInDaycare(state)),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onGrade: (testLineId: TId, points: 0 | 1 | 2) => dispatch(actions.updateGrading(testLineId, points)),
  onRegrade: (testLineId: TId) => dispatch(actions.openGrading(testLineId)),
  onNext: () => dispatch(actions.gotoTestNGP()),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
