import { IRootState, IStatements } from '@app/state';
import { get } from 'lodash';
import { getItemImage } from './partials/item';
import { TState } from './state';
import { TId } from 'src/lib/type';


export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  const getStatements = (state: IRootState): IStatements[] => {
    const slice = getSlice(state);
    return (slice.testItem.ids ?? []).map((id) => {
      const item = slice.testItem.map[id];
      return {
        id: item.id,
        type: item.itemType,
        label: item.itemType === 'statement' ? `Item ${item.itemNumber}` : `Erzählen`,
        text: item.statement,
        image: getItemImage(item.itemNumber),
        entered: slice.entered[item.id],
      };
    });
  };

  const getStatementHashedIds = (state: IRootState): TId[] => {
    const slice = getSlice(state);

    return Object.values(slice.hashed).filter((v): v is string => !!v);
  };

  return {
    getStatements,
    getStatementHashedIds,
    getIsDone: (state: IRootState): boolean => {
      const slice = getSlice(state);
      const statements = getStatements(state);
      return (
        !!slice.featureInitialized &&
        statements.length === statements.filter((s) => s.entered === false || s.entered).length
      );
    },
    getEnteredByItem: (state: IRootState, item: string): string | false | null => {
      const slice = getSlice(state);
      return slice.entered[item] || null;
    },
    skipNarration: (state: IRootState): boolean => {
      return getStatements(state).filter((s) => s.type === 'narration' && s.entered !== false).length === 0;
    },
  };
};
