import { VFC, Fragment, useCallback, forwardRef, ForwardedRef, useRef, ChangeEvent } from 'react';
import { useRefComposer } from 'react-ref-composer';
import styled, { css } from 'styled-components/macro';

const cssCheckedRight = css`
  background: #7acf80;
  border-color: #2ba834;
  color: #ffffff;
`;

const cssCheckedFalse = css`
  background: #de5b5b;
  border-color: #f98c8c;
  color: #ffffff;
`;

const cssUncheckedRight = css`
  background: #f5f8f6;
  border: 2px solid #edf2ef;
  border-radius: 13px;
  color: #2ba834;
  :hover {
    background: #7acf80;
    border-color: #2ba834;
    color: #ffffff;
  }
`;

const cssUncheckedFalse = css`
  color: #c01616;
  background: #fff3f3;
  border: 2px solid #f9e7e7;
  border-radius: 13px;
  :hover {
    color: #ffffff;
    background: #de5b5b;
    border: 2px solid #c01616;
    border-radius: 13px;
  }
`;

const cssUncheckedBasedOnExpert = css`
  border-color: #00b9e3;
  &:after {
    color: #00b9e3;
    content: '✱';
    font-weight: 500;
    font-size: 1.2rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
  }
`;

const cssUncheckedBasedOnHistory = css`
  border-color: #e5007d;
  &:after {
    color: #e5007d;
    content: 'H';
    font-weight: 500;
    font-size: 1.2rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
  }
`;

const cssUncheckedBasedOnAI = css`
  border-color: #50e3c2;
  &:after {
    color: #50e3c2;
    content: 'KI';
    font-weight: 500;
    font-size: 1.2rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
  }
`;

const cssGradingButtonCommon = css`
  cursor: pointer;
  position: relative;
  border-width: 2px;
  border-style: solid;
  border-radius: 1.3rem;
  font-family: Raleway;
  font-weight: 600;
  letter-spacing: 0.074rem;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export interface NumericGradingButtonProps {
  caption: 0 | 1 | 2;
  checked?: boolean;
  basedOn?: 'none' | 'expert' | 'history' | 'ai';
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const StyledButtonWrapper = styled.div`
  display: inline-block;
`;

const isRight = (caption: 0 | 1 | 2 | 'R' | 'F' | 'S' | '⇔' | 'J' | 'N' | '✓') =>
  [1, 2, 'R', 'S', '⇔', 'J', '✓'].includes(caption);

const StyledNumericGradingButton = styled.label<NumericGradingButtonProps>`
  ${cssGradingButtonCommon}
  width: 5.7rem;
  height: 5.7rem;
  font-size: 2.6rem;
  padding-bottom: 0.5rem;
  ${({ caption, checked }) => checked && isRight(caption) && cssCheckedRight}
  ${({ caption, checked }) => checked && !isRight(caption) && cssCheckedFalse}
  ${({ caption, checked }) => !checked && isRight(caption) && cssUncheckedRight}
  ${({ caption, checked }) => !checked && !isRight(caption) && cssUncheckedFalse}
  ${({ checked, basedOn }) => !checked && basedOn === 'expert' && cssUncheckedBasedOnExpert}
  ${({ checked, basedOn }) => !checked && basedOn === 'history' && cssUncheckedBasedOnHistory}
  ${({ checked, basedOn }) => !checked && basedOn === 'ai' && cssUncheckedBasedOnAI}
`;

const HiddenInput = styled.input`
  display: none;
`;

export const NumericGradingButton: VFC<NumericGradingButtonProps> = forwardRef<
  HTMLInputElement,
  NumericGradingButtonProps
>(({ caption, onChange = () => {}, checked = false, basedOn = 'none' }, outerRef: ForwardedRef<HTMLInputElement>) => {
  const innerRef = useRef<HTMLInputElement>();
  const composeRefs = useRefComposer();
  const handleKeyToggle = useCallback((e) => {
    if (innerRef.current && (e.keyCode === 32 || e.keyCode === 13)) {
      innerRef.current.checked = !innerRef.current.checked;
    }
  }, []);
  return (
    <Fragment>
      <StyledNumericGradingButton checked={checked} caption={caption} basedOn={basedOn} onKeyDown={handleKeyToggle}>
        <HiddenInput type="radio" checked={checked} onChange={onChange} ref={composeRefs(innerRef, outerRef)} />
        {caption}
      </StyledNumericGradingButton>
    </Fragment>
  );
});

export interface SmilyGradingButtonProps {
  caption: 'R' | 'F' | 'S' | '⇔' | 'J' | 'N' | '✓';
  checked?: boolean;
  basedOn?: 'none' | 'expert' | 'history' | 'ai';
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
}
const StyledBooleanGradingButton = styled.label<SmilyGradingButtonProps>`
  ${cssGradingButtonCommon}
  width: 5rem;
  height: 5rem;
  font-size: 2.6rem;
  padding-bottom: 0;
  ${({ caption, checked }) => checked && isRight(caption) && cssCheckedRight}
  ${({ caption, checked }) => checked && !isRight(caption) && cssCheckedFalse}
  ${({ caption, checked }) => !checked && isRight(caption) && cssUncheckedRight}
  ${({ caption, checked }) => !checked && !isRight(caption) && cssUncheckedFalse}
  ${({ checked, basedOn }) => !checked && basedOn === 'expert' && cssUncheckedBasedOnExpert}
  ${({ checked, basedOn }) => !checked && basedOn === 'history' && cssUncheckedBasedOnHistory}
  ${({ checked, basedOn }) => !checked && basedOn === 'ai' && cssUncheckedBasedOnAI}
`;

export const SmilyGradingButton: VFC<SmilyGradingButtonProps> = forwardRef<HTMLInputElement, SmilyGradingButtonProps>(
  (
    { caption, onChange = (evt) => {}, checked = false, basedOn = 'none' },
    outerRef: ForwardedRef<HTMLInputElement>
  ) => {
    const innerRef = useRef<HTMLInputElement>();
    const composeRefs = useRefComposer();
    const handleKeyToggle = useCallback((e) => {
      if (innerRef.current && (e.keyCode === 32 || e.keyCode === 13)) {
        innerRef.current.checked = !innerRef.current.checked;
      }
    }, []);
    return (
      <StyledButtonWrapper>
        <StyledBooleanGradingButton checked={checked} caption={caption} basedOn={basedOn} onKeyDown={handleKeyToggle}>
          <HiddenInput type="checkbox" checked={checked} onChange={onChange} ref={composeRefs(innerRef, outerRef)} />
          {caption}
        </StyledBooleanGradingButton>
      </StyledButtonWrapper>
    );
  }
);
