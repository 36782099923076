import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ILineResult, IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { selectors } from '../config';
import { TId } from '@app/lib/type';
import {} from './types';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  itemSelection: selectors.statementsReview.getTestItems(state),
  statementSelection: selectors.statementsReview.getStatements(state),
  reviewLines: selectors.statementsReview.getReviewLinesForGrading(state),
  reviewDone: selectors.statementsReview.getReviewLinesDone(state),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onSelectItem: (testItemId: TId) => dispatch(actions.selectItem(testItemId)),
  onSelectStatement: (statementId: TId) => dispatch(actions.selectStatement(statementId)),
  onGrade: (reviewLineId: TId, update: Partial<ILineResult>) => dispatch(actions.updateGrading(reviewLineId, update)),
  onRegrade: (reviewLineId: TId) => dispatch(actions.openGrading(reviewLineId)),
  onConfirmReview: (statementId: TId) => dispatch(actions.confirmReview(statementId)),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
