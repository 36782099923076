import { IRootState } from '@app/state';
import { get } from 'lodash';
import { TState } from './state';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  const hasRole = (state: IRootState, role: string): boolean => {
    return getSlice(state).roles.includes(role);
  };

  return {
    hasRole,

    roles: (state: IRootState): string[] => {
      return getSlice(state).roles;
    },

    groups: (state: IRootState): string[] => {
      return getSlice(state).groups;
    },

    isAuthorizedForRole: (state: IRootState, role: string) => {
      if (hasRole(state, 'boss')) return true;
      if (hasRole(state, role)) return true;
      return false;
    },

    isLoggedIn: (state: IRootState): boolean => {
      return !!getSlice(state).accessToken;
    },

    accessToken: (state: IRootState): string => {
      return getSlice(state).accessToken;
    },

    getUserId: (state: IRootState): string | null => {
      return getSlice(state).userId;
    },

    getUserString: (state: IRootState): string => {
      const profile = getSlice(state).profile;
      return (
        (profile.firstName || profile.lastName ? `${profile.firstName} ${profile.lastName}` : profile.username) ?? ''
      );
    },

    getUserFullname: (state: IRootState): string | null => {
      return getSlice(state).fullName;
    },
  };
};
