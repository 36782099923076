import { createReducer } from '@reduxjs/toolkit';
import { actions, IProfile } from './config';

interface IState {
  profile: IProfile;
  roles: string[];
  groups: string[];
  accessToken: string;
  userId: string | null;
  fullName: string | null;
}

export type TState = IState;

const initialState: TState = {
  profile: {},
  roles: [],
  groups: [],
  accessToken: '',
  userId: null,
  fullName: '',
};

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(actions.authSuccess, (state, action) => {
    state = {
      ...state,
      ...action.payload,
    };
    return state;
  });
  builder.addCase(actions.failure, (state) => {
    state = { ...initialState };
    return state;
  });
  builder.addCase(actions.refreshSuccess, (state, action) => {
    state.accessToken = action.payload.accessToken;
    state.userId = action.payload.userId;
    return state;
  });
  builder.addCase(actions.updateProfile, (state, action) => {
    state.profile = action.payload;
  });
});
