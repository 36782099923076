import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { Input } from '../../ui/form/input';
import { Button } from '../../ui/buttons/button';
import { ButtonInfoIcon, ButtonNextIcon } from '../../ui/icons';
import { StatementCard } from './partials/statement-card';
import { NarrationCard } from './partials/narration-card';
import { CardsGrid } from '../../ui/layout/grid';
import { Motivation, Summary, Warning } from '../../ui/typography';
import { IStatements } from './types';

const StyledPupilInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;

export interface IViewProps {
  pupilcode: string;
  done: boolean;
  onInfos: () => void;
  onNext: () => void;
  onEntered: (id: string, value: string | false | null) => void;
  statements: IStatements[];
}

export const View: VFC<IViewProps> = ({ pupilcode, onInfos, onNext, statements, done, onEntered }) => {
  return (
    <Page
      header={
        <Header
          title="Äußerungen des Kindes"
          left={
            <StyledPupilInfo>
              <Input type="text" disabled value={pupilcode || ''} placeholder="Codierung des Kindes" />
            </StyledPupilInfo>
          }
        />
      }
    >
      <Section>
        <CardsGrid variant="compact" stretchItems={true}>
          {statements.map((statement) => {
            if (statement.type === 'statement') {
              return (
                <StatementCard
                  key={statement.id}
                  label={statement.label}
                  image={statement.image}
                  text={statement.text || 'Textvorgabe fehlt'}
                  entered={statement.entered}
                  onEntered={(value) => {
                    onEntered(statement.id, value);
                  }}
                />
              );
            } else {
              return (
                <NarrationCard
                  key={statement.id}
                  label={statement.label}
                  image={statement.image}
                  text={statement.text || 'Textvorgabe fehlt'}
                  entered={statement.entered}
                  onEntered={(value) => {
                    onEntered(statement.id, value);
                  }}
                />
              );
            }
          })}
        </CardsGrid>
      </Section>
      <Section variant="done">
        <Summary>
          Das Kind hat bei {statements.filter((s) => s.entered === false).length ?? 0} von {statements.length ?? 0}{' '}
          Items keine Äußerung gemacht.
        </Summary>
        <Button variant="primary" onClick={onNext} disabled={!done}>
          <ButtonNextIcon />
          WEITER
        </Button>
        {done ? (
          <Motivation>Super, der 1. Schritt ist geschafft!</Motivation>
        ) : (
          <Warning>Bitte füllen Sie alles aus!</Warning>
        )}
      </Section>
    </Page>
  );
};
