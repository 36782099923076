import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { HelpButton } from '../../ui/help/help-button';
import { EvaluationLegend } from '../../ui/help/evaluation-legend';
import { ResultCard } from './partials/result-card';
import { CardsGrid } from '../../ui/layout/grid';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon } from '../../ui/icons';
import { Motivation, Warning } from '../../ui/typography';
import { ResultCircle } from '../../ui/layout/result-circle';
import { convertToRoman } from 'src/state';
import { ITestNominativStatement, ITestNominativStatementGrading } from './types';
import { TId } from '@app/lib/type';

const StyledTestPage = styled(Page)`
  .nominativ {
    font-weight: bold;
  }
`;

export interface ViewProps {
  done: boolean;
  nominativLevel: number;
  onNext: () => void;
  onGrade: (statementId: TId, update: Partial<ITestNominativStatementGrading>) => void;
  onRegrade: (statementId: string) => void;
  statements: ITestNominativStatement[];
}

export const View: VFC<ViewProps> = ({ statements = [], done, nominativLevel, onNext, onGrade, onRegrade }) => {
  return (
    <StyledTestPage
      header={
        <Header
          title="Genus: Nominativ I"
          left={<EvaluationLegend />}
          right={<HelpButton title="Kriterien">Für den Auswertungsbogen 3 - Genus: Nominativ I</HelpButton>}
        />
      }
    >
      <Section>
        <CardsGrid>
          {statements.map((s) => {
            return (
              <ResultCard
                {...s}
                key={s.id}
                onGrade={(update) => onGrade(s.id, update)}
                onRegrade={() => onRegrade(s.id)}
              />
            );
          })}
        </CardsGrid>
      </Section>
      <Section variant="done">
        {done && <ResultCircle title="Entwicklungsstufe" grade={convertToRoman(nominativLevel)} />}
        {done ? (
          <Motivation>Das war schon der dritte Test! Puh!</Motivation>
        ) : (
          <Warning>Bitte füllen Sie alles aus!</Warning>
        )}
        <Button variant="primary" onClick={onNext} disabled={!done}>
          <ButtonNextIcon />
          WEITER
        </Button>
      </Section>
    </StyledTestPage>
  );
};
