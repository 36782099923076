import { TId } from '@app/lib/type';
import { createAction } from '@reduxjs/toolkit';
import { features, createJob } from '../config';
import { TTestLineStatus } from './types';
export { selectors } from '../config';

export const feature = features.testSVO;

export const jobs = {
  loadTestSettings: createJob.loadTestSettings(),
};

export const testName = 'Satzbau & Wortschatz';

interface IStatusActionPayload {
  payload: {
    testLineId: TId;
    status: TTestLineStatus;
  };
}

export const actions = {
  gotoEnterStatements: () => features.enterStatements.routeAction({}),
  gotoTestSVO: () => features.testSVO.routeAction({}),
  gotoTestNGP: () => features.testNGP.routeAction({}),
  gotoTestNominativ: () => features.testNominativ.routeAction({}),
  gotoTestNarration: () => features.testNarration.routeAction({}),
  gotoResultSummary: () => features.resultsSummary.routeAction({}),
  updateGrading: createAction('UPDATE-GRADING@TEST-SVO', (testLineId: TId, points: 0 | 1 | 2) => ({
    payload: { testLineId, points },
  })),
  openGrading: createAction(
    'OPEN-GRADING@TEST-SVO',
    (testLineId: TId): IStatusActionPayload => ({
      payload: { testLineId, status: 'open' },
    })
  ),
  openAssistant: createAction(
    'OPEN-ASSISTANT@TEST-SVO',
    (testLineId: TId): IStatusActionPayload => ({
      payload: { testLineId, status: 'assistant1' },
    })
  ),
};
