export type TSupportColor = 'purple' | 'blue' | 'orange' | 'red' | 'green';

const supportColorMap: Record<TSupportColor, string> = {
  purple: 'Lila',
  blue: 'Blau',
  orange: 'Orange',
  red: 'Red',
  green: 'Grün',
};

export const supportColorToText = (supportColor: TSupportColor): string => supportColorMap[supportColor] || '';

export const supportGameMap: Record<TSupportColor, string[]> = {
  purple: ["1.1","2.1","2.2","2.3","3.1","3.2","4.1","4.2","4.3","5.1","5.2","5.3","5.4","6.1","6.2","6.3","6.4","6.5","7.1","7.2","7.3","7.4","8.1","9.1","9.2","10.1","10.2","12.1"],
  blue: ["1.1","2.1","2.2","2.3","2.4","2.5","3.1","3.2","4.1","4.2","4.3","5.1","5.2","5.3","5.4","5.5","5.6","6.1","7.1","7.2","7.3","7.4","7.5","7.6","7.7","7.8","7.9","7.10","7.11","7.12","8.1","8.2","8.3","8.4","8.5","8.6","8.7","8.8","8.9","8.10","8.11","9.1"],
  orange: ["1.1","2.1","3.1","3.2","3.3","3.4","4.1","5.1","5.2","5.3","5.4","5.5","5.6","6.1","6.2","7.1","8.1","8.2","11.1","11.2","11.3","11.4","11.5","11.6","12.1","13.1","13.2","14.1","14.2","15.1","15.2","16.1"],
  green: ["2.1","2.2","2.3","2.4","2.5","2.6","2.7","2.8","3.1","3.2","4.1","4.2","4.3","4.4","5.1","6.1","6.2","6.3","7.1","7.2","7.3","7.4","8.1","8.2"],
  red: ["1.1","1.2","2.1","2.2","2.3","2.4","2.5","2.6","3.1","3.2","3.3","3.4","3.5","3.6","3.7","3.8","3.9","3.10","3.11","3.12","3.13","3.14","3.15","3.16","3.17","4.1","4.2","4.3","4.4","4.5","4.6","5.1","5.2","5.3","5.4","5.5","5.6","5.7","5.8","5.9","6.1","6.2","6.3","6.4","6.5","6.6","6.7","6.8","6.9","6.10","7.1","7.2","7.3","7.4","7.5","7.6","7.7","7.8","7.9"]
}

export type TGender = 'male' | 'female' | 'diverse';

const genderMap: Record<TGender, string> = {
  male: 'Männlich',
  female: 'Weiblich',
  diverse: 'Unbekannt',
};

export const genderToText = (gender: TGender): string => genderMap[gender] || '';

export type TDiagnosis = 'start' | 'end';

const diagnosisMap: Record<TDiagnosis, string> = {
  start: 'Anfangsdiagnostik',
  end: 'Enddiagnostik',
};

export const diagnosisToText = (diagnosis: TDiagnosis): string => diagnosisMap[diagnosis] || '';

export type TClassLevel = 'daycare' | 'pre-school' | 'class-1' | 'class-2' | 'class-3';

const classLevelMap: Record<TClassLevel, string> = {
  daycare: 'Kita',
  'pre-school': 'Vorschule',
  'class-1': 'Klassenstufe 1',
  'class-2': 'Klassenstufe 2',
  'class-3': 'Klassenstufe 3',
};

export const classLevelToText = (classLevel: TClassLevel): string => classLevelMap[classLevel] || '';

var romanMatrix: [number, string][] = [
  [1000, 'M'],
  [900, 'CM'],
  [500, 'D'],
  [400, 'CD'],
  [100, 'C'],
  [90, 'XC'],
  [50, 'L'],
  [40, 'XL'],
  [10, 'X'],
  [9, 'IX'],
  [5, 'V'],
  [4, 'IV'],
  [1, 'I'],
];

export const convertToRoman = (num: number): string => {
  if (num === 0) {
    return '';
  }
  for (var i = 0; i < romanMatrix.length; i++) {
    if (num >= romanMatrix[i][0]) {
      return romanMatrix[i][1] + convertToRoman(num - romanMatrix[i][0]);
    }
  }
  return '';
};
