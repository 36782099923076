import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as BottomCircles } from './page-circles.svg';

const Styled = styled.article`
  position: relative;
  background-color: var(--page-background-color, transparent);
  max-width: 120rem;
  margin: 0 auto;
  @media (max-width: 1200px) {
    margin: 0 2rem;
  }
  padding-bottom: 20rem;
`;

const StyledBottomCircles = styled(BottomCircles)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export interface PageProps {
  header?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const Page: VFC<PageProps> = ({ header, children, className }) => {
  return (
    <Styled className={className}>
      <StyledBottomCircles />
      {header}
      {children}
    </Styled>
  );
};

export const PageMemo = memo(Page);
