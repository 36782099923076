import { take, put } from 'redux-saga/effects';
import { ActionCreatorWithPreparedPayload, AnyAction } from '@reduxjs/toolkit';
import { throwAppError } from '../error';

export interface IJobDeclaration {
  jobKey: string;
  jobId: string;
  pattern: (action: any) => boolean;
  runAction: ActionCreatorWithPreparedPayload<
    [data?: any],
    {
      data: any;
    }
  >;
  failedPattern: (action: any) => boolean;
}

export function* tryJob(
  jobDeclaration: IJobDeclaration,
  runPayload?: any,
  resultCheck?: (action: AnyAction) => boolean,
  resultExtractor?: (action: AnyAction) => any
) {
  yield put(jobDeclaration.runAction(runPayload));
  const resultAction = yield take(jobDeclaration.pattern);
  if (jobDeclaration.failedPattern(resultAction))
    throwAppError(
      `Job ${jobDeclaration.jobKey} - ${jobDeclaration.jobId} failed: ${resultAction.error?.message}.`,
      'app-jobs',
      resultAction.error
    );
  if (resultCheck && !resultCheck(resultAction)) {
    throwAppError(
      `Job ${jobDeclaration.jobKey} - ${jobDeclaration.jobId} failed result check.`,
      'app-jobs',
      resultAction
    );
  }
  return resultExtractor ? resultExtractor(resultAction) : resultAction;
}
