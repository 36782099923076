import { sha1 } from "hash-wasm";
import { TId } from "src/lib/type";

function strEncodeUTF16(str: string) {
    var buf = new ArrayBuffer(str.length * 2);
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return new Uint16Array(buf);
}

const changeEndianness = (str: string) => {
  const result: string[] = [];
  let len = str.length - 2;
  while (len >= 0) {
    result.push(str.substring(len, len + 2));
    len -= 2;
  }
  return result.join('');
}

export const normalizeStatement = function (statement: string) { 
  return statement
    .replace(/[^ abcdefghijklmnopqrstuvwxyzäöüABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜß()\[\]]/g, '')
    .replace(' ', '<>').replace('><', '').replace('<>', ' ').toLocaleLowerCase('de')
    .trim();
}

export const hashStatement = async function (normalizedStatement: string, item: TId) {
  let out = await sha1(strEncodeUTF16(item.toUpperCase() + normalizedStatement));

  return [
    changeEndianness(out.substring(0, 8)),
    changeEndianness(out.substring(8, 12)),
    '5' + changeEndianness(out.substring(12, 16)).substring(1,4),
    '8' + out.substring(16, 20).substring(1,4),
    out.substring(20, 32)
  ].join('-');
};
