import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Card, CardBodySection, CardHeader, CardHeaderContent } from '../../../ui/layout/card';
import { SmilyGradingButton } from '../../../ui/buttons/grading';
import { Input } from '../../../ui/form/input';
import { ITestNarrationConjunctionItem } from '../types';

const StyledCard = styled(Card)``;

const StyledCardBodyInner = styled.section`
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 2rem;
  column-gap: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-items: center;
  align-items: center;

  font-weight: 500;
  font-size: 1.619rem;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.025rem;

  & > header {
    text-align: center;
  }
`;

const StyledLabelInput = styled(Input)`
  width: 15rem;
  height: 3.5rem;
  border-radius: 1.3rem;
`;
const StyledFrequencyInput = styled(Input)`
  width: 5rem;
  height: 5rem;
  border-radius: 1.3rem;
  border-width: 2px;
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 1;
  text-align: center;
  padding: 0.5rem;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledCardHeaderContent = styled(CardHeaderContent)`
  display: block;
  & > header {
    display: block;
    text-align: center;
  }
`;

const StyledButtonGroup = styled.span`
  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export interface ConjunctionCardProps extends ITestNarrationConjunctionItem {
  className?: string;
  onEditLabel?: (label: string | null) => void;
  onChange?: (frequency: number, correct: boolean) => void;
}

export const ConjunctionCard: VFC<ConjunctionCardProps> = ({
  className,
  label,
  grading,
  readonlyLabel,
  onEditLabel,
  onChange,
}) => {
  const success = (grading.frequency ?? 0) > 0 && !!label;
  return (
    <StyledCard className={className} variant={success ? 'success' : 'default'}>
      <CardHeader>
        {readonlyLabel && <StyledCardHeaderContent>{label}</StyledCardHeaderContent>}
        {!readonlyLabel && (
          <StyledCardHeaderContent>
            <StyledLabelInput
              value={label}
              onChange={(e) => onEditLabel && onEditLabel(!!e.currentTarget.value ? e.currentTarget.value : null)}
            />
          </StyledCardHeaderContent>
        )}
      </CardHeader>
      <CardBodySection title="">
        <StyledCardBodyInner>
          <header>Häufigkeit</header>
          <header>Korrekter Gebrauch</header>
          <StyledFrequencyInput
            type="number"
            value={grading.frequency ?? 0}
            onFocus={(e) => e.currentTarget.select()}
            onChange={(e) =>
              onChange &&
              !isNaN(parseInt(e.currentTarget.value)) &&
              onChange(parseInt(e.currentTarget.value), grading.other)
            }
          />
          <StyledButtonGroup>
            <SmilyGradingButton
              caption="J"
              checked={grading.other}
              onChange={(evt) =>
                evt.currentTarget.checked && onChange && onChange(!grading.frequency ? 1 : grading.frequency, true)
              }
            />
            <SmilyGradingButton
              caption="N"
              checked={!grading.other}
              onChange={(evt) =>
                evt.currentTarget.checked && onChange && onChange(!grading.frequency ? 1 : grading.frequency, false)
              }
            />
          </StyledButtonGroup>
        </StyledCardBodyInner>
      </CardBodySection>
    </StyledCard>
  );
};
