import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { selectors } from '../config';
import { TId } from '@app/lib/type';
import { ITestNominativStatementGrading } from './types';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  statements: selectors.testNominativ.getStatements(state, selectors.enterStatements.getEnteredByItem),
  done: selectors.testNominativ.getIsDone(state),
  nominativLevel: selectors.testNominativ.getNominativLevel(state),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onGrade: (testLineId: TId, update: Partial<ITestNominativStatementGrading>) =>
    dispatch(actions.updateGrading(testLineId, update)),
  onRegrade: (testLineId: TId) => dispatch(actions.openGrading(testLineId)),
  onNext: () => dispatch(actions.gotoNextTest()),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
