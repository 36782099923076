import { VFC, memo } from 'react';
import styled from 'styled-components/macro';
import { Card, CardHeader, CardHeaderContent } from '../../../ui/layout/card';

const StyledCard = styled(Card)`
  width: 40rem;
  margin: 0 auto;
`;

const StyledCardBody = styled.section`
  font-family: Raleway;
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.5rem;
  padding-bottom: 2rem;
`;

export interface ITestNarrationCard {
  entered: string | false | null;
}

export const NarrationCard: VFC<ITestNarrationCard> = ({ entered }) => {
  return (
    <StyledCard variant="default">
      <CardHeader>
        <CardHeaderContent variant="compact" label="Auswertungsbogen 4: Erzählen">
          Äußerung des Kindes
        </CardHeaderContent>
      </CardHeader>
      <StyledCardBody>{entered !== false ? entered : 'Das Kind hat keine Äußerung gemacht.'}</StyledCardBody>
    </StyledCard>
  );
};

export const NarrationCardMemo = memo(NarrationCard);
