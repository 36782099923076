import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Card, CardProps, CardHeader, CardHeaderContent } from '../../../ui/layout/card';
import { Textarea } from '../../../ui/form/textarea';
import { ITestNarrationStatementItem } from '../types';

const StyledTextarea = styled(Textarea)`
  width: 100%;
`;

const StyledCard = styled(Card)<CardProps & { custom: boolean }>`
  /*width: 40rem;*/
  margin: 0 auto;
  ${({ custom }) => !custom && `cursor: pointer;`}
  ${({ custom }) => !custom && `user-select: none;`}
  border: 2px solid transparent;
  ${({ variant }) => variant === 'success' && `border-color: #7acf80`}
`;

const StyledCardBody = styled.section`
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.005rem;
  color: #009ee3;
  padding-bottom: 0;
`;

export interface StatementCardProps extends ITestNarrationStatementItem {
  onChange?: (checked: boolean) => void;
  onEntered?: (value: string | null) => void;
}

export const StatementCard: VFC<StatementCardProps> = ({
  label,
  text,
  grading,
  custom,
  correct,
  onChange,
  onEntered,
}) => {
  return (
    <StyledCard
      variant={correct ? 'success' : 'default'}
      custom={custom}
      onClick={() => onChange && !custom && onChange(!grading.other)}
    >
      <CardHeader>
        <CardHeaderContent variant="compact" label={label} />
      </CardHeader>
      <StyledCardBody>
        {!custom ? (
          text
        ) : (
          <StyledTextarea
            value={text || ''}
            onInput={(e) => onEntered && onEntered(e.currentTarget.value === '' ? null : e.currentTarget.value)}
          />
        )}
      </StyledCardBody>
    </StyledCard>
  );
};
