import { Fragment, VFC, ReactNode } from 'react';
import './normalize.css';
import './font.css';
import './milligram.css';

export interface ApplyCssProps {
  children?: ReactNode;
}

export const ApplyCss: VFC<ApplyCssProps> = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};
