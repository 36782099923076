import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';
import { NormalizedButton } from '../buttons/button';

const StyledHelpButtonIconSpace = styled.div`
  background: #e2e2e2;
  align-self: stretch;
  font-family: Raleway;
  font-weight: 600;
  font-size: 10rem;
  letter-spacing: 0.74px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c9c9c9;
`;

const StyledHelpButton = styled(NormalizedButton)`
  border: 1px solid #e2e2e2;
  background: inherit;
  padding: 0;
  width: 34.5rem;
  height: 12rem;
  display: grid;
  grid-template-columns: 12rem auto;
  grid-template-rows: 100%;
  border-radius: 1.5rem;
  overflow: hidden;
  :hover ${StyledHelpButtonIconSpace} {
    background: #e0f0f7;
    color: #ffffff;
  }
`;

const StyledHelpButtonTextSpace = styled.div`
  align-self: center;
  text-align: left;
`;

const StyledHelpButtonTitle = styled.p`
  margin: 1rem;
  font-family: Raleway;
  font-weight: 500;
  font-size: 2.5rem;
  color: #212121;
`;

const StyledHelpButtonText = styled.p`
  margin: 1rem;
  font-family: Raleway;
  font-weight: 400;
  font-size: 1.42rem;
  color: #666666;
  letter-spacing: 0.025rem;
  line-height: 2rem;
`;

export interface HelpButtonProps {
  title?: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
}

export const HelpButton: VFC<HelpButtonProps> = ({ title, children, onClick }) => {
  return (
    <StyledHelpButton onClick={onClick}>
      <StyledHelpButtonIconSpace>?</StyledHelpButtonIconSpace>
      <StyledHelpButtonTextSpace>
        <StyledHelpButtonTitle>{title}</StyledHelpButtonTitle>
        <StyledHelpButtonText>{children}</StyledHelpButtonText>
      </StyledHelpButtonTextSpace>
    </StyledHelpButton>
  );
};

export const HelpButtonMemo = memo(HelpButton);
