import { genericTestLineIsDone, IRootState, TEnteredByItem } from '@app/state';
import { get } from 'lodash';
import { TState } from './state';
import { ITestNominativStatement } from './types';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  return {
    getLineResults: (state: IRootState) => Object.values(getSlice(state).lineResult),
    getStatements: (state: IRootState, getEnteredByItem: TEnteredByItem): ITestNominativStatement[] => {
      const sectionSpecifier = 'Artikel';
      const slice = getSlice(state);
      const section = slice.testSectionMap[sectionSpecifier];
      if (!section || !slice.testLine.groups.testSection || !slice.testLine.groups.testSection[section.id]) return [];
      return slice.testLine.groups.testSection[section.id].map((lineId) => {
        const line = slice.testLine.map[lineId];
        const item = slice.testItem.map[line.testItem];
        const lineResult = slice.lineResult[lineId];
        const lineStatus = slice.lineStatus[lineId] ?? 'open';
        const gradingProposal = slice.gradingProposal.map[lineId];
        return {
          id: line.id,
          label: `Item ${item.itemNumber}`,
          statement: item.statement,
          text: getEnteredByItem(state, line.testItem) || 'Keine Äußerung',

          proposal: !gradingProposal ? undefined : {
            femaleArticleR: gradingProposal.femaleArticleR,
            femaleArticleW: gradingProposal.femaleArticleW,
            maleArticleR: gradingProposal.maleArticleR,
            maleArticleW: gradingProposal.maleArticleW,
            maleIndefinitArticleR: gradingProposal.maleIndefinitArticleR,
            maleIndefinitArticleW: gradingProposal.maleIndefinitArticleW,
            neuterArticleR: gradingProposal.neuterArticleR,
            neuterArticleW: gradingProposal.neuterArticleW,
            other: gradingProposal.other,
            basedOn: gradingProposal.proposedBy
          },

          grading: {
            femaleArticleR: lineResult?.femaleArticleR ?? false,
            femaleArticleW: lineResult?.femaleArticleW ?? false,
            maleArticleR: lineResult?.maleArticleR ?? false,
            maleArticleW: lineResult?.maleArticleW ?? false,
            maleIndefinitArticleR: lineResult?.maleIndefinitArticleR ?? false,
            maleIndefinitArticleW: lineResult?.maleIndefinitArticleW ?? false,
            neuterArticleR: lineResult?.neuterArticleR ?? false,
            neuterArticleW: lineResult?.neuterArticleW ?? false,
            other: lineResult?.other ?? false,
          },
          status: lineStatus,

          correct: lineResult?.correct ?? null,
        };
      });
    },
    getIsDone: (state: IRootState) => {
      const slice = getSlice(state);
      return (
        !!slice.featureInitialized &&
        Object.entries(slice.lineResult).length === slice.testLine.ids.length &&
        Object.values(slice.lineResult).every((line) => genericTestLineIsDone(line))
      );
    },
    getNominativLevel: (state: IRootState) => {
      const slice = getSlice(state);

      const counts = Object.values(slice.lineResult).reduce(
        (p, line) => {
          const mask = slice.testLineMask.map[line.id];
          return {
            female:
              p.female +
              (line.femaleArticleR && mask.femaleArticleR ? 1 : 0) +
              (line.femaleArticleW && mask.femaleArticleW ? 1 : 0),
            male:
              p.male +
              (line.maleArticleR && mask.maleArticleR ? 1 : 0) +
              (line.maleArticleW && mask.maleArticleW ? 1 : 0) +
              (line.maleIndefinitArticleR && mask.maleIndefinitArticleR ? 1 : 0) +
              (line.maleIndefinitArticleW && mask.maleIndefinitArticleW ? 1 : 0),
            neuter:
              p.neuter +
              (line.neuterArticleR && mask.neuterArticleR ? 1 : 0) +
              (line.neuterArticleW && mask.neuterArticleW ? 1 : 0),
            other: p.other + (line.other ? 1 : 0),
          };
        },
        {
          female: 0,
          male: 0,
          neuter: 0,
          other: 0,
        }
      );

      if (counts.other >= 5) return 1;
      if (counts.female >= 2 && ((counts.male >= 4 && counts.neuter >= 2) || (counts.male >= 3 && counts.neuter >= 3)))
        return 4;
      if (counts.female >= 2 && counts.male >= 4) return 3;
      return 2;
    },
  };
};
