import { createReducer } from '@reduxjs/toolkit';
import { EmptyINormalized, INormalized, TId } from '@app/lib/type';
import { feature, jobs, actions } from './config';
import { createDataSuccessToNormalizedReducer } from '@app/lib/reducer';
import { ITestItem } from '@app/state/types/model';

interface IState {
  featureInitialized?: boolean;
  testItem: INormalized<ITestItem>;
  entered: Record<TId, string | false | null>;
  hashed: Record<TId, TId | null>;
}

export type TState = IState;

const initialState: TState = {
  testItem: EmptyINormalized(),
  entered: {},
  hashed: {}
};

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(feature.initAction, (state) => {
    state.featureInitialized = true;
  });
  builder.addCase(jobs.loadEnterStatements.successAction, (state, data) => {
    createDataSuccessToNormalizedReducer('testItem', 'testItem')(state, data);
  });
  builder.addCase(actions.onEntered, (state, { payload: { id, value } }) => {
    state.entered[id] = value;
  });
  builder.addCase(actions.onHashed, (state, { payload: { id, value } }) => {
    state.hashed[id] = value;
  });
});
