export function throwAppError(message: string, tag?: string, payload?: any) {
  function AppError(message: string, tag?: string, payload?: any) {
    this.tag = tag;
    this.message = message;
    this.payload = payload;
    this.stack = new Error().stack;
    this.print = (e = this) => console.error(`[${e.tag}] ${e.message}\n`, `payload: ${e.payload?.toString()}`, e.stack);
  }
  AppError.prototype = new Error();
  AppError.prototype.name = 'AppError';
  AppError.prototype.constructor = AppError;

  throw new AppError(message, tag, payload);
}
