import axios from 'axios';

export const createAxios = (accessToken = undefined, language = undefined) => {
  let headers = {};
  if (accessToken) {
    headers['Authorization'] = accessToken;
  }
  if (language) {
    headers['Accept-Language'] = language;
  }
  let config = {
    baseURL: (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_BASEURL) || '/report',
    timeout: 120000,
    headers,
  };
  return axios.create(config);
};
