import { VFC } from 'react';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { HelpButton } from '../../ui/help/help-button';
import { NarrationCard } from './partials/narration-card';
import { StatementCard } from './partials/statement-card';
import { OrderCard } from './partials/order-card';
import { ConjunctionCard } from './partials/conjunctions-card';
import { CardsGrid } from '../../ui/layout/grid';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon } from '../../ui/icons';
import { ResultCircle } from '../../ui/layout/result-circle';
import {
  ITestNarrationOrderItem,
  ITestNarrationConjunctionItem,
  ITestNarrationStatementItem,
  TNarrationOrderGrades,
} from './types';
import { TId } from '@app/lib/type';
import { Warning } from '@app/ui/typography';

export interface ViewProps {
  done: boolean;
  statements: ITestNarrationStatementItem[];
  conjunctions: ITestNarrationConjunctionItem[];
  entered: string | false | null;
  order: ITestNarrationOrderItem | null;
  narrationGrade: number;
  onToggleStatement?: (id: TId, checked: boolean) => void;
  onEditedStatement: (id: TId, value: string | null) => void;
  onGradeOrder?: (id: TId, grading: TNarrationOrderGrades) => void;
  onEditedConjunction?: (id: TId, value: string | null) => void;
  onChangeConjunction?: (id: TId, frequency: number, checked: boolean) => void;
  onNext: () => void;
}

export const View: VFC<ViewProps> = ({
  done,
  onNext,
  entered,
  statements,
  onToggleStatement,
  onEditedStatement,
  onEditedConjunction,
  onChangeConjunction,
  narrationGrade,
  order,
  onGradeOrder,
  conjunctions,
}) => {
  return (
    <Page
      header={
        <Header
          title="Erzählen"
          right={<HelpButton title="Kriterien">Für den Auswertungsbogen 4 - Erzählen</HelpButton>}
        />
      }
    >
      <Section>
        <NarrationCard entered={entered} />
      </Section>
      <Section title="Welche Inhalte/Szenen werden vom Kind in die Erzählung einbezogen?">
        <CardsGrid variant="mini" stretchItems={true} heightenItems={true}>
          {statements.map((s) => {
            return (
              <StatementCard
                key={s.id}
                {...s}
                onChange={(checked) => onToggleStatement && onToggleStatement(s.id, checked)}
                onEntered={(v) => onEditedStatement && onEditedStatement(s.id, v)}
              />
            );
          })}
        </CardsGrid>
      </Section>
      <Section variant="done">
        <ResultCircle title="Punkte gesamt" grade={narrationGrade} />
        <NarrationCard entered={entered} />
      </Section>

      {order && (
        <Section title="Werden die Aussagen in der richtigen Reihenfolge wiedergegeben?">
          <OrderCard
            orderGrade={order.orderGrade}
            onGradeOrder={(points) => onGradeOrder && onGradeOrder(order.id, points)}
          />
        </Section>
      )}
      <Section title="Wie verknüpft das Kind die Sätze?">
        <CardsGrid variant="compact">
          {conjunctions.map((s) => {
            return (
              <ConjunctionCard
                key={s.id}
                {...s}
                onEditLabel={(value) => onEditedConjunction && onEditedConjunction(s.id, value)}
                onChange={(frequency, checked) => onChangeConjunction && onChangeConjunction(s.id, frequency, checked)}
              />
            );
          })}
        </CardsGrid>
      </Section>
      <Section variant="done">
        {!done && <Warning>Bitte füllen Sie alles aus!</Warning>}
        <Button variant="primary" onClick={onNext} disabled={!done}>
          <ButtonNextIcon />
          WEITER
        </Button>
      </Section>
    </Page>
  );
};
