import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';
import { Button } from '../../ui/buttons/button';
import { ButtonDropdownSmallIcon } from '../../ui/icons';

const StyledCardHeader = styled.header`
  position: relative;
  margin: -1rem -1rem 1rem -1rem;
  border-radius: 1.5rem;
  min-height: 3.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const StyledCardHeaderImage = styled.section`
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  margin-right: 1rem;
  width: 13.8rem;
  height: 13.8rem;
  overflow: hidden;
  border-radius: 100%;
  border: 0.4rem solid #979797;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const StyledCardHeaderSpace = styled.header`
  flex: 1;
  border-radius: 1.5rem 1.5rem 0 0;
  padding: 1rem 1rem;
  overflow: hidden;
`;

const StyledCard = styled.section<{ variant: 'default' | 'success' }>`
  position: relative;
  background: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  font-family: Raleway-SemiBold;
  font-size: 1.616rem;
  color: #009ee3;
  letter-spacing: 0.015rem;
  line-height: 2.4rem;
  border-radius: 1.5rem;
  font-family: Raleway-Medium;
  font-size: 1.8rem;
  color: #666666;
  padding: 1rem 2rem 0 2rem;
  min-height: 13rem;
  min-width: 13rem;
  &:focus-within > header > ${StyledCardHeaderImage} {
    border: 0.4rem solid #009ee3;
  }
  ${({ variant }) =>
    variant === 'success' &&
    `
  background-color: #e0f0f7;
  & > * {
    background-color: #e0f0f7;
  }
  `}
`;

export interface CardProps {
  variant?: 'default' | 'success';
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

export const Card: VFC<CardProps> = ({ variant = 'default', className, children, onClick }) => {
  return (
    <StyledCard className={className} onClick={onClick} variant={variant}>
      {children}
    </StyledCard>
  );
};

export const CardMemo = memo(Card);

export const VirtualCard = styled(Card)<{ isSelected: boolean }>`
  content-visibility: auto;
  contain-intrinsic-height: 135px;
  contain-intrinsic-width: 13rem;
  height: 135px;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => (props.isSelected ? '#f8b477' : 'transparent')};
  cursor: pointer;
  &:hover {
    border-color: #009ee3;
  }
  &:active {
    background-color: ${(props) => (props.variant === 'success' ? '#e0f0f7' : '#fefefe')};
  }
`;

export const FlexibleCard = styled(Card)`
  min-height: unset;
`;

export interface CardHeaderProps {
  img?: ReactNode;
  children?: ReactNode;
}

export const CardHeader: VFC<CardHeaderProps> = ({ img, children }) => {
  return (
    <StyledCardHeader>
      {img && <StyledCardHeaderImage>{img}</StyledCardHeaderImage>}
      <StyledCardHeaderSpace>{children}</StyledCardHeaderSpace>
    </StyledCardHeader>
  );
};

export const CardHeaderMemo = memo(CardHeader);

export interface CardTopperProps {
  className?: string;
  left?: ReactNode;
  middle?: ReactNode;
  right?: ReactNode;
}

const StyledCardTopper = styled.section`
  display: grid;
  grid-template-columns: 6rem 1fr 11rem;
  column-gap: 1rem;
  font-family: Raleway;
  font-weight: 600;
  font-size: 1.112rem;
  color: #f8b477;
  letter-spacing: 0.015rem;
  line-height: 1.5;
  border-bottom: 0.5px solid #979797;
  place-items: center center;
  & > *:first-child {
    place-self: center start;
  }
  & > *:last-child {
    place-self: center end;
  }
  margin-bottom: 1.5rem;
`;

export const CardTopper: VFC<CardTopperProps> = ({ className, left, middle, right }) => {
  return (
    <StyledCardTopper className={className}>
      <div>{left}</div>
      <div>{middle}</div>
      <div>{right}</div>
    </StyledCardTopper>
  );
};

export interface CardHeaderContentProps {
  className?: string;
  variant?: 'default' | 'compact';
  label?: string;
  children?: ReactNode;
  hint?: string;
  aside?: ReactNode;
}

const StyledCardHeaderContent = styled.header<{ variant?: 'default' | 'compact' }>`
  font-family: Raleway;
  font-weight: ${({ variant }) => (variant === 'compact' ? 500 : 600)};
  font-size: ${({ variant }) => (variant === 'compact' ? '1.4rem' : '1.616rem')};
  color: #009ee3;
  letter-spacing: ${({ variant }) => (variant === 'compact' ? '0.005rem' : '0.015rem')};
  line-height: 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & > aside {
    align-self: flex-start;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: 1rem;
  }

  & > header > *:first-child {
    font-family: Raleway;
    font-weight: 600;
    font-size: 1.212rem;
    color: #212121;
    letter-spacing: 0.12rem;
    line-height: 2.4rem;
  }

  & > header > *:last-child {
    font-family: Raleway;
    font-weight: 300;
    font-size: 1.212rem;
    color: #212121;
    letter-spacing: 0.02rem;
    line-height: 2.4rem;
  }
`;

export const CardHeaderContent: VFC<CardHeaderContentProps> = ({
  className,
  variant = 'default',
  label,
  children,
  hint,
  aside,
}) => {
  return (
    <StyledCardHeaderContent variant={variant} className={className}>
      <header>
        <div>{label}</div>
        <div>{children}</div>
        <div dangerouslySetInnerHTML={hint ? { __html: hint } : undefined} />
      </header>
      {variant !== 'compact' && <aside>{aside}</aside>}
    </StyledCardHeaderContent>
  );
};

const StyledCardBodySection = styled.section`
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.1rem;
  color: #212121;
  letter-spacing: 0.034rem;
  text-align: left;
  line-height: 1.7rem;
  margin-bottom: 1rem;

  ${StyledCardHeader} + & {
    margin-top: -1rem;
    border-top: 1px solid #979797;
  }
  & + & {
    border-top: 1px solid #979797;
  }
`;

const StyledCardBodySectionTitle = styled.p`
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.619rem;
  color: #212121;
  letter-spacing: 0.05rem;
  text-align: left;
  line-height: 2.8rem;
  margin: 0.5rem 0;
`;
const StyledCardBodySectionSubtitle = styled.p`
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.619rem;
  color: #c01616;
  letter-spacing: 0.005rem;
  text-align: left;
  line-height: 2.8rem;
  margin: 0.5rem 0;
`;

export interface CardBodySectionProps {
  className?: string;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

export const CardBodySection: VFC<CardBodySectionProps> = ({ className, title, subtitle, children }) => {
  return (
    <StyledCardBodySection className={className}>
      {title && <StyledCardBodySectionTitle>{title}</StyledCardBodySectionTitle>}
      {subtitle && <StyledCardBodySectionSubtitle>{subtitle}</StyledCardBodySectionSubtitle>}
      {children}
    </StyledCardBodySection>
  );
};

const StyledCardExpander = styled.section`
  position: absolute;
  bottom: 0.5rem;
  background: transparent;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  ${StyledCardHeader} + & {
    margin-top: -1rem;
  }
`;

export interface CardExpanderProps {
  className?: string;
  onExpand: () => void;
}

export const CardExpander: VFC<CardExpanderProps> = ({ className, onExpand }) => {
  return (
    <StyledCardExpander className={className}>
      <Button variant="text-small" onClick={onExpand}>
        <ButtonDropdownSmallIcon />
      </Button>
    </StyledCardExpander>
  );
};
