import { createReducer } from '@reduxjs/toolkit';
import { EmptyINormalized, INormalized, IGrouped, EmptyIGrouped, buildGroup, TId } from '@app/lib/type';
import { feature, jobs, actions } from './config';
import { createDataSuccessToNormalizedReducer } from '@app/lib/reducer';
import {
  ITestImage,
  ITestItem,
  ITestSection,
  ITestLine,
  TTestSectionSpecifier,
  ITestLineResult,
  upsertTestLineResult,
} from '@app/state';

interface IState {
  featureInitialized?: boolean;
  testSection: INormalized<ITestSection>;
  testSectionMap: Partial<Record<TTestSectionSpecifier, ITestSection>>;
  testImage: INormalized<ITestImage>;
  testItem: INormalized<ITestItem>;
  testLine: IGrouped<ITestLine>;
  lineResult: Record<TId, ITestLineResult>;
}

export type TState = IState;

const initialState: TState = {
  testSection: EmptyINormalized(),
  testSectionMap: {},
  testImage: EmptyINormalized(),
  testItem: EmptyINormalized(),
  testLine: EmptyIGrouped(),
  lineResult: {},
};

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(feature.initAction, (state) => {
    state.featureInitialized = true;
  });
  builder.addCase(jobs.loadTestSettings.successAction, (state, data) => {
    createDataSuccessToNormalizedReducer('testImage', 'testImage')(state, data);
    createDataSuccessToNormalizedReducer('testSection', 'testSection')(state, data);
    createDataSuccessToNormalizedReducer('testItem', 'testItem')(state, data);
    createDataSuccessToNormalizedReducer('testLine', 'testLine')(state, data);
    buildGroup<ITestSection, ITestLine>('testSection', state.testSection, state.testLine, (tl) => tl.lineNumber);
    for (const section of Object.values(state.testSection.map)) state.testSectionMap[section.section] = section;
  });
  builder.addCase(actions.updateStatementGrading, (state, { payload: { testLineId, update } }) => {
    state.lineResult[testLineId] = upsertTestLineResult(testLineId, state.lineResult[testLineId], {
      other: false,
      correct: update.other ?? false,
      ...update,
    });
  });
  builder.addCase(actions.updateOrderGrading, (state, { payload: { testLineId, update } }) => {
    state.lineResult[testLineId] = upsertTestLineResult(testLineId, state.lineResult[testLineId], {
      points: update.points ?? 0,
      correct: (update.points ?? 0) > 0,
      ...update,
    });
  });
  builder.addCase(actions.updateConjunctionGrading, (state, { payload: { testLineId, update } }) => {
    const testLine = state.testLine.map[testLineId];
    const prevLineResult = state.lineResult[testLineId];
    state.lineResult[testLineId] = upsertTestLineResult(testLineId, prevLineResult, {
      other: false,
      correct:
        ((typeof update.frequency !== 'undefined' && !!update.frequency) || !!prevLineResult.frequency) &&
        (!testLine.custom || !!prevLineResult?.customObjective) &&
        update.other,
      ...update,
    });
  });
  builder.addCase(actions.updateCustomObjective, (state, { payload: { testLineId, customObjective } }) => {
    const prevLineResult = state.lineResult[testLineId];
    state.lineResult[testLineId] = upsertTestLineResult(testLineId, state.lineResult[testLineId], {
      customObjective,
      other: true,
      correct: !!customObjective && !!prevLineResult?.frequency,
    });
  });
});
