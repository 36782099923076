import { PayloadAction } from '@reduxjs/toolkit';
import { IWithId, IItemInitializer } from '../type';
import { createObjectArrayToNormalizedReducer } from './object-array-to-normalized';

export const createDataSuccessToNormalizedReducer = <T extends IWithId, TStateSlice, TItem extends T = T>(
  statekey: string,
  datapath: string,
  itemInitializer: IItemInitializer<T, TStateSlice, TItem> = () => Object()
) => createObjectArrayToNormalizedReducer<T, TStateSlice, TItem>(statekey, `payload.data.${datapath}`, itemInitializer);

export const createDataSuccessToTrackedReducer = <T extends IWithId, TStateSlice, TItem extends T = T>(
  statekey: string,
  datapath: string,
  itemInitializer: IItemInitializer<T, TStateSlice, TItem> = () => Object()
) => (state: TStateSlice, action: PayloadAction<any>) => {
  createDataSuccessToNormalizedReducer(statekey, datapath, itemInitializer)(state, action);
  state[statekey].created = [...state[statekey].ids];
  return state;
};
