import { actionToPath } from 'redux-first-router';
import { actions, canonicalRoutes } from './routes';

export const is404 = (locationReduxSlice) => {
  const { type } = locationReduxSlice;
  return type === actions.route404;
};

export const isRedirect = (locationReduxSlice) => {
  const { kind, pathname, search } = locationReduxSlice;
  if (kind === 'redirect') {
    return search ? `${pathname}?${search}` : pathname;
  } else {
    return false;
  }
};

export const getPrevRouteType = (state) => {
  return state.location.pref?.type;
};

export const getRouteType = (state) => {
  return (state.location && state.location.type) || 'index';
};

export const getRoutePathname = (state) => {
  return (state.location && state.location.pathname) || '/';
};

export const getCurrentRouteAction = (state) => {
  return state.location
    ? {
        type: state.location.type,
        payload: state.location.payload,
        kind: state.location.kind,
      }
    : null;
};

export const getCanonicalRoutePathname = (state) => {
  const action = getCurrentRouteAction(state);
  if (!action) return '/';

  return actionToPath(action, canonicalRoutes);
};

export const getQueryParams = (state) => {
  return (state.location && state.location.query) || {};
};

export const getQueryString = (state) => {
  return (state.location && state.location.search) || '';
};

export const getRoutePayload = (state) => {
  return (state.location && state.location.payload) || {};
};
