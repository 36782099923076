// import './App.css';
import { ApplyCss } from './css/apply-css';
import { ScreenController } from './features/routing';
import styled from 'styled-components/macro';

const StyledAppWindow = styled.div`
  margin-left: 8rem;
  margin-right: 4rem;
  width: calc(100% - 12rem);
  height: 100vh;
`;

const onContextMenu = (event) => {
  event.preventDefault && event.preventDefault();
  return false;
};

function App() {
  return (
    <ApplyCss>
      <StyledAppWindow className="fill-viewport" onContextMenu={onContextMenu}>
        <ScreenController />
      </StyledAppWindow>
    </ApplyCss>
  );
}

export default App;
