import { EmptyINormalizedWithSelection } from './../../lib/type/basic';
import { createReducer } from '@reduxjs/toolkit';
import {
  EmptyINormalized,
  INormalized,
  IGrouped,
  EmptyIGrouped,
  buildGroup,
  TId,
  INormalizedWithSelection,
} from '@app/lib/type';
import { feature, jobs, actions, testOrder } from './config';
import { createDataSuccessToNormalizedReducer } from '@app/lib/reducer';
import {
  ITestItem,
  ITestSection,
  ITestLine,
  TTestSectionSpecifier,
  ITestLineMask,
  IReviewStatement,
  upsertReviewLineResult,
  evaluateTestLineNGP,
  evaluateTestLineSvo,
  evaluateTestLineNominativ,
  IReviewLine,
  initReviewLineResult,
  initLineResult,
} from '@app/state';
import { TTestLineStatus } from './types';
import { createObjectArrayToNormalizedReducer } from '@app/lib/reducer/object-array-to-normalized';
import { sortBy } from 'lodash';

export const testEvaluator = {
  'Satzbau & Wortschatz': evaluateTestLineSvo,
  'Genus: NGP I': evaluateTestLineNGP,
  'Genus: Nominativ I': evaluateTestLineNominativ,
};

interface IState {
  featureInitialized?: boolean;
  testSection: INormalized<ITestSection>;
  testSectionMap: Partial<Record<TTestSectionSpecifier, ITestSection>>;
  testItem: INormalizedWithSelection<ITestItem>;
  testLine: IGrouped<ITestLine>;
  testLineMask: INormalized<ITestLineMask>;
  reviewStatement: INormalizedWithSelection<IReviewStatement>;
  reviewLines: INormalized<IReviewLine>;
  reviewLinesTouched: Record<TId, boolean>;
  lineStatus: Record<TId, TTestLineStatus>;
}

export type TState = IState;

const initialState: TState = {
  testSection: EmptyINormalized(),
  testSectionMap: {},
  testItem: EmptyINormalizedWithSelection(),
  testLine: EmptyIGrouped(),
  testLineMask: EmptyINormalized(),
  reviewStatement: EmptyINormalizedWithSelection(),
  reviewLines: EmptyINormalized(),
  reviewLinesTouched: {},
  lineStatus: {},
};

const getTestlineOrderNum = (state: TState, tl: ITestLine) =>
  testOrder[state.testSection.map[tl.testSection].test] * 10000 +
  state.testSection.map[tl.testSection].sectionNumber * 100 +
  tl.lineNumber;

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(feature.initAction, (state) => {
    state.featureInitialized = true;
  });
  builder.addCase(jobs.loadReviewStatements.successAction, (state, data) => {
    createDataSuccessToNormalizedReducer('testSection', 'testSection')(state, data);
    createDataSuccessToNormalizedReducer('testItem', 'testItem')(state, data);
    createDataSuccessToNormalizedReducer('testLine', 'testLine')(state, data);
    createDataSuccessToNormalizedReducer('testLineMask', 'testLineMask')(state, data);
    buildGroup<ITestItem, ITestLine>('testItem', state.testItem, state.testLine, (tl) =>
      getTestlineOrderNum(state, tl)
    );
    buildGroup<ITestSection, ITestLine>('testSection', state.testSection, state.testLine, (tl) => tl.lineNumber);
    for (const section of Object.values(state.testSection.map)) state.testSectionMap[section.section] = section;
    createDataSuccessToNormalizedReducer('reviewStatement', 'reviewStatement')(state, data);
    state.testItem.selected = state.testItem.selected ?? state.testItem.ids[0] ?? null;
  });
  builder.addCase(jobs.loadReviewLines.successAction, (state, data) => {
    const reviewer = null;
    createDataSuccessToNormalizedReducer('reviewLines', 'reviewLine')(state, data);
    state.reviewLines.ids = sortBy(state.reviewLines.ids, (rlId) =>
      getTestlineOrderNum(state, state.testLine.map[state.reviewLines.map[rlId].testLine])
    );
    state.reviewLinesTouched = {};
    if (state.reviewLines.ids.length === 0 && state.reviewStatement.selected !== null) {
      // no review lines stored in database for this statement yet. create the lines with default values.
      const selectedStatement = state.reviewStatement.map[state.reviewStatement.selected];
      const item = state.testItem.map[selectedStatement.item];
      const testLines = state.testLine.groups.testItem[item.id].map((tlId) => state.testLine.map[tlId]);
      const reviewLines = testLines.map((tl) => initReviewLineResult(tl.id, selectedStatement.id, reviewer));
      createObjectArrayToNormalizedReducer('reviewLines', 'reviewLines')(state, { reviewLines });
      state.reviewLinesTouched = reviewLines.reduce<Record<string, boolean>>(
        (prev, rl) => ({ ...prev, [rl.id]: true }),
        {}
      );
    }
  });
  builder.addCase(actions.selectItem, (state, { payload: { testItemId } }) => {
    state.testItem.selected = testItemId;
  });
  builder.addCase(actions.selectStatement, (state, { payload: { statementId } }) => {
    if (state.reviewStatement.selected !== statementId) {
      state.reviewStatement.selected = statementId;
    } else {
      state.reviewStatement.selected = null;
      state.reviewLines = EmptyINormalized();
    }
  });
  builder.addCase(actions.updateGrading, (state, { payload: { reviewLineId, update } }) => {
    const test =
      state.testSection.map[state.testLine.map[state.reviewLines.map[reviewLineId].testLine].testSection].test;
    const evaluate = testEvaluator[test];
    state.reviewLines.map[reviewLineId] = evaluate(
      upsertReviewLineResult(
        state.reviewLines.map[reviewLineId].testLine,
        state.reviewLines.map[reviewLineId].statement,
        state.reviewLines.map[reviewLineId].reviewer,
        state.reviewLines.map[reviewLineId],
        {
          ...initLineResult(reviewLineId),
          ...update,
        }
      ),
      state.testLineMask.map[state.reviewLines.map[reviewLineId].testLine]
    );
    state.lineStatus[reviewLineId] = 'closed';
    state.reviewLinesTouched[reviewLineId] = true;
  });
  builder.addCase(actions.openGrading, (state, { payload: { reviewLineId } }) => {
    state.lineStatus[reviewLineId] = 'open';
  });
  builder.addCase(jobs.saveReviewLines.successAction, (state) => {
    if (!state.reviewStatement.selected) return;

    state.reviewStatement.map[state.reviewStatement.selected].reviewLineCount = state.reviewLines.ids.length;
    state.reviewStatement.selected = null;
    state.reviewLines = EmptyINormalized();
  });
});
