import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { selectors } from '../config';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => {
  const done = {
    pupilForm: selectors.pupilForm.getIsDone(state),
    enterStatements: selectors.enterStatements.getIsDone(state),
    svo: selectors.testSVO.getIsDone(state),
    ngp: selectors.testNGP.getIsDone(state),
    nominativ: selectors.testNominativ.getIsDone(state),
    narration: selectors.enterStatements.skipNarration(state) || selectors.testNarration.getIsDone(state),
  };
  return {
    formValues: selectors.pupilForm.getFormValues(state),
    grades: {
      svoGrade: selectors.testSVO.getGradeTotal(state),
      svoGradeColor: selectors.testSVO.getSupportColor(state, selectors.pupilForm.getInDaycare(state)),
      ngpAcquired: selectors.testNGP.getNGPAquired(state),
      ngpCorrectAnswers: selectors.testNGP.getNumberOfCorrectAnswers(state),
      ngpTotalAnswers: selectors.testNGP.getStatements(state, selectors.enterStatements.getEnteredByItem).length,
      nominativLevel: selectors.testNominativ.getNominativLevel(state),

      narrationGrade: selectors.testNarration.getNarrationTotal(state),
    },
    done,
    allDone: Object.values(done).every(Boolean),
    sent: selectors.resultsSummary.getSent(state),
    onNextChild: () => {
      alert('Goto next child...');
    },
    testMode: selectors.resultsSummary.getTestMode(state),
  };
};
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onSend: () => dispatch(actions.send()),
  onSwitchTestMode: (on: boolean) => dispatch(actions.switchTestMode(on)),
  onCreatePdf: () => dispatch(actions.createPdf()),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
