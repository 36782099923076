import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Input } from '../../../ui/form/input';

export const StyledCheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-top: 0.3rem;
  line-height: 1.42;
  user-select: none;
`;

export interface LabeledCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const LabeledCheckbox: VFC<LabeledCheckboxProps> = ({ label, checked, onChange }) => (
  <StyledCheckboxLabel>
    <Input type="checkbox" checked={checked} onChange={(e) => onChange(e.currentTarget.checked)} />
    <span>{label}</span>
  </StyledCheckboxLabel>
);
