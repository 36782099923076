import { IRootState, TEnteredByItem, TSupportColor } from '@app/state';
import { get } from 'lodash';
import { TState } from './state';
import { ITestSVOStatement } from './types';
import { TTestSectionSpecifier } from '@app/state';

const createStatementsGetter = (sectionSpecifier: TTestSectionSpecifier, getSlice: (state: IRootState) => TState) => {
  return (state: IRootState, getEnteredByItem: TEnteredByItem): ITestSVOStatement[] => {
    const slice = getSlice(state);
    const section = slice.testSectionMap[sectionSpecifier];
    if (!section || !slice.testLine.groups.testSection || !slice.testLine.groups.testSection[section.id]) return [];
    return slice.testLine.groups.testSection[section.id].map((lineId) => {
      const line = slice.testLine.map[lineId];
      const item = slice.testItem.map[line.testItem];
      const lineResult = slice.lineResult[lineId];
      const lineStatus = slice.lineStatus[lineId] ?? 'open';
      const gradingProposal = slice.gradingProposal.map[lineId];
      return {
        id: line.id,
        label: `Item ${item.itemNumber}`,
        statement: item.statement,
        text: getEnteredByItem(state, line.testItem) || 'Keine Äußerung',
        grading: lineResult?.points ?? null,
        status: lineStatus,
        proposal: !gradingProposal? undefined : {
          points: gradingProposal.points,
          basedOn: gradingProposal.proposedBy
        },
      };
    });
  };
};

const getGradeTotal = (state: IRootState, getSlice: (state: IRootState) => TState) => {
  return Object.values(getSlice(state).lineResult).reduce((p, line) => p + line.points, 0);
};

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  return {
    getLineResults: (state: IRootState) => Object.values(getSlice(state).lineResult),
    getStatementsSVO: createStatementsGetter('S-V-O', getSlice),
    getStatementsSVOP: createStatementsGetter('S-V-O-PARTIKEL', getSlice),
    getStatementsSHOV: createStatementsGetter('S-HILFSVERB-O-VOLLVERB', getSlice),
    getIsDone: (state: IRootState) => {
      const slice = getSlice(state);
      return !!slice.featureInitialized && Object.entries(slice.lineResult).length === slice.testLine.ids.length;
    },
    getGradeTotal: (state: IRootState) => getGradeTotal(state, getSlice),
    getSupportColor: (
      state: IRootState,
      daycare: boolean = false,
      articlesResultIIIorIV: boolean = false
    ): TSupportColor => {
      const points = getGradeTotal(state, getSlice);
      if (points <= 10) {
        return daycare ? 'purple' : 'blue';
      } else if (points <= 15) {
        return 'orange';
      } else {
        if (articlesResultIIIorIV) {
          return 'red';
        } else {
          return 'orange';
        }
      }
    },
  };
};
