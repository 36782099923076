import { VFC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon } from '../../ui/icons';

const Greeting = styled.div`
  font-family: Raleway;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: 0.032rem;
  line-height: 2.4rem;
`;

const CenteredSection = styled(Section)`
  text-align: center;
`;

export interface ViewProps {
  accountFullName?: string;
  onEnterEvaluation: () => void;
}

export const View: VFC<ViewProps> = ({ accountFullName, onEnterEvaluation }) => {
  return (
    <Page header={<Header title="Willkommen" />}>
      <CenteredSection>
        <Greeting>{accountFullName}</Greeting>
      </CenteredSection>
      <CenteredSection>
        <Button variant="primary" onClick={onEnterEvaluation}>
          <ButtonNextIcon />
          Zur Sprachstandserfassung
        </Button>
      </CenteredSection>
    </Page>
  );
};
