import { genericTestLineIsDone, IRootState, TEnteredByItem } from '@app/state';
import { get } from 'lodash';
import { TState } from './state';
import { ITestNGPStatement } from './types';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  const getNumberOfCorrectAnswers = (state: IRootState) => {
    const slice = getSlice(state);
    return Object.values(slice.lineResult).reduce((p, line) => (line.correct ? p + 1 : p), 0);
  };

  return {
    getLineResults: (state: IRootState) => Object.values(getSlice(state).lineResult),
    getStatements: (state: IRootState, getEnteredByItem: TEnteredByItem): ITestNGPStatement[] => {
      const sectionSpecifier = 'Artikel';
      const slice = getSlice(state);
      const section = slice.testSectionMap[sectionSpecifier];
      if (!section || !slice.testLine.groups.testSection || !slice.testLine.groups.testSection[section.id]) return [];
      return slice.testLine.groups.testSection[section.id].map((lineId) => {
        const line = slice.testLine.map[lineId];
        const item = slice.testItem.map[line.testItem];
        const lineResult = slice.lineResult[lineId];
        const lineStatus = slice.lineStatus[lineId] ?? 'open';
        const gradingProposal = slice.gradingProposal.map[lineId];
        return {
          id: line.id,
          label: `Item ${item.itemNumber}`,
          statement: item.statement,
          text: getEnteredByItem(state, line.testItem) || 'Keine Äußerung',

          proposal: !gradingProposal? undefined : {
            femaleArticleR: gradingProposal.femaleArticleR,
            femaleArticleW: gradingProposal.femaleArticleW,
            maleArticleR: gradingProposal.maleArticleR,
            maleArticleW: gradingProposal.maleArticleW,
            other: gradingProposal.other,
            basedOn: gradingProposal.proposedBy
          },

          grading: {
            femaleArticleR: lineResult?.femaleArticleR ?? false,
            femaleArticleW: lineResult?.femaleArticleW ?? false,
            maleArticleR: lineResult?.maleArticleR ?? false,
            maleArticleW: lineResult?.maleArticleW ?? false,
            other: lineResult?.other ?? false,
          },
          status: lineStatus,

          correct: lineResult?.correct ?? null,
        };
      });
    },
    getIsDone: (state: IRootState) => {
      const slice = getSlice(state);
      return (
        !!slice.featureInitialized &&
        Object.entries(slice.lineResult).length === slice.testLine.ids.length &&
        Object.values(slice.lineResult).every((line) => genericTestLineIsDone(line))
      );
    },
    getNumberOfCorrectAnswers,
    getNGPAquired: (state: IRootState) => {
      return getNumberOfCorrectAnswers(state) >= 5;
    },
  };
};
