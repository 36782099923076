import { IRootState, TEnteredByItem } from '@app/state';
import { get } from 'lodash';
import { TState } from './state';
import { ITestNarrationStatementItem, ITestNarrationConjunctionItem, ITestNarrationOrderItem } from './types';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  const getConjunctions = (state: IRootState): ITestNarrationConjunctionItem[] => {
    const sectionSpecifier = 'Konjunktionen';
    const slice = getSlice(state);
    const section = slice.testSectionMap[sectionSpecifier];
    if (!section || !slice.testLine.groups.testSection || !slice.testLine.groups.testSection[section.id]) return [];
    return slice.testLine.groups.testSection[section.id].map((lineId) => {
      const line = slice.testLine.map[lineId];
      const item = slice.testItem.map[line.testItem];
      const lineResult = slice.lineResult[lineId];
      return {
        id: line.id,
        label: (!line.custom ? line.objective : lineResult?.customObjective) ?? '',
        statement: item.statement,
        text: null,
        readonlyLabel: !line.custom,

        grading: {
          frequency: lineResult?.frequency ?? 0,
          other: lineResult?.other ?? false,
        },

        correct: lineResult?.correct ?? false,
      };
    });
  };

  return {
    getLineResults: (state: IRootState) => Object.values(getSlice(state).lineResult),
    getStatements: (state: IRootState): ITestNarrationStatementItem[] => {
      const sectionSpecifier = 'Inhalte';
      const slice = getSlice(state);
      const section = slice.testSectionMap[sectionSpecifier];
      if (!section || !slice.testLine.groups.testSection || !slice.testLine.groups.testSection[section.id]) return [];
      let otherStatementCounter = 1;
      return slice.testLine.groups.testSection[section.id].map((lineId) => {
        const line = slice.testLine.map[lineId];
        const images = [
          !!line.testImage && slice.testImage.map[line.testImage],
          !!line.testImageAlt && slice.testImage.map[line.testImageAlt],
        ].filter(Boolean);
        const item = slice.testItem.map[line.testItem];
        const lineResult = slice.lineResult[lineId];
        return {
          id: line.id,
          label: !line.custom
            ? `Bild ${images.map((i) => i && i.imageNumber).join('/')}`
            : `${otherStatementCounter++}. Weitere Äußerung des Kindes`,
          statement: item.statement,
          text: (!line.custom ? line.objective : lineResult?.customObjective) ?? null,

          custom: line.custom,

          grading: {
            other: lineResult?.other ?? false,
          },

          correct: lineResult?.correct ?? false,
        };
      });
    },

    getOrderGrade: (state: IRootState): null | ITestNarrationOrderItem => {
      const sectionSpecifier = 'Reihenfolge';
      const slice = getSlice(state);
      const section = slice.testSectionMap[sectionSpecifier];
      if (
        !section ||
        !slice.testLine.groups.testSection ||
        !slice.testLine.groups.testSection[section.id] ||
        !slice.testLine.groups.testSection[section.id].length
      )
        return null;

      const lineId = slice.testLine.groups.testSection[section.id][0];
      const lineResult = slice.lineResult[lineId];

      return {
        id: lineId,
        orderGrade: lineResult?.points ?? null,
      };
    },

    getEntered: (state: IRootState, getEnteredByItem: TEnteredByItem): string | false | null => {
      const sectionSpecifier = 'Reihenfolge';
      const slice = getSlice(state);
      const section = slice.testSectionMap[sectionSpecifier];
      if (
        !section ||
        !slice.testLine.groups.testSection ||
        !slice.testLine.groups.testSection[section.id] ||
        !slice.testLine.groups.testSection[section.id].length ||
        !slice.testLine.map[slice.testLine.groups.testSection[section.id][0]].testItem
      )
        return null;

      return getEnteredByItem(state, slice.testLine.map[slice.testLine.groups.testSection[section.id][0]].testItem);
    },

    getConjunctions,

    getNarrationTotal: (state: IRootState): number => {
      const sectionSpecifier = 'Inhalte';
      const slice = getSlice(state);
      const section = slice.testSectionMap[sectionSpecifier];
      if (!section || !slice.testLine.groups.testSection || !slice.testLine.groups.testSection[section.id]) return 0;
      return slice.testLine.groups.testSection[section.id].reduce((p, lineId) => {
        const lineResult = slice.lineResult[lineId];
        return lineResult?.other ? p + 1 : p;
      }, 0);
    },

    getIsDone: (state: IRootState) => {
      const sectionSpecifier = 'Reihenfolge';
      const slice = getSlice(state);
      const section = slice.testSectionMap[sectionSpecifier];
      if (
        !section ||
        !slice.testLine.groups.testSection ||
        !slice.testLine.groups.testSection[section.id] ||
        !slice.testLine.groups.testSection[section.id].length
      )
        return false;

      const lineId = slice.testLine.groups.testSection[section.id][0];
      return !!slice.featureInitialized && !!slice.lineResult[lineId];
    },
  };
};
