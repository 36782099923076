import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '@app/state';
import { View } from './view';
import { actions } from './config';
import { selectors } from '../config';
import { TId } from '@app/lib/type';
import { ITestNGPStatementGrading } from './types';

export interface IControllerProps {
  route: string;
}
const mapStateToProps = (state: IRootState, props: IControllerProps) => ({
  statements: selectors.testNGP.getStatements(state, selectors.enterStatements.getEnteredByItem),
  done: selectors.testNGP.getIsDone(state),
  ngpAcquired: selectors.testNGP.getNGPAquired(state),
  ngpCorrectAnswers: selectors.testNGP.getNumberOfCorrectAnswers(state),
});
const mapDispatchToProps = (dispatch: Dispatch, props: IControllerProps) => ({
  onGrade: (testLineId: TId, update: Partial<ITestNGPStatementGrading>) =>
    dispatch(actions.updateGrading(testLineId, update)),
  onRegrade: (testLineId: TId) => dispatch(actions.openGrading(testLineId)),
  onNext: () => dispatch(actions.gotoTestNominativ()),
});

export const Controller = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IControllerProps,
  IRootState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
