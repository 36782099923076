import { ReactNode } from 'react';
import styled from 'styled-components/macro';

export interface TextProps {
  children?: ReactNode;
}

export const Paragraph = styled.p<TextProps>`
  margin: 0;
  font-family: Raleway;
`;

export const Footnote = styled(Paragraph)<TextProps>`
  font-weight: 300;
  font-size: 1.4rem;
  color: inherit;
`;

export const Summary = styled(Paragraph)<TextProps>`
  font-weight: 300;
  font-size: 1.9rem;
  color: inherit;
`;

export const Warning = styled.section<TextProps>`
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.9rem;
  letter-spacing: 0.032rem;
  line-height: 2.4rem;
  color: #c01616;
`;

export const Motivation = styled(Paragraph)<TextProps>`
  font-weight: 600;
  font-size: 3.416rem;
  color: #f8b477;
  letter-spacing: 0.032rem;
  line-height: 2.4rem;
`;

export const Success = styled(Paragraph)<TextProps>`
  font-weight: 600;
  font-size: 3.416rem;
  color: #2ba834;
  letter-spacing: 0.032rem;
  line-height: 2.4rem;
`;

export const SectionTitle = styled.h2`
  margin: 0;
  font-family: Raleway;
  font-weight: 600;
  font-size: 20.2px;
  color: #243382;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 2rem;
`;
