import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';

const StyledFieldset = styled.fieldset.attrs<{ invalid: boolean }, { invalid: boolean }>(({ invalid }) => ({
  invalid: invalid ?? false,
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  color: ${({ invalid }) => (invalid ? '#ff0000' : 'inherit')};
  padding: 0.35rem 0em 0.625rem;
`;

const StyledHint = styled.span`
  font-family: Raleway;
  font-weight: 400;
  font-size: 1.116rem;
  letter-spacing: 0.01rem;
  line-height: 2.4rem;
`;

const StyledLabelContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  font-family: Raleway;
  font-weight: 400;
  font-size: 1.42rem;
  color: inherit;
  letter-spacing: 0.025rem;
  line-height: 2rem;
  width: 100%;
  & > * {
    margin-bottom: 0.5rem;
    width: 100%;
  }
`;

const StyledLabel = styled.span`
  font-family: Raleway;
  font-weight: 500;
  /*font-size: 1.116rem;*/
  font-size: 1.212rem;
  letter-spacing: 0.08rem;
  line-height: 2.4rem;
  & sup,
  & sub {
    line-height: 0;
  }
`;

export interface FieldsetProps {
  className?: string;
  label?: ReactNode;
  children?: ReactNode;
  hint?: ReactNode;
  invalid?: ReactNode;
}

export const Fieldset: VFC<FieldsetProps> = ({ className, label, children, hint, invalid }) => {
  return (
    <StyledFieldset className={className} invalid={!!invalid}>
      <StyledLabelContainer>
        <StyledLabel>{label || '\u00a0'}</StyledLabel>
        {children}
      </StyledLabelContainer>
      <StyledHint>{invalid ?? hint}</StyledHint>
    </StyledFieldset>
  );
};

export const FieldsetMemo = memo(Fieldset);
