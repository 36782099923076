import { VFC, ReactNode, memo } from 'react';
import { Brand } from '../brand/brand';
import styled from 'styled-components/macro';

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  margin-bottom: 6rem;
  & > * {
    flex: 1 1 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  & > *:first-child,
  & > *:last-child {
    margin: 0;
  }
  & > *:first-child {
    align-items: flex-start;
    padding: 2rem 0 0 0;
  }
  & > *:last-child {
    align-items: flex-end;
    padding: 2rem 0 0 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > * {
      flex: 1;
    }
    & > *:first-child {
      display: none;
    }
  }
`;

export const StyledTitle = styled.h1`
  margin: 0;
  position: relative;
  font-family: Raleway;
  font-weight: 500;
  font-size: 2.429rem;
  color: #243382;
  text-align: center;
  &::after {
    content: ' ';
    position: absolute;
    left: 50%;
    bottom: -2rem;
    transform: translateX(-50%);
    width: 5.5rem;
    border-top: 1px solid #979797;
  }
`;

export const StyledSubtitle = styled.h2`
  margin: 0;
  position: relative;
  font-family: Raleway;
  font-weight: 600;
  font-size: 2rem;
  color: #243382;
  text-align: center;
  line-height: 3;
`;

export interface HeaderProps {
  title: string;
  left?: ReactNode;
  right?: ReactNode;
}

export const Header: VFC<HeaderProps> = ({ title, left, right }) => {
  return (
    <StyledHeader>
      <section>{left}</section>
      <header>
        <Brand />
        <StyledTitle>{title}</StyledTitle>
      </header>
      <section>{right}</section>
    </StyledHeader>
  );
};

export const HeaderMemo = memo(Header);
