import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as BrandSvg } from './brand.svg';

const StyledBrand = styled(BrandSvg)`
  max-width: 14.0rem;
`;

export interface BrandProps {
  children?: ReactNode;
}

export const Brand: VFC<BrandProps> = ({ children }) => {
  return <StyledBrand />;
};

export const BrandMemo = memo(Brand);
