import axios from 'axios';
import { isDate, isObject, isArray, forEach } from 'lodash';

const encode = (val) => {
  return encodeURIComponent(val).
    replace(/%3A/gi, ':').
    replace(/%24/g, '$').
    replace(/%2C/gi, ',').
    replace(/%20/g, '+').
    replace(/%5B/gi, '[').
    replace(/%5D/gi, ']');
}

/*
  The default axios v0.21.1 url serialization code copied from source
  with changed array behavior
*/
const paramsSerializer = (params) => { 
  var parts = [];

  forEach(params, function serialize(val, key) {
    if (val === null || typeof val === 'undefined') {
      return;
    }

    if (isArray(val)) {
      key = key; // + '[]'; default axios v0.21.1 encoding modified here to remove array brackets
    } else {
      val = [val];
    }

    forEach(val, function parseValue(v) {
      if (isDate(v)) {
        v = v.toISOString();
      } else if (isObject(v)) {
        v = JSON.stringify(v);
      }
      parts.push(encode(key) + '=' + encode(v));
    });
  });

  return parts.join('&');  
}

export const createAxios = (accessToken = undefined, language = undefined) => {
  let headers = {};
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  if (language) {
    headers['Accept-Language'] = language;
  }
  let config = {
    baseURL: (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_BASEURL) || '/api',
    timeout: 120000,
    headers,
    paramsSerializer
  };
  return axios.create(config);
};
