import { createReducer } from '@reduxjs/toolkit';
import { EmptyINormalized, INormalized, IGrouped, EmptyIGrouped, buildGroup, TId } from '@app/lib/type';
import { feature, jobs, actions, controlActions } from './config';
import { createDataSuccessToNormalizedReducer } from '@app/lib/reducer';
import { IChildFormData, initChildFormData } from '@app/state/types/model';
import { v4 as uuidv4 } from 'uuid';

interface IState {
  batchId: TId;
  childInfo: IChildFormData;
}

export type TState = IState;

const initialState: TState = {
  batchId: uuidv4(),
  childInfo: initChildFormData(),
};

export const reducer = createReducer<TState>(initialState, (builder) => {
  builder.addCase(controlActions.routing.resetApplication, (state) => {
    state.batchId = uuidv4();
  });
  builder.addCase(actions.pupilFormSubmitted, (state, { payload }: { payload: Partial<IChildFormData> }) => {
    let overwrite: Partial<IChildFormData> = {};
    if ('supportLevel' in payload) {
      overwrite.supportGame = null;
    }
    if ('diagnosis' in payload) {
      switch (payload.diagnosis) {
        case 'start':
          overwrite.classDesignation = null;
          overwrite.supportHoursPerWeek = null;
          overwrite.supportHoursMissed = null;
          overwrite.supportLevel = null;
          overwrite.supportGame = null;
          break;
        case 'end':
          overwrite.monthsOfContact = null;
          break;
        default:
          overwrite.nativeLanguage = null;
          overwrite.nativeLanguage2 = null;
          overwrite.dateOfBirth = null;
          overwrite.gender = null;
          overwrite.monthsOfContact = null;
          overwrite.supportHoursPerWeek = null;
          overwrite.supportHoursMissed = null;
          overwrite.supportLevel = null;
          overwrite.supportGame = null;
          overwrite.classLevel = null;
          overwrite.classDesignation = null;
      }
    }
    state.childInfo = {
      ...state.childInfo,
      ...payload,
      ...overwrite,
    };
  });
});
