import { IRootState } from '@app/state';
import { selectors } from '@app/features/config';
import { BigToBool, IEvent, compileEvent } from '@app/lib/type';
import { get } from 'lodash';
import { TState } from './state';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  return {
    getSent: (state: IRootState) => {
      const slice = getSlice(state);
      return slice.sent;
    },
    getTestMode: (state: IRootState) => {
      return getSlice(state).testMode;
    },
  };
};
