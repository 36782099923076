import { ReactNode } from 'react';
import styled from 'styled-components/macro';

export interface CommonTableProps {
  children?: ReactNode;
}

export interface CommonTableRowProps {
  children?: ReactNode;
  gridTemplateColumns: string;
}

export const Table = styled.div<CommonTableProps>`
  font-size: 1.8rem;
  font-family: Raleway;
  font-weight: 500;
  letter-spacing: 0.032rem;
  line-height: 2.4rem;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: minmax(5rem, auto);
  grid-auto-columns: 1fr;
  margin: 0 2rem;
`;

export const HeaderRow = styled.div<CommonTableRowProps>`
  color: #f8b477;
  display: grid;
  border-radius: 1.5rem;
  grid-template-columns: ${(args) => args.gridTemplateColumns};
  margin: 0.75rem 0;
`;

export const BodyRow = styled.div<CommonTableRowProps>`
  background: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  display: grid;
  border-radius: 1.5rem;
  grid-template-columns: ${(args) => args.gridTemplateColumns};
  margin: 0.75rem 0;
`;

export const Cell = styled.div<CommonTableProps>`
  display: flex;
  padding: 0.5rem 4.5rem 0.5rem 2rem;
  align-items: center;
`;

export const HeaderCell = styled(Cell)<CommonTableProps>``;

export const BodyCell = styled(Cell)<CommonTableProps>`
  & + & {
    border-left: 0.5px solid #979797;
  }
`;
