import { createReducer } from '@reduxjs/toolkit';
import { EmptyINormalized, INormalized, IGrouped, EmptyIGrouped, buildGroup, TId } from '@app/lib/type';
import { jobs, actions } from './config';
import { createDataSuccessToNormalizedReducer } from '@app/lib/reducer';

interface IState {}

export type TState = IState;

const initialState: TState = {};

export const reducer = createReducer<TState>(initialState, (builder) => {});
