import { TId } from '@app/lib/type';
import { ILineResult } from '@app/state/types';
import { createAction } from '@reduxjs/toolkit';
import { features, createJob } from '../config';
import { TTestLineStatus } from './types';
export { selectors } from '../config';

export const feature = features.statementsReview;

export const jobs = {
  loadReviewStatements: createJob.loadReviewStatements(),
  loadReviewLines: createJob.loadReviewLines(),
  saveReviewLines: createJob.saveReviewLines(),
};

export const testName = 'Review';

export const testOrder = {
  'Satzbau & Wortschatz': 1,
  'Genus: NGP I': 2,
  'Genus: Nominativ I': 3,
};

interface IStatusActionPayload {
  payload: {
    reviewLineId: TId;
    status: TTestLineStatus;
  };
}

export const actions = {
  selectItem: createAction('SELECT-ITEM@STATEMENTS-REVIEW', (testItemId: TId) => ({ payload: { testItemId } })),
  selectStatement: createAction('SELECT-STATEMENT@STATEMENTS-REVIEW', (statementId: TId) => ({
    payload: { statementId },
  })),
  updateGrading: createAction(
    'UPDATE-GRADING@STATEMENTS-REVIEW',
    (reviewLineId: TId, update: Partial<ILineResult>) => ({
      payload: { reviewLineId, update },
    })
  ),
  openGrading: createAction(
    'OPEN-GRADING@STATEMENTS-REVIEW',
    (reviewLineId: TId): IStatusActionPayload => ({
      payload: { reviewLineId, status: 'open' },
    })
  ),
  confirmReview: createAction('CONFIRM-REVIEW@STATEMENTS-REVIEW', (statementId: TId) => ({
    payload: { statementId },
  })),
};
