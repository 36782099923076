export const languageCodes = [
  ['aar', 'aar', 'Afar'],
  ['abk', 'abk', 'Abchasisch'],
  ['ace', 'ace', 'Achinesisch'],
  ['ach', 'ach', 'Acholi'],
  ['ada', 'ada', 'Dangme'],
  ['ady', 'ady', 'Adygeisch'],
  ['afa', '', 'Afroasiatische Sprachen'],
  ['afh', 'afh', 'Afrihili'],
  ['afr', 'afr', 'Afrikaans'],
  ['ain', 'ain', 'Ainu'],
  ['aka', 'aka', 'Akan'],
  ['akk', 'akk', 'Akkadisch'],
  ['alb', 'sqi', 'Albanisch'],
  ['ale', 'ale', 'Aleutisch'],
  ['alg', '', 'Algonkin-Sprachen'],
  ['alt', 'alt', 'Südaltaisch'],
  ['amh', 'amh', 'Amharisch'],
  ['ang', 'ang', 'Altenglisch'],
  ['anp', 'anp', 'Angika'],
  ['apa', '', 'Apache-Sprachen'],
  ['ara', 'ara', 'Arabisch'],
  ['arc', 'arc', 'Reichsaramäisch'],
  ['arg', 'arg', 'Aragonesisch'],
  ['arm', 'hye', 'Armenisch'],
  ['arn', 'arn', 'Mapudungun'],
  ['arp', 'arp', 'Arapaho'],
  ['art', '', 'Konstruierte Sprachen'],
  ['arw', 'arw', 'Arawak'],
  ['asm', 'asm', 'Assamesisch'],
  ['ast', 'ast', 'Asturisch'],
  ['ath', '', 'Athapaskische Sprachen'],
  ['aus', '', 'Australische Sprachen'],
  ['ava', 'ava', 'Awarisch'],
  ['ave', 'ave', 'Avestisch'],
  ['awa', 'awa', 'Awadhi'],
  ['aym', 'aym', 'Aymara'],
  ['aze', 'aze', 'Aserbaidschanisch'],
  ['bad', '', 'Banda-Sprachen'],
  ['bai', '', 'Bamileke-Sprachen'],
  ['bak', 'bak', 'Baschkirisch'],
  ['bal', 'bal', 'Belutschisch'],
  ['bam', 'bam', 'Bambara'],
  ['ban', 'ban', 'Balinesisch'],
  ['bas', 'bas', 'Bassa'],
  ['bat', '', 'Baltische Sprachen'],
  ['bej', 'bej', 'Bedscha'],
  ['bel', 'bel', 'Weißrussisch'],
  ['bem', 'bem', 'Bemba'],
  ['ben', 'ben', 'Bengalisch'],
  ['ber', '', 'Berbersprachen'],
  ['bho', 'bho', 'Bhojpuri'],
  ['bih', '', 'Bihari'],
  ['bik', 'bik', 'Bikolano'],
  ['bin', 'bin', 'Edo'],
  ['bis', 'bis', 'Bislama'],
  ['bla', 'bla', 'Blackfoot'],
  ['bnt', '', 'Bantusprachen'],
  ['tib', 'bod', 'Tibetisch'],
  ['bos', 'bos', 'Bosnisch'],
  ['bra', 'bra', 'Braj-Bhakha'],
  ['bre', 'bre', 'Bretonisch'],
  ['btk', '', 'Bataksprachen'],
  ['bua', 'bua', 'Burjatisch'],
  ['bug', 'bug', 'Buginesisch'],
  ['bul', 'bul', 'Bulgarisch'],
  ['byn', 'byn', 'Blin'],
  ['cad', 'cad', 'Caddo'],
  ['cai', '', 'Mesoamerikanische Sprachen'],
  ['car', 'car', 'Karib'],
  ['cat', 'cat', 'Katalanisch, Valencianisch'],
  ['cau', '', 'Kaukasische Sprachen'],
  ['ceb', 'ceb', 'Cebuano'],
  ['cel', '', 'Keltische Sprachen'],
  ['cha', 'cha', 'Chamorro'],
  ['chb', 'chb', 'Chibcha'],
  ['che', 'che', 'Tschetschenisch'],
  ['chg', 'chg', 'Tschagataisch'],
  ['chk', 'chk', 'Chuukesisch'],
  ['chm', 'chm', 'Mari'],
  ['chn', 'chn', 'Chinook Wawa'],
  ['cho', 'cho', 'Choctaw'],
  ['chp', 'chp', 'Chipewyan'],
  ['chr', 'chr', 'Cherokee'],
  ['chu', 'chu', 'Kirchenslawisch, Altkirchenslawisch'],
  ['chv', 'chv', 'Tschuwaschisch'],
  ['chy', 'chy', 'Cheyenne'],
  ['cmc', '', 'Chamische Sprachen'],
  ['cnr', 'cnr', 'Montenegrinisch'],
  ['cop', 'cop', 'Koptisch'],
  ['cor', 'cor', 'Kornisch'],
  ['cos', 'cos', 'Korsisch'],
  ['cpe', '', 'Englisch-basierte Kreols und Pidgins'],
  ['cpf', '', 'Französisch-basierte Kreols und Pidgins'],
  ['cpp', '', 'Portugiesisch-basierte Kreols und Pidgins'],
  ['cre', 'cre', 'Cree'],
  ['crh', 'crh', 'Krimtatarisch'],
  ['crp', '', 'Kreol- und Pidginsprachen'],
  ['csb', 'csb', 'Kaschubisch'],
  ['cus', '', 'Kuschitische Sprachen'],
  ['wel', 'cym', 'Walisisch'],
  ['cze', 'ces', 'Tschechisch'],
  ['dak', 'dak', 'Dakota'],
  ['dan', 'dan', 'Dänisch'],
  ['dar', 'dar', 'Darginisch'],
  ['day', '', 'Land-Dayak-Sprachen'],
  ['del', 'del', 'Delawarisch'],
  ['den', 'den', 'Slavey'],
  ['ger', 'deu', 'Deutsch'],
  ['dgr', 'dgr', 'Dogrib'],
  ['din', 'din', 'Dinka'],
  ['div', 'div', 'Dhivehi'],
  ['doi', 'doi', 'Dogri'],
  ['dra', '', 'Dravidische Sprachen'],
  ['dsb', 'dsb', 'Niedersorbisch'],
  ['dua', 'dua', 'Duala'],
  ['dum', 'dum', 'Mittelniederländisch'],
  ['dut', 'nld', 'Niederländisch, Belgisches Niederländisch'],
  ['dyu', 'dyu', 'Dioula'],
  ['dzo', 'dzo', 'Dzongkha'],
  ['efi', 'efi', 'Efik'],
  ['egy', 'egy', 'Ägyptisch'],
  ['eka', 'eka', 'Ekajuk'],
  ['gre', 'ell', 'Griechisch'],
  ['elx', 'elx', 'Elamisch'],
  ['eng', 'eng', 'Englisch'],
  ['enm', 'enm', 'Mittelenglisch'],
  ['epo', 'epo', 'Esperanto'],
  ['est', 'est', 'Estnisch'],
  ['baq', 'eus', 'Baskisch'],
  ['ewe', 'ewe', 'Ewe'],
  ['ewo', 'ewo', 'Ewondo'],
  ['fan', 'fan', 'Fang'],
  ['fao', 'fao', 'Färöisch'],
  ['fat', 'fat', 'Fante'],
  ['fij', 'fij', 'Fidschi'],
  ['fil', 'fil', 'Filipino'],
  ['fin', 'fin', 'Finnisch'],
  ['fiu', '', 'Finno-ugrische Sprachen'],
  ['fon', 'fon', 'Fon'],
  ['fre', 'fra', 'Französisch'],
  ['frm', 'frm', 'Mittelfranzösisch'],
  ['fro', 'fro', 'Altfranzösisch'],
  ['frr', 'frr', 'Nordfriesisch'],
  ['frs', 'frs', 'Ostfriesisch'],
  ['fry', 'fry', 'Westfriesisch'],
  ['ful', 'ful', 'Fulfulde'],
  ['fur', 'fur', 'Furlanisch'],
  ['gaa', 'gaa', 'Ga'],
  ['gay', 'gay', 'Gayo'],
  ['gba', 'gba', 'Gbaya-Sprachen'],
  ['gem', '', 'Germanische Sprachen'],
  ['geo', 'kat', 'Georgisch'],
  ['gez', 'gez', 'Altäthiopisch'],
  ['gil', 'gil', 'Kiribatisch, Gilbertesisch'],
  ['gla', 'gla', 'Schottisch-gälisch'],
  ['gle', 'gle', 'Irisch'],
  ['glg', 'glg', 'Galicisch, Galegisch'],
  ['glv', 'glv', 'Manx, Manx-Gälisch'],
  ['gmh', 'gmh', 'Mittelhochdeutsch'],
  ['goh', 'goh', 'Althochdeutsch'],
  ['gon', 'gon', 'Gondi'],
  ['gor', 'gor', 'Gorontalo'],
  ['got', 'got', 'Gotisch'],
  ['grb', 'grb', 'Grebo'],
  ['grc', 'grc', 'Altgriechisch'],
  ['gre* / ell', 'ell', 'Griechisch'],
  ['grn', 'grn', 'Guaraní'],
  ['gsw', 'gsw', 'Schweizerdeutsch'],
  ['guj', 'guj', 'Gujarati'],
  ['gwi', 'gwi', "Gwich'in (Sprache)"],
  ['hai', 'hai', 'Haida'],
  ['hat', 'hat', 'Haitianisch'],
  ['hau', 'hau', 'Hausa'],
  ['haw', 'haw', 'Hawaiisch'],
  ['heb', 'heb', 'Hebräisch'],
  ['her', 'her', 'Otjiherero'],
  ['hil', 'hil', 'Hiligaynon'],
  ['him', '', 'West-Paharisprachen'],
  ['hin', 'hin', 'Hindi'],
  ['hit', 'hit', 'Hethitisch'],
  ['hmn', 'hmn', 'Hmong-Sprache'],
  ['hmo', 'hmo', 'Hiri Motu'],
  ['hrv', 'hrv', 'Kroatisch'],
  ['hsb', 'hsb', 'Obersorbisch'],
  ['hun', 'hun', 'Ungarisch'],
  ['hup', 'hup', 'Hoopa'],
  ['iba', 'iba', 'Iban'],
  ['ibo', 'ibo', 'Igbo'],
  ['ice', 'isl', 'Isländisch'],
  ['ido', 'ido', 'Ido'],
  ['iii', 'iii', 'Yi'],
  ['ijo', '', 'Ijo-Sprachen'],
  ['iku', 'iku', 'Inuktitut'],
  ['ile', 'ile', 'Interlingue'],
  ['ilo', 'ilo', 'Ilokano'],
  ['ina', 'ina', 'Interlingua'],
  ['inc', '', 'Indoarische Sprachen'],
  ['ind', 'ind', 'Indonesisch'],
  ['ine', '', 'Indogermanische Sprachen'],
  ['inh', 'inh', 'Inguschisch'],
  ['ipk', 'ipk', 'Inupiaq'],
  ['ira', '', 'Iranische Sprachen'],
  ['iro', '', 'Irokesische Sprachen'],
  ['ita', 'ita', 'Italienisch'],
  ['jav', 'jav', 'Javanisch'],
  ['jbo', 'jbo', 'Lojban'],
  ['jpn', 'jpn', 'Japanisch'],
  ['jpr', 'jpr', 'Judäo-Persisch'],
  ['jrb', 'jrb', 'Judäo-Arabisch'],
  ['kaa', 'kaa', 'Karakalpakisch'],
  ['kab', 'kab', 'Kabylisch'],
  ['kac', 'kac', 'Jingpo'],
  ['kal', 'kal', 'Grönländisch, Kalaallisut'],
  ['kam', 'kam', 'Kikamba'],
  ['kan', 'kan', 'Kannada'],
  ['kar', '', 'Karenische Sprachen'],
  ['kas', 'kas', 'Kashmiri'],
  ['kau', 'kau', 'Kanuri'],
  ['kaw', 'kaw', 'Kawi, Altjavanisch'],
  ['kaz', 'kaz', 'Kasachisch'],
  ['kbd', 'kbd', 'Kabardinisch, Ost-Tscherkessisch'],
  ['kha', 'kha', 'Khasi'],
  ['khi', '', 'Khoisansprachen'],
  ['khm', 'khm', 'Khmer'],
  ['kho', 'kho', 'Khotanesisch'],
  ['kik', 'kik', 'Kikuyu'],
  ['kin', 'kin', 'Kinyarwanda, Ruandisch'],
  ['kir', 'kir', 'Kirgisisch'],
  ['kmb', 'kmb', 'Kimbundu'],
  ['kok', 'kok', 'Konkani'],
  ['kom', 'kom', 'Komi'],
  ['kon', 'kon', 'Kikongo'],
  ['kor', 'kor', 'Koreanisch'],
  ['kos', 'kos', 'Kosraeanisch'],
  ['kpe', 'kpe', 'Kpelle'],
  ['krc', 'krc', 'Karatschai-balkarisch'],
  ['krl', 'krl', 'Karelisch'],
  ['kro', '', 'Kru-Sprachen'],
  ['kru', 'kru', 'Kurukh'],
  ['kua', 'kua', 'oshiKwanyama'],
  ['kum', 'kum', 'Kumykisch'],
  ['kur', 'kur', 'Kurdisch'],
  ['kut', 'kut', 'Kutanaha'],
  ['lad', 'lad', 'Judenspanisch, Ladino, Sephardisch'],
  ['lah', 'lah', 'Lahnda, Westpanjabi'],
  ['lam', 'lam', 'Lamba'],
  ['lao', 'lao', 'Laotisch'],
  ['lat', 'lat', 'Latein'],
  ['lav', 'lav', 'Lettisch'],
  ['lez', 'lez', 'Lesgisch'],
  ['lim', 'lim', 'Limburgisch, Südniederfränkisch'],
  ['lin', 'lin', 'Lingála'],
  ['lit', 'lit', 'Litauisch'],
  ['lol', 'lol', 'Lomongo'],
  ['loz', 'loz', 'Lozi'],
  ['ltz', 'ltz', 'Luxemburgisch'],
  ['lua', 'lua', 'Tschiluba'],
  ['lub', 'lub', 'Kiluba'],
  ['lug', 'lug', 'Luganda'],
  ['lui', 'lui', 'Luiseño'],
  ['lun', 'lun', 'Chilunda'],
  ['luo', 'luo', 'Luo'],
  ['lus', 'lus', 'Mizo, Lushai'],
  ['mac', 'mkd', 'Mazedonisch'],
  ['mad', 'mad', 'Maduresisch'],
  ['mag', 'mag', 'Magadhi'],
  ['mah', 'mah', 'Marshallesisch'],
  ['mai', 'mai', 'Maithili'],
  ['mak', 'mak', 'Makassar'],
  ['mal', 'mal', 'Malayalam'],
  ['man', 'man', 'Manding'],
  ['mao', 'mri', 'Maori'],
  ['map', '', 'Austronesische Sprachen'],
  ['mar', 'mar', 'Marathi'],
  ['mas', 'mas', 'Maa, Kimaasai'],
  ['may', 'msa', 'Malaiisch'],
  ['mdf', 'mdf', 'Mokschanisch'],
  ['mdr', 'mdr', 'Mandar'],
  ['men', 'men', 'Mende'],
  ['mga', 'mga', 'Mittelirisch'],
  ['mic', 'mic', 'Míkmawísimk'],
  ['min', 'min', 'Minangkabauisch'],
  //['mis', 'mis', '„Unkodiert“'],
  ['mkh', '', 'Mon-Khmer-Sprachen'],
  ['mlg', 'mlg', 'Malagasy, Malagassi'],
  ['mlt', 'mlt', 'Maltesisch'],
  ['mnc', 'mnc', 'Mandschurisch'],
  ['mni', 'mni', 'Meitei'],
  ['mno', '', 'Manobo-Sprachen'],
  ['moh', 'moh', 'Mohawk'],
  ['mon', 'mon', 'Mongolisch'],
  ['mos', 'mos', 'Mòoré'],
  ['mun', '', 'Munda-Sprachen'],
  ['mus', 'mus', 'Muskogee-Sprachen'],
  ['mwl', 'mwl', 'Mirandés'],
  ['mwr', 'mwr', 'Marwari'],
  ['bur', 'mya', 'Birmanisch'],
  ['myn', '', 'Maya-Sprachen'],
  ['myv', 'myv', 'Ersjanisch, Ersja-Mordwinisch'],
  ['nah', '', 'Nahuatl'],
  ['nai', '', 'Nordamerikanische Sprachen'],
  ['nap', 'nap', 'Neapolitanisch'],
  ['nau', 'nau', 'Nauruisch'],
  ['nav', 'nav', 'Navajo'],
  ['nbl', 'nbl', 'Süd-Ndebele'],
  ['nde', 'nde', 'Nord-Ndebele'],
  ['ndo', 'ndo', 'Ndonga'],
  ['nds', 'nds', 'Niederdeutsch, Plattdeutsch'],
  ['nep', 'nep', 'Nepali'],
  ['new', 'new', 'Newari'],
  ['nia', 'nia', 'Nias'],
  ['nic', '', 'Niger-Kongo-Sprachen'],
  ['niu', 'niu', 'Niueanisch'],
  ['nno', 'nno', 'Nynorsk'],
  ['nob', 'nob', 'Bokmål'],
  ['nog', 'nog', 'Nogaisch'],
  ['non', 'non', 'Altnordisch'],
  ['nor', 'nor', 'Norwegisch'],
  ['nqo', 'nqo', 'N’Ko'],
  ['nso', 'nso', 'Nord-Sotho'],
  ['nub', '', 'Nubische Sprachen'],
  ['nwc', 'nwc', 'Klassisches Newari'],
  ['nya', 'nya', 'Chichewa'],
  ['nym', 'nym', 'Nyamwesi'],
  ['nyn', 'nyn', 'Runyankole, Runyankore'],
  ['nyo', 'nyo', 'Runyoro'],
  ['nzi', 'nzi', 'Nzema'],
  ['oci', 'oci', 'Okzitanisch'],
  ['oji', 'oji', 'Ojibwe'],
  ['ori', 'ori', 'Oriya'],
  ['orm', 'orm', 'Oromo'],
  ['osa', 'osa', 'Osage'],
  ['oss', 'oss', 'Ossetisch'],
  ['ota', 'ota', 'Osmanisch, osmanisches Türkisch'],
  ['oto', '', 'Oto-Pame-Sprachen'],
  ['paa', '', 'Papuasprachen'],
  ['pag', 'pag', 'Pangasinensisch'],
  ['pal', 'pal', 'Mittelpersisch, Pahlavi'],
  ['pam', 'pam', 'Kapampangan'],
  ['pan', 'pan', 'Panjabi, Pandschabi'],
  ['pap', 'pap', 'Papiamentu'],
  ['pau', 'pau', 'Palauisch'],
  ['peo', 'peo', 'Altpersisch'],
  ['per', 'fas', 'Persisch'],
  ['phi', '', 'Philippinische Sprachen'],
  ['phn', 'phn', 'Phönizisch, Punisch'],
  ['pli', 'pli', 'Pali'],
  ['pol', 'pol', 'Polnisch'],
  ['pon', 'pon', 'Pohnpeanisch'],
  ['por', 'por', 'Portugiesisch'],
  ['pra', '', 'Prakrit'],
  ['pro', 'pro', 'Altokzitanisch, Altprovenzalisch'],
  ['pus', 'pus', 'Paschtunisch'],
  ['qaa-qtz', 'qaa-qtz', 'Reserviert für lokale Nutzung'],
  ['que', 'que', 'Quechua'],
  ['raj', 'raj', 'Rajasthani'],
  ['rap', 'rap', 'Rapanui'],
  ['rar', 'rar', 'Rarotonganisch, Māori der Cookinseln'],
  ['roa', '', 'Romanische Sprachen'],
  ['roh', 'roh', 'Bündnerromanisch, Romanisch'],
  ['rom', 'rom', 'Romani, Romanes'],
  ['rum', 'ron', 'Rumänisch'],
  ['run', 'run', 'Kirundi'],
  ['rup', 'rup', 'Aromunisch, Makedoromanisch'],
  ['rus', 'rus', 'Russisch'],
  ['sad', 'sad', 'Sandawe'],
  ['sag', 'sag', 'Sango'],
  ['sah', 'sah', 'Jakutisch'],
  ['sai', '', 'Südamerikanische Sprachen'],
  ['sal', '', 'Salish-Sprachen'],
  ['sam', 'sam', 'Samaritanisch'],
  ['san', 'san', 'Sanskrit'],
  ['sas', 'sas', 'Sasak'],
  ['sat', 'sat', 'Santali'],
  ['scn', 'scn', 'Sizilianisch'],
  ['sco', 'sco', 'Scots'],
  ['sel', 'sel', 'Selkupisch'],
  ['sem', '', 'Semitische Sprachen'],
  ['sga', 'sga', 'Altirisch'],
  ['sgn', '', 'Gebärdensprache'],
  ['shn', 'shn', 'Shan'],
  ['sid', 'sid', 'Sidama'],
  ['sin', 'sin', 'Singhalesisch'],
  ['sio', '', 'Sioux-Sprachen'],
  ['sit', '', 'Sinotibetische Sprachen'],
  ['sla', '', 'Slawische Sprachen'],
  ['slo', 'slk', 'Slowakisch'],
  ['slv', 'slv', 'Slowenisch'],
  ['sma', 'sma', 'Südsamisch'],
  ['sme', 'sme', 'Nordsamisch'],
  ['smi', '', 'Samische Sprachen'],
  ['smj', 'smj', 'Lulesamisch'],
  ['smn', 'smn', 'Inarisamisch'],
  ['smo', 'smo', 'Samoanisch'],
  ['sms', 'sms', 'Skoltsamisch'],
  ['sna', 'sna', 'Shona'],
  ['snd', 'snd', 'Sindhi'],
  ['snk', 'snk', 'Soninke'],
  ['sog', 'sog', 'Sogdisch'],
  ['som', 'som', 'Somali'],
  ['son', '', 'Songhai-Sprachen'],
  ['sot', 'sot', 'Sesotho, Süd-Sotho'],
  ['spa', 'spa', 'Spanisch, Kastilisch'],
  ['srd', 'srd', 'Sardisch'],
  ['srn', 'srn', 'Sranantongo'],
  ['srp', 'srp', 'Serbisch'],
  ['srr', 'srr', 'Serer'],
  ['ssa', '', 'Nilosaharanische Sprachen'],
  ['ssw', 'ssw', 'Siswati'],
  ['suk', 'suk', 'Sukuma'],
  ['sun', 'sun', 'Sundanesisch'],
  ['sus', 'sus', 'Susu'],
  ['sux', 'sux', 'Sumerisch'],
  ['swa', 'swa', 'Swahili'],
  ['swe', 'swe', 'Schwedisch'],
  ['syc', 'syc', 'Syrisch'],
  ['syr', 'syr', 'Nordost-Neuaramäisch'],
  ['tah', 'tah', 'Tahitianisch, Tahitisch'],
  ['tai', '', 'Tai-Sprachen'],
  ['tam', 'tam', 'Tamil'],
  ['tat', 'tat', 'Tatarisch'],
  ['tel', 'tel', 'Telugu'],
  ['tem', 'tem', 'Temne'],
  ['ter', 'ter', 'Terena'],
  ['tet', 'tet', 'Tetum'],
  ['tgk', 'tgk', 'Tadschikisch'],
  ['tgl', 'tgl', 'Tagalog'],
  ['tha', 'tha', 'Thai'],
  ['tig', 'tig', 'Tigre'],
  ['tir', 'tir', 'Tigrinya'],
  ['tiv', 'tiv', 'Tiv'],
  ['tkl', 'tkl', 'Tokelauisch'],
  ['tlh', 'tlh', 'Klingonisch'],
  ['tli', 'tli', 'Tlingit'],
  ['tmh', 'tmh', 'Tuareg'],
  ['tog', 'tog', 'ChiTonga'],
  ['ton', 'ton', 'Tongaisch'],
  ['tpi', 'tpi', 'Tok Pisin, Neuguinea-Pidgin'],
  ['tsi', 'tsi', 'Tsimshian'],
  ['tsn', 'tsn', 'Setswana'],
  ['tso', 'tso', 'Xitsonga'],
  ['tuk', 'tuk', 'Turkmenisch'],
  ['tum', 'tum', 'Tumbuka'],
  ['tup', '', 'Tupí-Sprachen'],
  ['tur', 'tur', 'Türkisch'],
  ['tut', '', 'Altaische Sprachen'],
  ['tvl', 'tvl', 'Tuvaluisch'],
  ['twi', 'twi', 'Twi'],
  ['tyv', 'tyv', 'Tuwinisch'],
  ['udm', 'udm', 'Udmurtisch'],
  ['uga', 'uga', 'Ugaritisch'],
  ['uig', 'uig', 'Uigurisch'],
  ['ukr', 'ukr', 'Ukrainisch'],
  ['umb', 'umb', 'Umbundu'],
  //['und', 'und', '„Undefiniert“'],
  ['urd', 'urd', 'Urdu'],
  ['uzb', 'uzb', 'Usbekisch'],
  ['vai', 'vai', 'Vai'],
  ['ven', 'ven', 'Tshivenda'],
  ['vie', 'vie', 'Vietnamesisch'],
  ['vol', 'vol', 'Volapük'],
  ['vot', 'vot', 'Wotisch'],
  ['wak', '', 'Wakash-Sprachen'],
  ['wal', 'wal', 'Wolaytta'],
  ['war', 'war', 'Wáray-Wáray'],
  ['was', 'was', 'Washoe'],
  ['wen', '', 'Sorbische Sprache'],
  ['wln', 'wln', 'Wallonisch'],
  ['wol', 'wol', 'Wolof'],
  ['xal', 'xal', 'Kalmückisch'],
  ['xho', 'xho', 'isiXhosa'],
  ['yao', 'yao', 'Yao'],
  ['yap', 'yap', 'Yapesisch'],
  ['yid', 'yid', 'Jiddisch'],
  ['yor', 'yor', 'Yoruba'],
  ['ypk', '', 'Yupik-Sprachen'],
  ['zap', 'zap', 'Zapotekisch'],
  ['zbl', 'zbl', 'Bliss-Symbol'],
  ['zen', 'zen', 'Zenaga'],
  ['zgh', 'zgh', 'Marokkanisches Tamazight'],
  ['zha', 'zha', 'Zhuang'],
  ['chi', 'zho', 'Chinesisch'],
  ['znd', '', 'Zande-Sprachen'],
  ['zul', 'zul', 'isiZulu'],
  ['zun', 'zun', 'Zuñi'],
  ['zza', 'zza', 'Zazaisch'],
  //['mul', 'mul', '„Mehrsprachig“'],
  //['zxx', 'zxx', '„Kein sprachlicher Inhalt; Nicht anwendbar“'],
];

export type OptionsList = {
  value: string;
  label: string;
};

export const getSortedLanguageOptions = (): OptionsList[] => {
  return languageCodes.map((c) => ({ value: c[0], label: c[2] })).sort((a, b) => a.label.localeCompare(b.label));
};

export const isLanguageCodeValid = (code: string) => {
  return languageCodes.find((cc) => cc[0] === code || (cc[1] && cc[1] === code)) !== undefined;
};

export type LanguageInfo = {
  authoritative: string;
  iso639rev3: string;
  description: string;
};

/**
 *
 * @param code
 * @returns False if invalid. Three letter language code otherwise
 */
export const getAuthoritativeLanguageInfo = (code: string): LanguageInfo | false => {
  const entry = languageCodes.find((cc) => cc[0] === code || (cc[1] && cc[1] === code));
  if (entry !== undefined)
    return {
      authoritative: entry[0],
      iso639rev3: entry[1],
      description: entry[2],
    };
  else return false;
};

/**
 *
 * @param code
 * @returns False if invalid. Three letter language code otherwise
 */
export const getAuthoritativeLanguageCode = (code: string): string | false => {
  const entry = getAuthoritativeLanguageInfo(code);
  if (entry !== undefined) return entry[0];
  else return false;
};
