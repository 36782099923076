import { ReducersMapObject } from '@reduxjs/toolkit';
import { sagas as routingSagas } from './routing';
import { sagas as keycloakSagas } from './keycloak';
import { sagas as apiSagas } from './api';
import { sagas as pupilFormSagas } from './pupil-form';
import { sagas as enterStatementsSagas } from './enter-statements';
import { sagas as testSVOSagas } from './test-svo';
import { sagas as testNGPSagas } from './test-ngp';
import { sagas as testNominativSagas } from './test-nominativ';
import { sagas as testNarrationSagas } from './test-narration';
import { sagas as resultsSummarySagas } from './results-summary';
import { sagas as resultsPdfSagas } from './results-pdf';
import { sagas as completeEvaluationsSagas } from './complete-evaluations';
import { sagas as incompleteEvaluationsSagas } from './incomplete-evaluations';
import { sagas as homeSagas } from './home';
import { sagas as statementsReviewSagas } from './statements-review';

import { ILocationState, connectRouting } from '@app/features/routing';
import { reducer as keycloakReducer, TState as TKeycloakState } from './keycloak';
import { reducer as pupilFormReducer, TState as TPupilFormState } from './pupil-form';
import { reducer as enterStatementsReducer, TState as TEnterStatementsState } from './enter-statements';
import { reducer as testSvoReducer, TState as TTestSvoState } from './test-svo';
import { reducer as testNgpReducer, TState as TTestNgpState } from './test-ngp';
import { reducer as testNominativReducer, TState as TTestNominativState } from './test-nominativ';
import { reducer as testNarrationReducer, TState as TTestNarrationState } from './test-narration';
import { reducer as resultsSummaryReducer, TState as TResultsSummaryState } from './results-summary';
import { reducer as resultsPdfReducer, TState as TResultsPdfState } from './results-pdf';
import { reducer as statementsReviewReducer, TState as TStatementsReviewState } from './statements-review';

import { reducer as completeEvaluationsReducer, TState as TCompleteEvaluationsState } from './complete-evaluations';
import {
  reducer as incompleteEvaluationsReducer,
  TState as TIncompleteEvaluationsState,
} from './incomplete-evaluations';
import { reducer as homeReducer, TState as THomeState } from './home';

export { controlActions } from './routing/actions';

export const sagas = [
  ...routingSagas,
  ...keycloakSagas,
  ...apiSagas,
  ...pupilFormSagas,
  ...enterStatementsSagas,
  ...testSVOSagas,
  ...testNGPSagas,
  ...testNominativSagas,
  ...testNarrationSagas,
  ...resultsSummarySagas,
  ...resultsPdfSagas,
  ...completeEvaluationsSagas,
  ...incompleteEvaluationsSagas,
  ...homeSagas,
  ...statementsReviewSagas,
];

export interface IRootState {
  location: ILocationState;
  auth: TKeycloakState;
  pupilForm: TPupilFormState;
  enterStatements: TEnterStatementsState;
  testSVO: TTestSvoState;
  testNGP: TTestNgpState;
  testNominativ: TTestNominativState;
  testNarration: TTestNarrationState;
  resultsSummary: TResultsSummaryState;
  resultsPdf: TResultsPdfState;
  completeEvaluations: TCompleteEvaluationsState;
  incompleteEvaluations: TIncompleteEvaluationsState;
  home: THomeState;
  statementsReview: TStatementsReviewState;
}

export const routingConnector = connectRouting();

export const reducersMap: ReducersMapObject<IRootState> = {
  location: routingConnector.reducer,
  auth: keycloakReducer,
  pupilForm: pupilFormReducer,
  enterStatements: enterStatementsReducer,
  testSVO: testSvoReducer,
  testNGP: testNgpReducer,
  testNominativ: testNominativReducer,
  testNarration: testNarrationReducer,
  resultsSummary: resultsSummaryReducer,
  resultsPdf: resultsPdfReducer,
  completeEvaluations: completeEvaluationsReducer,
  incompleteEvaluations: incompleteEvaluationsReducer,
  home: homeReducer,
  statementsReview: statementsReviewReducer,
};
