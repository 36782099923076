import { VFC, ChangeEvent, useCallback } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header, StyledSubtitle } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { Fieldset } from '../../ui/form/fieldset';
import { Select } from '../../ui/form/select';
//import { ResultCard } from './partials/result-card';
import { CardsGrid } from '../../ui/layout/grid';
import { VirtualCard, FlexibleCard, CardHeader, CardTopper, CardHeaderContent } from 'src/ui/layout/card';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon, ButtonRestartIcon } from '../../ui/icons';
import { Motivation, Warning } from '../../ui/typography';
import { ResultCircle } from '../../ui/layout/result-circle';
import { IReviewStatementSelection, ITestItemSelection, IReviewLineForGrading } from './types';
import { TId } from '@app/lib/type';
import { Drawer } from '@app/ui/layout/drawer';
import { SvoCard, ITestSVOStatement } from './partials/svo-card';
import { NominativCard, ITestNominativStatement, ITestNominativStatementGrading } from './partials/nominativ-card';
import { NgpCard, ITestNGPStatement, ITestNGPStatementGrading } from './partials/ngp-card';
import { ILineResult } from '@app/state/types';

const StyledTestPage = styled(Page)``;

const StyledSvoCard = styled(SvoCard)`
  .svo {
    font-weight: bold;
  }
`;
const StyledNgpCard = styled(NgpCard)`
  .ngp {
    font-weight: bold;
  }
`;
const StyledNominativCard = styled(NominativCard)`
  .nominativ {
    font-weight: bold;
  }
`;

const ReviewDrawer = styled(Drawer).attrs({
  size: '100rem',
  direction: 'right',
  customIdSuffix: 'review-drawer',
})`
  max-width: 80vw;
  padding: 2rem 5rem;
  overflow-y: scroll;
`;

export interface ViewProps {
  onSelectItem: (testItemId: TId) => void;
  onSelectStatement: (statementId: TId) => void;
  onGrade: (reviewLineId: TId, update: Partial<ILineResult>) => void;
  onRegrade: (reviewLineId: TId) => void;
  onConfirmReview: (statementId: TId) => void;
  itemSelection: ITestItemSelection;
  statementSelection: IReviewStatementSelection;
  reviewLines: IReviewLineForGrading[];
  reviewDone: boolean;
}

export const View: VFC<ViewProps> = ({
  statementSelection,
  itemSelection,
  onSelectItem,
  onSelectStatement,
  onGrade,
  onRegrade,
  onConfirmReview,
  reviewLines = [],
  reviewDone = false,
}) => {
  const onChangeItem = useCallback(
    (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      onSelectItem(evt.currentTarget.value);
    },
    [onSelectItem]
  );

  return (
    <StyledTestPage
      header={
        <Header
          title="Satzproben bewerten"
          left={
            <Section>
              <Fieldset label="Item wählen">
                <Select name="testItem" value={itemSelection.selected?.id} onChange={onChangeItem}>
                  {itemSelection.items.map((item) => (
                    <option value={item.id} key={item.id}>{`Item ${item.itemNumber}`}</option>
                  ))}
                </Select>
              </Fieldset>
            </Section>
          }
          //right={}
        />
      }
    >
      <Section>
        <CardsGrid>
          {statementSelection.statements.map((s) => {
            return (
              <VirtualCard
                key={s.id}
                isSelected={s.id === statementSelection.selected?.id}
                onClick={() => onSelectStatement(s.id)}
                variant={s.done ? 'success' : 'default'}
              >
                <CardHeader>
                  <CardTopper right={`Häufigkeit: ${s.duplicates} Mal`} />
                  <CardHeaderContent hint={s.reference}>{s.normalized}</CardHeaderContent>
                </CardHeader>
              </VirtualCard>
            );
          })}
        </CardsGrid>
      </Section>
      <ReviewDrawer
        open={!!statementSelection.selected?.id}
        onClose={() => statementSelection.selected && onSelectStatement(statementSelection.selected.id)}
        lockBackgroundScroll
      >
        {reviewLines.map((reviewLine) => {
          if (!itemSelection.selected || !statementSelection.selected) return null;
          return (
            <Section title={reviewLine.test} key={reviewLine.id} narrow>
              {
                {
                  'Satzbau & Wortschatz': (
                    <StyledSvoCard
                      id={reviewLine.id}
                      label={`Item ${itemSelection.selected.itemNumber}`}
                      text={statementSelection.selected.normalized}
                      statement={statementSelection.selected.reference}
                      onGrade={(points: 0 | 1 | 2) => onGrade(reviewLine.id, { points })}
                      onRegrade={() => onRegrade(reviewLine.id)}
                      grading={reviewLine.points}
                      status={reviewLine.status}
                    />
                  ),
                  'Genus: NGP I': (
                    <StyledNgpCard
                      id={reviewLine.id}
                      label={`Item ${itemSelection.selected?.itemNumber}`}
                      text={statementSelection.selected.normalized}
                      statement={statementSelection.selected.reference}
                      onGrade={(update: Partial<ITestNGPStatementGrading>) => onGrade(reviewLine.id, update)}
                      onRegrade={() => onRegrade(reviewLine.id)}
                      grading={reviewLine}
                      correct={reviewLine.correct}
                      status={reviewLine.status}
                    />
                  ),
                  'Genus: Nominativ I': (
                    <StyledNominativCard
                      id={reviewLine.id}
                      label={`Item ${itemSelection.selected?.itemNumber}`}
                      text={statementSelection.selected.normalized}
                      statement={statementSelection.selected.reference}
                      onGrade={(update: Partial<ITestNominativStatementGrading>) => onGrade(reviewLine.id, update)}
                      onRegrade={() => onRegrade(reviewLine.id)}
                      grading={reviewLine}
                      correct={reviewLine.correct}
                      status={reviewLine.status}
                    />
                  ),
                }[reviewLine.test]
              }
            </Section>
          );
        })}
        <Section variant="done" narrow horizontal spread>
          <Button
            variant="primary"
            onClick={() => statementSelection.selected && onConfirmReview(statementSelection.selected.id)}
            disabled={!reviewDone || !statementSelection.selected}
          >
            <ButtonNextIcon />
            SPEICHERN
          </Button>
          <Button
            variant="secondary"
            onClick={() => statementSelection.selected && onSelectStatement(statementSelection.selected.id)}
          >
            <ButtonRestartIcon />
            ABBRECHEN
          </Button>
        </Section>
      </ReviewDrawer>
    </StyledTestPage>
  );
};
