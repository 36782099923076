import { VFC, memo } from 'react';
import styled from 'styled-components/macro';
import { Card, CardBodySection, CardExpander, CardHeader, CardHeaderContent } from '../../../ui/layout/card';
import { SmilyGradingButton } from '../../../ui/buttons/grading';
import { GradingSad, GradingSmile } from '../../../ui/icons';
import { ITestNominativStatement, ITestNominativStatementGrading } from '../types';

const StyledGradingColor = ({ correct }: { correct: boolean | null }) => {
  switch (correct) {
    case true:
      return `#2BA834`;
    case false:
      return `#C01616`;
    default:
      return `#c9c9c9`;
  }
};

const StyledGrading = styled.span<{ correct: boolean | null }>`
  font-family: Raleway;
  font-weight: 600;
  font-size: 6rem;
  letter-spacing: 0.74px;
  color: ${StyledGradingColor};
`;

const StyledCardBodyInner = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-items: center;

  font-size: 2.02rem;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.025rem;
`;

const StyledButtonGroup = styled.section`
  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

export interface ResultCardProps extends ITestNominativStatement {
  className?: string;
  onGrade: (update: Partial<ITestNominativStatementGrading>) => void;
  onRegrade: () => void;
}

export const ResultCard: VFC<ResultCardProps> = ({
  className,
  label,
  text,
  statement,
  proposal,
  status,
  grading,
  correct,
  onGrade,
  onRegrade,
}) => {
  const success = Object.values(grading).some(Boolean);
  return (
    <Card className={className} variant={success ? 'success' : 'default'}>
      <CardHeader>
        <CardHeaderContent
          label={label}
          hint={statement || undefined}
          aside={
            <StyledGrading correct={correct}>
              {correct !== null ? correct ? <GradingSmile /> : <GradingSad /> : '?'}
            </StyledGrading>
          }
        >
          {text}
        </CardHeaderContent>
      </CardHeader>
      {status === 'closed' && <CardExpander onExpand={onRegrade} />}
      {status !== 'closed' && (
        <CardBodySection title="Bewertung">
          <StyledCardBodyInner>
            <header>die/eine/sie</header>
            <header>der</header>
            <header>ein</header>
            <StyledButtonGroup>
              <SmilyGradingButton
                caption="R"
                checked={grading.femaleArticleR}
                basedOn={proposal?.femaleArticleR ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ femaleArticleR: true })}
              />
              <SmilyGradingButton
                caption="F"
                checked={grading.femaleArticleW}
                basedOn={proposal?.femaleArticleW ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ femaleArticleW: true })}
              />
            </StyledButtonGroup>
            <StyledButtonGroup>
              <SmilyGradingButton
                caption="R"
                checked={grading.maleArticleR}
                basedOn={proposal?.maleArticleR ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ maleArticleR: true })}
              />
              <SmilyGradingButton
                caption="F"
                checked={grading.maleArticleW}
                basedOn={proposal?.maleArticleW ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ maleArticleW: true })}
              />
            </StyledButtonGroup>
            <StyledButtonGroup>
              <SmilyGradingButton
                caption="R"
                checked={grading.maleIndefinitArticleR}
                basedOn={proposal?.maleIndefinitArticleR ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ maleIndefinitArticleR: true })}
              />
              <SmilyGradingButton
                caption="F"
                checked={grading.maleIndefinitArticleW}
                basedOn={proposal?.maleIndefinitArticleW ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ maleIndefinitArticleW: true })}
              />
            </StyledButtonGroup>
            <header>das</header>
            <header>sonstige</header>
            <header></header>
            <StyledButtonGroup>
              <SmilyGradingButton
                caption="R"
                checked={grading.neuterArticleR}
                basedOn={proposal?.neuterArticleR ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ neuterArticleR: true })}
              />
              <SmilyGradingButton
                caption="F"
                checked={grading.neuterArticleW}
                basedOn={proposal?.neuterArticleW ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ neuterArticleW: true })}
              />
            </StyledButtonGroup>
            <StyledButtonGroup>
              <SmilyGradingButton
                caption="S"
                checked={grading.other}
                basedOn={proposal?.other ? proposal.basedOn : 'none'}
                onChange={(evt) => evt.currentTarget.checked && onGrade && onGrade({ other: true })}
              />
            </StyledButtonGroup>
          </StyledCardBodyInner>
        </CardBodySection>
      )}
    </Card>
  );
};

export const ResultCardMemo = memo(ResultCard);
