import { VFC, ReactNode, memo } from 'react';
import styled from 'styled-components/macro';
import { Card, CardHeader, CardHeaderContent } from '../../../ui/layout/card';
import { Fieldset } from '../../../ui/form/fieldset';
import { Textarea } from '../../../ui/form/textarea';
import { Input } from '../../../ui/form/input';

export const StyledCheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-top: 0.3rem;
  line-height: 1.42;
`;

const StyledTextarea = styled(Textarea)`
  min-height: 14.2rem;
`;

export interface NoStatementCheckboxProps {
  label: string;
  entered: string | false | null;
  onEntered: (value: string | false | null) => void;
}

export const NoStatementCheckbox: VFC<NoStatementCheckboxProps> = ({ label, entered, onEntered }) => (
  <StyledCheckboxLabel>
    <Input
      type="checkbox"
      checked={entered === false ? true : false}
      onChange={(e) => onEntered(e.currentTarget.value ? false : null)}
    />
    <span>{label}</span>
  </StyledCheckboxLabel>
);

export interface NarrationCardProps extends NoStatementCheckboxProps {
  label: string;
  text: string;
  image?: ReactNode;
}

export const NarrationCard: VFC<NarrationCardProps> = ({ label, text, image, entered, onEntered }) => {
  return (
    <Card variant={entered === false || entered ? 'success' : 'default'}>
      <CardHeader img={image ?? null}>
        <CardHeaderContent variant="compact" label={label}>
          <div dangerouslySetInnerHTML={text ? { __html: text } : undefined} />
        </CardHeaderContent>
      </CardHeader>
      <Fieldset
        label="Äußerung des Kindes"
        hint={
          <NoStatementCheckbox
            label="Das Kind hat keine Äußerung gemacht / Test überspringen."
            entered={entered}
            onEntered={onEntered}
          />
        }
      >
        <StyledTextarea
          value={entered || ''}
          onInput={(e) => onEntered(e.currentTarget.value === '' ? null : e.currentTarget.value)}
        />
      </Fieldset>
    </Card>
  );
};

export const NarrationCardMemo = memo(NarrationCard);
