import { VFC } from 'react';
import styled from 'styled-components/macro';
import { Card, CardBodySection, CardHeader, CardHeaderContent } from '../../../ui/layout/card';
import { SmilyGradingButton } from '../../../ui/buttons/grading';
import { TNarrationOrderGrades } from '../types';

const StyledCard = styled(Card)`
  width: 40rem;
  margin: 0 auto;
`;

const StyledCardBodyInner = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-items: center;

  font-size: 2.02rem;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.025rem;
`;

export interface OrderCardProps {
  className?: string;
  orderGrade: null | TNarrationOrderGrades;
  onGradeOrder?: (grading: TNarrationOrderGrades) => void;
}

export const OrderCard: VFC<OrderCardProps> = ({ className, orderGrade, onGradeOrder }) => {
  const success = orderGrade !== null;
  return (
    <StyledCard className={className} variant={success ? 'success' : 'default'}>
      <CardHeader>
        <CardHeaderContent label="Reihenfolge" />
      </CardHeader>
      <CardBodySection title="Bewertung">
        <StyledCardBodyInner>
          <header>Ja</header>
          <header>Teilweise</header>
          <header>Nein</header>
          <SmilyGradingButton
            caption="J"
            checked={orderGrade === 2}
            onChange={(e) => e.currentTarget.value && onGradeOrder && onGradeOrder(2)}
          />
          <SmilyGradingButton
            caption="⇔"
            checked={orderGrade === 1}
            onChange={(e) => e.currentTarget.value && onGradeOrder && onGradeOrder(1)}
          />
          <SmilyGradingButton
            caption="N"
            checked={orderGrade === 0}
            onChange={(e) => e.currentTarget.value && onGradeOrder && onGradeOrder(0)}
          />
        </StyledCardBodyInner>
      </CardBodySection>
    </StyledCard>
  );
};
