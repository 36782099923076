import { get } from 'lodash';
import { IWithId, IItemInitializer } from '../type';

export const createObjectArrayToNormalizedReducer = <T extends IWithId, TStateSlice, TItem extends T = T>(
  statekey: string,
  datapath?: string,
  itemInitializer: IItemInitializer<T, TStateSlice, TItem> = () => Object()
) => (state: TStateSlice, action: Record<any, any>) => {
  const data: T[] = get(action, datapath ? datapath : 'payload', []);
  state[statekey].map = {};
  state[statekey].ids = [];
  data.forEach((dataItem) => {
    const initial = itemInitializer(state);
    state[statekey].map[dataItem.id] = { ...dataItem, ...initial };
    state[statekey].ids.push(dataItem.id);
  });
  return state;
};
