import { Component } from 'react';
import { connect } from 'react-redux';
import { getRouteType } from './selectors';
import { screenByFeatureKey } from '../config.screen';
import { Controller as MainMenuController } from '../main-menu';
import { Fragment } from 'react';

export class ScreenFrame extends Component {
  render() {
    const ScreenView = screenByFeatureKey(this.props.route.substr(6));
    return (
      <Fragment>
        <MainMenuController route={this.props.route} />
        {ScreenView ? <ScreenView /> : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    route: ownProps.route || getRouteType(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    propName: () => {
      //dispatch()
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenFrame);
