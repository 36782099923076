import { takeEvery, call, put, select } from 'redux-saga/effects';
import { createAxios } from './repository';
import { createJob, selectors } from '../config';
import { apiJobRunPattern } from '@app/lib/config';

export const sagas = [loader];

function* loader() {
  yield takeEvery(apiJobRunPattern, executeApiJob);
}

function* executeApiJob(action) {
  const {
    payload: { data },
    meta: { jobKey, jobId, jobParams },
  } = action;
  const config = createJob[jobKey];
  if (!config) {
    console.error(`Jobs creator config missing for job key ${jobKey}`);
    return;
  }
  const job = config(jobId);

  try {
    const accessToken = yield select(selectors.auth.accessToken);
    const axios = createAxios(accessToken);
    const requestConfig = {
      ...jobParams,
      params: jobParams.method === 'get' ? data : undefined,
      data: jobParams.method !== 'get' ? data : undefined,
    };
    const response = yield call(axios.request, requestConfig);
    yield put(job.successAction(response.data?.data));
  } catch (err) {
    console.error(err);
    const response = err.response || {};
    yield put(job.failedAction(err.message, err.request, response.status, response.data, response.headers));
  } finally {
    yield put(job.finallyAction());
  }
}
