import { actionChannel, take, put, select, takeLatest } from 'redux-saga/effects';
import { getPrevRouteType, getRouteType, getCurrentRouteAction } from './selectors';
import { actions as routingActions, featuresByRoute } from './routes';
import { controlActions } from './actions';

export const sagas = [onRoute, onResetApplication];

function* onRoute() {
  const queue = yield actionChannel([...Object.values(routingActions).map((action) => action.type)]);
  while (true) {
    const routeAction = yield take(queue);
    const { type } = routeAction;
    const prevType = yield select(getPrevRouteType);

    // Close menu before routing
    // yield put(ActionCreators.onSwitchMenu(false));

    if (prevType && featuresByRoute[prevType]) {
      yield put(featuresByRoute[prevType].destroyAction());
    }

    if (routeAction.payload.saveLayer) {
      yield put(controlActions.saveApplicationData(routeAction.payload.fromLayer));
    }

    if (featuresByRoute[type]) {
      yield put(featuresByRoute[type].initAction(routeAction));
    }
  }
}

function* onResetApplication() {
  yield takeLatest(controlActions.resetApplication.type, reinitializeCurrentFeature);
  yield takeLatest(controlActions.loadApplicationData.type, reinitializeCurrentFeature);
}

function* reinitializeCurrentFeature() {
  const type = yield select(getRouteType);
  const routeAction = yield select(getCurrentRouteAction);
  if (type && routeAction && featuresByRoute[type]) {
    yield put(featuresByRoute[type].initAction(routeAction));
  }
}
