import { createAction } from '@reduxjs/toolkit';
import { IRootState } from '@app/state';

export const controlActions = {
  resetApplication: createAction('@@RESET_APPLICATION', (mergeState: Partial<IRootState> = {}) => ({
    payload: mergeState,
  })),
  loadApplicationData: createAction('@@LOAD_APPLICATION_DATA', (mergeState: Partial<IRootState> = {}) => ({
    payload: mergeState,
  })),
  saveApplicationData: createAction('@@SAVE_APPLICATION_DATA', (fromLayer: string) => ({
    payload: { fromLayer },
  })),
};
