import { VFC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const StyledCircle = styled.div`
  font-family: Raleway;
  color: #f8b477;
  width: 26.5rem;
  height: 26.5rem;
  background: #ffffff;
  border: 3px solid #f8b477;
  border-radius: 50%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 6rem 1rem 4rem 1rem;
  align-items: center;
`;

const StyledTitle = styled.div`
  flex: 1 0 0;
  font-weight: 500;
  font-size: 2.4rem;
  color: #4a4a4a;
  letter-spacing: 0.03rem;
  text-align: center;
`;

const StyledGrade = styled.div`
  flex: 1 0 0;
  font-weight: 600;
  font-size: 6rem;
  line-height: 7rem;
  letter-spacing: 0.074rem;
  text-align: center;
`;

const StyledInfo = styled.div`
  flex: 1 0 0;
  font-weight: 300;
  font-size: 1.7rem;
  line-height: 2rem;
  color: #4a4a4a;
  letter-spacing: 0.021rem;
  text-align: center;
`;

export interface ResultCircleProps {
  title?: ReactNode;
  grade?: ReactNode;
  info?: ReactNode;
}

export const ResultCircle: VFC<ResultCircleProps> = ({ title, grade, info }) => {
  return (
    <StyledCircle>
      <StyledTitle>{title}</StyledTitle>
      <StyledGrade>{grade}</StyledGrade>
      <StyledInfo>{info}</StyledInfo>
    </StyledCircle>
  );
};
