import { VFC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';

const Styled = styled.div``;

export interface ViewProps {
  children?: ReactNode;
}

export const View: VFC<ViewProps> = ({ children }) => {
  return (
    <Page header={<Header title="PDF Herunterladen" />}>
      <Section>Content...</Section>
    </Page>
  );
};
