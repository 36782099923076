import { configureStore, PreloadedState, combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { clientRootSaga } from './sagas';
import { IRootState, routingConnector, reducersMap, controlActions } from '@app/features/config.redux';
import { defaultsDeep } from 'lodash';

type TPreloadedState = PreloadedState<IRootState>;

const extractPreservedState = (state: IRootState | undefined): Partial<IRootState> => {
  return typeof state === 'undefined'
    ? {}
    : {
        location: state.location,
        auth: state.auth,
      };
};

const storeCreator = (preloadedState?: TPreloadedState) => {
  const routing = routingConnector;
  const sagaMiddleware = createSagaMiddleware();

  const sliceReducers: Reducer<IRootState, AnyAction> = combineReducers({
    ...reducersMap,
  });

  const reducer: Reducer<IRootState, AnyAction> = (state, action) => {
    switch (String(action.type)) {
      case controlActions.resetApplication.type: {
        const preservedState: Partial<IRootState> = extractPreservedState(state);
        const resetState: IRootState = {
          ...sliceReducers(undefined, action),
          ...preservedState,
        };
        return sliceReducers(resetState, action);
      }
      case controlActions.loadApplicationData.type: {
        const preservedState: Partial<IRootState> = extractPreservedState(state);
        const initialState = sliceReducers(undefined, action);
        const resetState: IRootState = defaultsDeep(
          {
            ...action.payload,
            ...preservedState,
          },
          initialState
        );
        return sliceReducers(resetState, action);
      }
      default:
        return sliceReducers(state, action);
    }
  };

  const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }).concat([routing.middleware, sagaMiddleware]),
    enhancers: (defaultEnhancers) => [routing.enhancer, ...defaultEnhancers],
  });

  sagaMiddleware.run(clientRootSaga, routing.initialize, !process.env.REACT_APP_DISABLE_AUTH && 'APP_AUTHENTICATED');

  return store;
};

export { storeCreator };
export type { TPreloadedState, IRootState };
