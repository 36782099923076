import { TId } from '@app/lib/type';
import { IRootState } from '@app/state';
import { get } from 'lodash';
import { IScratchPad } from './config';
import { TState } from './state';

export const createSelectors = (slicePath: string) => {
  const getSlice = (state: IRootState): TState => get(state, slicePath);

  return {
    getStateToSave: (state: IRootState): Partial<IRootState> => {
      return {
        pupilForm: state.pupilForm,
        enterStatements: state.enterStatements,
        testSVO: state.testSVO,
        testNGP: state.testNGP,
        testNominativ: state.testNominativ,
        testNarration: state.testNarration,
      };
    },
    getEvaluations: (state: IRootState): IScratchPad[] => {
      const data = getSlice(state).evaluations;
      return data.ids.map((id) => data.map[id]);
    },
    getEvaluationById: (state: IRootState, id: TId): IScratchPad => {
      const data = getSlice(state).evaluations;
      return data.map[id];
    },
  };
};
